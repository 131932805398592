import React, { Component, ReactElement } from "react";
import InputField from "../InputField";
import { Grid, TextFieldProps } from "@mui/material";
import "./form.scss";
import DropDownInput from "../DropDownInput";
import DateInput from "../DateInput/DateInput";
import dayjs from "dayjs";
import RadioInput from "../RadioInput";
import MultiSelect from "../MultiSelect/MultiSelect";
import Checkbox from "../Checkbox";
import TimeInput from "../TimeInput/TimeInput";
import AutocompleteDropdown from "components/Autocomplete/Autocomplete";

export interface FormValidators {
  check: any;
  message: string;
  num?: number;
}
export interface FormModel {
  color?: any;
  field: any;
  label: string;
  placeholder: string;
  required: boolean;
  validators: FormValidators[];
  value: any;
  disabled?: boolean;
  styleClass?: string;
  responsive?: any;
  autoFocus?: any;
  variant?: any;
  size?: any;
  inputProps?: any;
  InputProps?: any;
  typeValue?: any;
  type?: any;
  options?: any;
  style?: any;
  className?: string;
  sx?: any;
  disablePast?: boolean;
  disableFuture?: boolean;
  minDate?: any;
  multiline?: boolean;
  rows?: number | string;
  defaultValue?: any;
  dropdownData?: any
  isMulti?: boolean,
  isClearable?: boolean,
  handledeleteImage?: (id: number) => void,
  defaultSource?: boolean,
  accept?: string;
  changeValueFunc?: any;
  additionalText?: any,
  upiIdVerified?: any,
  url?: any;
}

export interface FormDataModel {
  [key: string]: string | number | boolean | any[];
}

interface FormProps {
  isFormUpdated?: boolean;
  values: any;
  model: FormModel[];
  strings?: any
  testId?: string;
  onChange?: (
    field: string,
    value: string | boolean,
    formData: FormDataModel,
    deleted?: any
    // isFormValid: boolean
  ) => void;
  onFocus?: (
    field: string,
    value: string | boolean,
    formData: FormDataModel,
    deleted?: any
    // isFormValid: boolean
  ) => void;
  card?: any;
  hasError?: boolean;
  fieldError?: boolean;
  dropdownData?: any,
  forgetPassword?: () => void | undefined,
}

export default class Form extends Component<FormProps> {
  state: { formData: FormDataModel; isFormValid: boolean } = {
    formData: {},
    isFormValid: false
  };

  componentDidMount() {
    this.prepareFormData();
  }

  componentDidUpdate(prevProps: Readonly<FormProps>) {
    const { values, strings } = this.props;
    if (
      this.props.isFormUpdated !== prevProps.isFormUpdated ||
      strings !== prevProps.strings ||
      (values && Object.keys(values).length > 0 && values !== prevProps.values)
    ) {
      this.prepareFormData();
    }
  }
  handleChange = (
    value: string | boolean,
    field: string,
    error?: { error: boolean; message: string },
    deleted?: any
  ) => {
    const formData: any = this.state.formData;
    formData[field] = value;

    if (this.props.onChange) {
      // const isFormValid = this.validateForm(formData);
      this.props.onChange(field, value, formData, deleted);
    }
    if (deleted?.deletedField === field) {
      formData[field + "deleted"] = deleted?.DeletedFile;
    }
    formData[field + "Error"] = error && error.error;
    this.setState({
      formData,
      isFormValid: this.validateForm(formData)
    });

  };

  handleFocus = (
    value: string | boolean,
    field: string,
    deleted?: any
  ) => {
    const formData: any = this.state.formData;

    if (this.props.onFocus) {
      // const isFormValid = this.validateForm(formData);
      this.props.onFocus(field, value, formData, deleted);
    }
  };

  removeData = () => {
    const { model, values } = this.props;
    const formData: FormDataModel = {};
    model.forEach((item) => {
      formData[item.field] = "";
      formData[item.field + "Error"] = item.required;
    });

    this.setState({ formData, isFormValid: this.validateForm(formData) });

  }
  validateForm = (formData: FormDataModel) => {
    const { model } = this.props;
    let isFormValid = true;
    model.forEach((item) => {
      if (item.required || formData[item.field + "Error"]) {
        isFormValid = isFormValid && !formData[item.field + "Error"];
      }
    });
    return isFormValid;
  };

  getFormData = () => {
    const { formData, isFormValid } = this.state;
    return { formData, isFormValid };
  };

  resetForm = () => {
    this.removeData();

  };
  prepareFormDataHelper3 = (item: any, values: any, formData: FormDataModel) => {
    if (formData[item.field] || !item.required) {
      formData[item.field + "Error"] = !!(values[item.field] && values[item.field && item.field !== ""] && item.required);
    } else {
      formData[item.field + "Error"] =
        !(values[item.field] && values[item.field && item.field !== ""] && item.required);
    }
  };
  prepareFormDataHelper1 = (model: FormModel[], values: any, formData: FormDataModel) => {
    model.forEach((item) => {
      formData[item.field] =
        (values[item.field] || values[item.field] === 0) &&
          values[item.field] !== ""
          ? values[item.field]
          : "";
      this.prepareFormDataHelper3(item, values, formData);
    });
    this.setState({ formData, isFormValid: this.validateForm(formData) });
  };

  prepareFormDataHelper2 = (model: FormModel[], values: any, formData: FormDataModel) => {
    model.forEach((item) => {
      formData[item.field] =
        values && values[item.field] ? values[item.field] : "";
      formData[item.field + "Error"] = item.required;
    });

    this.setState({ formData, isFormValid: this.validateForm(formData) });
  };

  prepareFormData() {
    const { model, values } = this.props;
    const formData: FormDataModel = {};
    if (values && Object.keys(values).length !== 0) {
      this.prepareFormDataHelper1(model, values, formData);
    } else {
      this.prepareFormDataHelper2(model, values, formData);
    }
  }

  renderFormFields() {
    const { model, hasError, dropdownData, forgetPassword } = this.props;
    const { formData } = this.state;
    const arrayOfFields: ReactElement[] = [];
    model.forEach((item) => {
      switch (item.type) {
        case 'autocomplete':
          arrayOfFields.push(
            <Grid key={item.field} {...item.responsive} item className={'form-group ' + item.styleClass}>
              <AutocompleteDropdown
                sx={item.sx}
                url={item.url}
                id={''}
                disabled={item.disabled || false}
                hasError={hasError || false}
                field={item.field}
                value={formData[item.field] || formData[item.field] === 0 ? (formData[item.field] as string) : ''}
                placeholder={item.placeholder}
                label={item.label || ''}
                fieldError={item.field ? (formData[item.field + 'Error'] as boolean) : false}
                validators={item.validators}
                onChange={this.handleChange}
                size={item.size}
              />
            </Grid>);
          break;
        case "text":
          arrayOfFields.push(
            <Grid
              key={item.field}
              {...item.responsive}
              item
              className={"form-group " + item.styleClass}
            >
              <InputField
                disabled={item.disabled || false}
                autoFocus={item.autoFocus || false}
                variant={item.variant}
                size={item.size}
                inputProps={item.inputProps || {}}
                InputProps={item.InputProps}
                hasError={hasError || false}
                field={item.field}
                multiline={item.multiline}
                rows={item.rows}
                inputValue={
                  formData[item.field] || formData[item.field] === 0
                    ? (formData[item.field] as string)
                    : ""
                }
                style={item.style}
                typeValue={item.typeValue || ""}
                label={item.label || ""}
                fieldError={
                  item.field
                    ? (formData[item.field + "Error"] as boolean)
                    : false
                }
                validators={item.validators}
                className={item.className}
                textChange={this.handleChange}
                sx={item.sx}
                forgetPassword={forgetPassword || undefined}
                changeValueFunc={item.changeValueFunc}
                upiIdVerified={item.upiIdVerified}
                color={item.color}
              />
            </Grid>
          );
          break;
        case "checkbox":
          arrayOfFields.push(
            <Grid
              key={item.field}
              {...item.responsive}
              item
              className={"form-group " + item.styleClass}
            >
              <Checkbox
                label={item.label}
                field={item.field}
                value={formData[item.field] ? formData[item.field] : false}
                selected={formData[item.field] ? formData[item.field] : false}
                disabled={item.disabled}
                textChange={this.handleChange}
                sx={item.sx}
              />
            </Grid>
          );
          break;
        case "drop-down":
          arrayOfFields.push(
            <Grid
              key={item.field}
              {...item.responsive}
              item
              className={"form-group " + item.styleClass}
            >
              <DropDownInput
                sx={item.sx}
                options={item.options as { label: string; value: string; icon?: string }[]}
                className={""}
                id={""}
                style={item.style}
                labelId={""}
                disabled={item.disabled || false}
                inputProps={item.inputProps || {}}
                hasError={hasError || false}
                field={item.field}
                inputValue={
                  formData[item.field] || formData[item.field] === 0
                    ? (formData[item.field] as string)
                    : ""
                }
                placeholder={item.placeholder}
                label={item.label || ""}
                fieldError={
                  item.field
                    ? (formData[item.field + "Error"] as boolean)
                    : false
                }
                validators={item.validators}
                textChange={this.handleChange}
                handleFocus={this.handleFocus}
                size={item.size}
                additionalText={item.additionalText || undefined}
              />
            </Grid>
          );
          break;
        case "select":
          arrayOfFields.push(
            <Grid
              key={item.field}
              {...item.responsive}
              item
              className={"form-group " + item.styleClass}
            >
              <MultiSelect
                options={dropdownData && dropdownData[item.field]}
                className={""}
                id={""}
                style={item.style}
                isClearable={item.isClearable}
                labelId={""}
                isMulti={item.isMulti}
                disabled={item.disabled || false}
                inputProps={item.inputProps || {}}
                hasError={hasError || false}
                field={item.field}
                inputValue={
                  formData[item.field] || formData[item.field] === 0
                    ? (formData[item.field] as string)
                    : ""
                }
                placeholder={item.placeholder}
                label={item.label || ""}
                fieldError={
                  item.field
                    ? (formData[item.field + "Error"] as boolean)
                    : false
                }
                validators={item.validators}
                onChange={this.handleChange}
              />
            </Grid>
          );
          break;
        case "radio":
          arrayOfFields.push(
            <Grid
              key={item.field}
              {...item.responsive}
              item
              className={"form-group " + item.styleClass}
            >
              <RadioInput
                options={item.options}
                className={""}
                id={""}
                style={item.style}
                labelId={""}
                defaultValue={formData[item.field] || formData[item.field] === 0
                  ? (formData[item.field] as string)
                  : false}
                disabled={item.disabled || false}
                inputProps={item.inputProps || {}}
                hasError={hasError || false}
                field={item.field}
                inputValue={
                  formData[item.field] || formData[item.field] === 0
                    ? (formData[item.field] as string)
                    : false
                }
                placeholder={item.placeholder}
                label={item.label || ""}
                fieldError={
                  item.field
                    ? (formData[item.field + "Error"] as boolean)
                    : false
                }
                validators={item.validators}
                onChange={this.handleChange}
                size={item.size}
              />
            </Grid>
          );
          break;
        case "date":
          arrayOfFields.push(
            <Grid
              key={item.field}
              {...item.responsive}
              item
              className={"form-group " + item.styleClass}
            >
              <DateInput
                label={item.label}
                validators={item.validators}
                textChange={this.handleChange}
                sx={item.sx}
                value={
                  formData[item.field] || formData[item.field] === 0
                    ? dayjs(new Date(formData[item.field] as string))
                    : null
                }
                field={item.field}
                size={item.size}
                onChange={(e: any) => e}
                disablePast={item.disablePast}
                disableFuture={item.disableFuture}
                placeholder={item.placeholder}
                hasError={hasError || false}
                fieldError={
                  item.field
                    ? (formData[item.field + "Error"] as boolean)
                    : false
                }
                minDate={item.minDate}
                disabled={item.disabled}
                renderInput={(params: TextFieldProps) => (
                  <InputField
                    size={item.size}
                    hasError={hasError || false}
                    inputValue={params.value as string}
                    fieldError={
                      item.field
                        ? (formData[item.field + "Error"] as boolean)
                        : false
                    }
                    {...params}
                  />
                )}
              />
            </Grid>
          );
          break;
        case "time":
          arrayOfFields.push(
            <Grid
              key={item.field}
              {...item.responsive}
              item
              className={"form-group " + item.styleClass}
            >
              <TimeInput
                label={item.label}
                validators={item.validators}
                textChange={this.handleChange}
                value={
                  formData[item.field] || formData[item.field] === 0
                    ? formData[item.field]
                    : null
                }
                field={item.field}
                size={item.size}
                onChange={(e: any) => e}
                placeholder={item.placeholder}
                hasError={hasError || false}
                fieldError={
                  item.field
                    ? (formData[item.field + "Error"] as boolean)
                    : false
                }
                minDate={item.minDate}
                disabled={item.disabled}
                renderInput={(params: TextFieldProps) => (
                  <InputField
                    size={item.size}
                    hasError={hasError || false}
                    inputValue={params.value as string}
                    fieldError={
                      item.field
                        ? (formData[item.field + "Error"] as boolean)
                        : false
                    }
                    {...params}
                  />
                )}
              />
            </Grid>
          );
          break;

        default:
          break;
      }
    });

    return arrayOfFields;
  }

  render() {
    return <>{this.renderFormFields()}</>;
  }
}
