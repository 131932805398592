import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import PrimaryButton from 'components/Button/PrimaryButton';
import strings from 'common/Translation/Translate';
import { useTheme } from '@mui/system';
import { Grid, CircularProgress, AlertColor, useMediaQuery, TextareaAutosize } from '@mui/material';
import Form, { FormDataModel } from 'components/Form';
import { LoginForm } from 'screens/Authentication/Login/LoginForm';
import { URLS } from 'utils/constants/urls';
import { setAuthentication, setUser } from 'utils/redux';
import axiosInstance from 'utils/redux/axios';
import { setNotificationMessage } from 'utils/redux/reducer/Notification-details-slice';
import { removeErrorFieldsFromValues } from 'utils/validators';
import Notification from 'components/Notification';
import { config } from 'process';
import { useDispatch } from 'react-redux';
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { Scrollbar } from 'react-scrollbars-custom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import PageLoader from 'components/PageLoader';
import './styles.css'
import { images } from '../../../utils/constants/images'
import { transcode } from 'buffer';
import PdfViewer from 'components/PdfViewer';
type ModalProps = {
      open: boolean;
      setOpen: any;
      invoiceNumber: any;
};

const style = {
      fontWeight: "bold",
      fontSize: "16px"
}

export const InvoiceModal: React.FC<ModalProps> = ({
      open,
      setOpen,
      invoiceNumber


}) => {
      const theme = useTheme();
      const dispatch = useDispatch();
      const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
      const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
      const [loading, setLoading] = React.useState<boolean>(false);
      const [invoiceData, setInvoiceData] = React.useState<any>({});
      let totalAmount = 0;




      const [message, setMessage] = React.useState<{
            display: boolean;
            severity: AlertColor | null;
            message: any;
      }>({
            display: false,
            severity: null,
            message: "",
      });

      const closeNotification = (value: boolean) => {
            setMessage({ ...message, display: value });
      };

      const handleDownload = () => {
            const link = document.createElement('a');
            link.href = invoiceData; // Assuming invoiceData is the URL of the PDF
            link.download = 'invoice.pdf'; // Name of the downloaded file
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
      };
      React.useEffect(() => {
            const fetchInvoiceDetails = async () => {
                  setLoading(true)
                  try {

                        const response = await axiosInstance.get(URLS.invoice(invoiceNumber), {
                              responseType: 'blob'
                        })
                        if (response.status === 200) {
                              const blob = new Blob([response.data], { type: "application/pdf" });

                              const blobUrl = window.URL.createObjectURL(blob)
                              setInvoiceData(blobUrl);
                        }
                  }
                  catch (error) {


                  }
                  setLoading(false)
            }
            if (invoiceNumber)
                  fetchInvoiceDetails();

      }, [invoiceNumber])


      return (
            <>
                  {loading && <PageLoader />}
                  {message.display && (
                        <Notification
                              isOpen={message.display}
                              message={message.message}
                              severity={message.severity as AlertColor}
                              closeNotification={closeNotification}
                        />
                  )}
                  <Modal
                        sx={{ zIndex: 888 }}
                        open={open}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        onClose={() => setOpen(false)}
                  >
                        <Box sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              width: screenSizeDownMd ? 700 : screenSizeDownSm ? 500 : 800,
                              bgcolor: 'background.paper',
                              boxShadow: 24,
                              p: 3,
                              maxHeight: '90vh',
                              overflow: "auto",



                        }} className="invoice-modal">
                              <Box textAlign={"right"} sx={{ mb: 2 }} className="close-button">

                                    <ClearOutlinedIcon
                                          onClick={(e) => setOpen(false)}
                                          sx={{
                                                ":hover": { cursor: "pointer" },
                                                color: theme.palette.primary.main,
                                          }}
                                    />
                              </Box>
                              {invoiceData && <Box textAlign={'right'} padding="20px"><Button onClick={handleDownload}>Download</Button></Box>}
                              <Box display={'flex'} justifyContent={'center'}> {invoiceData && <PdfViewer file={invoiceData} />}</Box>
                              {/* <iframe src={invoiceData} style={{ width: "100%", height: "600px", border: "none" }} ></iframe> */}


                        </Box>
                  </Modal>
            </>
      );
}






// {Object.keys(invoiceData).length ? <div className="invoice-container">

// <table className="header-table">
//       <thead>
//             <tr>
//                   <td>
//                         <div>
//                               <img src={images.blueTextLogoImg} alt="logo" style={{ width: "130px", objectFit: "contain" }} />
//                         </div>
//                   </td>
//                   <td className="text-right-child">
//                         <p className="bold-text">Invoice Number: <span >{invoiceData.invoiceNumber}</span></p>
//                         <p className="bold-text">Invoiced Date: <span >{new Date(invoiceData.invoiceDateTime).toLocaleString()}</span></p>
//                   </td>

//             </tr>

//       </thead>
// </table>


// <div className="detail_info">
//       <table className="invoice-table">
//             <tr className="no-border">
//                   <td>
//                         <h3>Billing Address</h3>
//                   </td>
//                   <td className="text-right-child">
//                         <h3>Shipping Address</h3>
//                   </td>
//             </tr>
//             <tr className="no-border">
//                   <td>
//                         {invoiceData.billingAddress.addressLine1}
//                   </td>
//                   <td className="text-right-child">
//                         {invoiceData.shippingAddress.addressLine1}
//                   </td>
//             </tr>
//             <tr className="no-border">
//                   <td>
//                         {invoiceData.billingAddress.addressLine2}
//                   </td>
//                   <td className="text-right-child">
//                         {invoiceData.shippingAddress.addressLine2}
//                   </td>
//             </tr>
//             <tr className="no-border">
//                   <td>
//                         {invoiceData.billingAddress.region}
//                   </td>
//                   <td className="text-right-child">
//                         {invoiceData.shippingAddress.region}
//                   </td>
//             </tr>
//             <tr className="no-border">
//                   <td>
//                         {invoiceData.billingAddress.country}
//                   </td>
//                   <td className="text-right-child">
//                         {invoiceData.shippingAddress.country}
//                   </td>
//             </tr>
//             <tr className="no-border">
//                   <td>
//                         {invoiceData.billingAddress.pinCode}
//                   </td>
//                   <td className="text-right-child">
//                         {invoiceData.shippingAddress.pinCode}
//                   </td>
//             </tr>
//       </table>
// </div>
// <table className=" table-border">
//       <thead>
//             <tr>
//                   <th>Product</th>
//                   <th>Variant Code</th>
//                   <th>Quantity</th>
//                   <th>Unit Price</th>
//             </tr>
//       </thead>
//       <tbody>
//             {
//                   invoiceData.orderLineItems.map((item: any) => {
//                         totalAmount += item.price * item.quantity
//                         return (
//                               <tr key={item.id}>
//                                     <td>
//                                           {item.productName}
//                                     </td>
//                                     <td>
//                                           {item.variantCode}
//                                     </td>
//                                     <td>
//                                           {item.quantity}
//                                     </td>
//                                     <td>
//                                           {item.price}
//                                     </td>
//                               </tr>
//                         )
//                   })
//             }
//       </tbody>
//       <tfoot>
//             <tr >
//                   <td colSpan={3}>Total Amount:</td>
//                   <td>{totalAmount}</td>
//             </tr>
//       </tfoot>
// </table>
// <table className=" table-border">
//       <thead>
//             <tr>
//                   <th>Payment Type</th>
//                   <th>Payment Reference Id</th>
//                   <th>Payment Date/Time</th>
//                   <th>Amount</th>

//             </tr>
//       </thead>
//       <tbody>
//             {
//                   invoiceData.payments.map((item: any) => (
//                         <tr key={item.id}>
//                               <td>
//                                     {(item.paymentType || "").replaceAll("_", " ")}
//                               </td>
//                               <td>
//                                     {item.paymentRefId}
//                               </td>
//                               <td>
//                                     {new Date(item.paymentDateTime).toLocaleString()}
//                               </td>
//                               <td>
//                                     {item.amount}
//                               </td>
//                         </tr>
//                   ))
//             }
//       </tbody>

// </table>
// </div> : ""}








