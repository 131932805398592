import { Box } from "@mui/system";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PdfViewer({ file }: { file: any }) {
      const [numPages, setNumPages] = useState<number | null>(null);

      function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
            setNumPages(numPages);
      }

      return (
            <Box textAlign="center">
                  {file && (
                        <div style={{ height: "100%", width: "100%" }}>
                              <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                                    {Array.from(Array(numPages ?? 0), (_, i) => (
                                          <Page
                                                key={`page_${i + 1}`}
                                                pageNumber={i + 1}
                                                renderTextLayer={false}
                                                renderAnnotationLayer={false}
                                                width={800} // Example width
                                                height={600} // Example height
                                          />
                                    ))}
                              </Document>
                        </div>
                  )}
            </Box>
      );
}

export default PdfViewer;
