import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { Card, Divider, Grid, Modal, Switch, Typography, useMediaQuery } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import strings from "common/Translation/Translate";
import PrimaryButton from "components/Button/PrimaryButton";
import DrawerMenu from "components/DrawerMenu";
import PageLoader from "components/PageLoader";
import { ToolTip } from "components/Tooltip/ToolTip";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { URLS } from "utils/constants/urls";
import { Authenticated, paymentDrawerState } from "utils/redux";
import axiosInstance from "utils/redux/axios";
import { setNotificationMessage } from "utils/redux/reducer/Notification-details-slice";
import { setOpenAuthenticationDrawer } from "utils/redux/reducer/authentication-slice";
import { getDrawerWidth } from "utils/validators/HelperFunctions";
import { DetailsPage } from "./DetailsPage";
import Payment from './Payment'
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';
import LoaderText from "components/LoadingText"


const couponBox = {
  //height: '200px',
  borderRadius: "20px 0px",
  padding: "15px",
  display: "flex",
  gap: "10px",
  background: "#80808012",
  "&:hover": {
    boxShadow: "inset 3px -3px 6px rgba(0, 0, 0, 0.1607843137)",
    background: "#af94942e",
  },
};

const descriptionFont = {
  fontFamily: "initial",
  fontSize: "x-large",
};

const ProductDetails = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogin = useSelector(Authenticated);
  const userDetails = localStorage.getItem("userDetails");
  const { id } = useParams();
  const screenSizeDownXl = useMediaQuery(theme.breakpoints.down("xl"));
  const screenSizeDownLg = useMediaQuery(theme.breakpoints.down("lg"));
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const screenSizeUpLg = useMediaQuery(theme.breakpoints.up("lg"));
  const screenSizeUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const screenSizeUpSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [quantity, setQuantity] = useState<any>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const [productDetails, setProductDetails] = useState<any>({});
  const [openPlaceOrderModal, setOpenPlaceOrderModal] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [qrCode, setQrCode] = useState<any>(null);
  const [upiIntentString, setUpiIntentString] = useState<any>(null);
  const [transactionId, setTransactionId] = useState<any>(null);
  const [txnStatus, setTxnStatus] = useState<any>(null);
  const [useLoyaltyPoints, setUseLoyaltyPoints] = useState<boolean>(false);
  const [tooltipIsOpen, setTooltipIsOpen] = useState<boolean>(false);
  const [orderDetails, setOrderDetails] = useState<any>([]);
  const [orderSummary, setOrderSummary] = useState<any>({});
  const [totalAmount, setTotalAmount] = useState<any>(null);
  const [promisePayLoadData, setPromisePayLoadData] = useState(null as any)
  const [address, setAddress] = useState([] as any);
  const [fulfillmentOptionResp, setFulfilmentOptionResp] = useState(null as any)
  const [dataItemList, setDataItemList] = useState(null as any)
  const [promiseResp, setPromiseResp] = useState(null as any)
  const [loaderText, setLoaderText] = React.useState(false)
  const paymentDrawer = useSelector(paymentDrawerState);

  // const placeOrderOnSubmit = async (e: any) => {
  //   e.preventDefault();
  //   try {
  //     const body = {
  //       redeemFromLoyaltyPoints: useLoyaltyPoints,
  //       orders: orderDetails,
  //     };
  //     setLoader(true);
  //     const { status, data } = await axiosInstance.post(URLS.placeOrder, body);
  //     if (status === 200) {
  //       setLoader(false);
  //       setOpenDrawer(false);
  //       setOpenPlaceOrderModal(true);
  //       setTotalAmount(data.totalAmount);
  //       setQrCode(data.base64QrCodeImage);
  //       setUpiIntentString(data.upiIntentString);
  //       setTransactionId(data.transactionId);
  //     }
  //   } catch (error: any) {
  //     setLoader(false);
  //     let errorMessage = 'An unknown error occurred';
  //     if (error.response && error.response.data) {
  //       errorMessage = error.response.data.error_description || error.response.data.message || errorMessage;
  //     } else if (error.message) {
  //       errorMessage = error.message;
  //     }
  //     dispatch(
  //       setNotificationMessage({
  //         display: true,
  //         severity: "error",
  //         message: errorMessage
  //       })
  //     );
  //   }
  // };

  useEffect(() => {
    const fetchTransactionStatus = async () => {
      try {
        if (transactionId && openPlaceOrderModal === true) {
          await checkTransactionStatus();
        }
      } catch (error) {
        console.error("Error checking transaction status:", error);
      }
    };

    if (txnStatus === "SUCCESS") {
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: "Payment Acknowleged.",
        })
      );
      setOpenPlaceOrderModal(false);
    } else {
      fetchTransactionStatus();
      const intervalId = setInterval(fetchTransactionStatus, 10000);
      return () => clearInterval(intervalId);
    }
  }, [txnStatus, transactionId, openPlaceOrderModal]);

  const checkTransactionStatus = async () => {
    try {
      const requestBody = {
        transactionId: transactionId,
      };
      const { status, data } = await axiosInstance.post(URLS.transactionStatus, requestBody);
      if (status === 200 && data.statusCode === "200" && data.message === "Transaction already updated to CREDITED") {
        setTxnStatus("SUCCESS");
      }
    } catch (error: any) {
      setLoader(false);

      let errorMessage = 'An unknown error occurred';

      if (error.response && error.response.data) {

        errorMessage = error.response.data.error_description || error.response.data.message || errorMessage;
      } else if (error.message) {

        errorMessage = error.message;
      }
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: error.message,
        })
      );
    }
  };

  const onDrawerClose = () => {
    setOpenDrawer(false);
  };

  const getInitialData = useCallback(async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.productDetails(id));
      if (status === 200) {
        setLoader(false);
        setProductDetails(data);
      }
    } catch (err: any) {
      setLoader(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: err.message || "unknown error",
        })
      );
    }
  }, []);

  const handleError = (message: any, type: string) => {
    return dispatch(
      setNotificationMessage({
        display: true,
        severity: type === "success" ? "success" : "error",
        message: message,
      })
    );
  };

  // const getProducts = (itemList: any) => {
  //   let bdyData = {
  //     "products": [
  //       {
  //         // "category_id": "11420",
  //         "country_of_origin": "CHL",
  //         "gtin": "string",
  //         "is_courierable": true,
  //         "is_dangerous": true,
  //         "is_gift": true,
  //         "is_returnable": true,
  //         "preference": {
  //           "bag_required": true,
  //           "picker_notes": "string"
  //         },
  //         "co_line_id": "1",//random
  //         // "product_class": "Pet food",
  //         // "product_id": "11420",
  //         // "product_name": "Belcando water buffalo 400 GR",
  //         // "product_type": "Pet food",
  //         "qty_same_as_parent": true,
  //         "quantity": itemList.quantity,
  //         "requires_shipping": true,
  //         "sku_id": itemList.sku_id
  //       }
  //     ]
  //   }
  //   return bdyData;
  // }

  // const fetchAddress = async (pincode: any, itemList: any) => {
  //   try {
  //     const { status, data } = await axiosInstance.get(URLS.address)
  //     if (status === 200) {
  //       setAddress(data)
  //       handleOnClickFeasibleFulfillment(pincode, itemList, data)
  //     }
  //   } catch (error: any) {
  //     handleError(error.message, "error");
  //   }
  // }

  // const placeOrderFunc = async (code: number, unknownQty: number, pincode: string, itemList: any) => {

  //   if (!isLogin) {
  //     dispatch(setOpenAuthenticationDrawer(true));
  //   } else {

  //     const body = {
  //       "billingAddressId": 2222,
  //       "billingAddressName": "string",
  //       "countryCode": "CL",
  //       "currencyCode": "CL",
  //       "orders": [
  //         {
  //           "orderLineId": "1",
  //           "quantity": quantity,
  //           "variantId": code
  //         }
  //       ],
  //       "payment": [
  //         {
  //           "amount": 0,
  //           "currency_code": "CL",
  //           "payment_datetime": "string",
  //           "payment_gateway": "string",
  //           "payment_ref_id": "string",
  //           "payment_type": "CASH",
  //           "transaction_id": "string"
  //         }
  //       ],
  //       "posNo": "string",
  //       "promiseId": "string",
  //       "promiseVersion": "string",
  //       "redeemFromLoyaltyPoints": true,
  //       "shippingAddressId": 3333333,
  //       "shippingAddressName": "string",
  //       "storeId": "string",
  //       "supplyTypeCode": "string"
  //     }
  //     try {
  //       setLoader(true);
  //       setLoaderText(true);

  //       const { status, data } = await axiosInstance.post(URLS.getOrderSummary, body);
  //       if (status === 200) {
  //         // setOpenDrawer(true);
  //         setOrderDetails(body);
  //         setOrderSummary(data);
  //         fetchAddress(pincode, itemList)
  //         // setLoader(false);
  //       }
  //     } catch (error: any) {
  //       setLoader(false);
  //       setLoaderText(false);
  //       let errorMessage = 'An unknown error occurred';

  //       if (error?.response && error?.response.data) {
  //         errorMessage = error.response.data.error_description || error.response.data.message || errorMessage;
  //       }
  //       dispatch(
  //         setNotificationMessage({
  //           display: true,
  //           severity: "error",
  //           message: errorMessage
  //         })
  //       );
  //     }

  //   }
  // };

  // const handleOnClickFeasibleFulfillment = async (pincode: string, itemList: any, addressData: any) => {
  //   const emailId = userDetails ? JSON.parse(userDetails).email : null
  //   let shipToPostCode = pincode
  //   let countryCode = "CL"
  //   let productString = JSON.stringify(itemList)
  //   let convertHeaderData = shipToPostCode + countryCode + productString
  //   const hash = CryptoJS.MD5(convertHeaderData).toString();

  //   let bodyData = {
  //     cart_id: uuidv4(),
  //     order_type: 'FRW',
  //     channel: 'ONLINE',
  //     transaction_start_datetime: new Date().toISOString(),
  //     "checkout_datetime": new Date().toISOString(),
  //     source_trigger: 'CART',
  //     currency_code: 'CLP',
  //     customer_profile_info: {
  //       "profile_id": emailId,
  //       "profile_type": "CONSUMER"
  //     },
  //     store_id: "ejje-dotcom-001",
  //     pos_id: '1',
  //     "products": [
  //       {
  //         "co_line_id": "1",
  //         "quantity": itemList.quantity,
  //         "sku_id": itemList.sku_id
  //       }
  //     ],
  //     "ship_to_address": {
  //       "address_id": addressData[0].addressType == "SHIPPING" ? addressData[0].addressId : addressData[1].addressId,
  //       "phone_numbers": [
  //         {
  //           "type": "PERSONAL",
  //           "country_code": "CL",
  //           "number": "1212121212"
  //         }
  //       ],
  //       "country_code": "CL",
  //       "postal_code": pincode
  //     },
  //   };

  //   setPromisePayLoadData(bodyData)

  //   try {
  //     const config = {
  //       headers: {
  //         'revision': hash,
  //       },
  //     };
  //     const { status, data } = await axiosInstance.post(URLS.promise, bodyData, config as any)
  //     if (status === 201) {
  //       let listData = {
  //         pinCode: pincode,
  //         items: itemList,
  //         promiseId: data?.promise_id,
  //         revision: hash
  //       }
  //       setPromiseResp(data)
  //       setDataItemList(listData)
  //       updateDeliveryLocation(data, pincode, itemList)
  //     }
  //   } catch (err: any) {
  //     handleError(err.message, "error");
  //     setLoader(false);
  //     setLoaderText(false)
  //   }
  // }

  // const updateDeliveryLocation = async (promiseResp: any, pincode: any, itemList: any) => {
  //   let shipToPostCode = pincode
  //   let countryCode = "CL"
  //   let productString = JSON.stringify(itemList)
  //   let convertHeaderData = shipToPostCode + countryCode + productString
  //   const hash = CryptoJS.MD5(convertHeaderData).toString();

  //   const bodyData = {
  //     address_id: 'string',
  //     country: "CL",
  //     country_code: "CL",
  //     postal_code: pincode,
  //     phone_numbers: [
  //       {
  //         availability: 'string',
  //         country_code: '+91',
  //         number: '11111111111',
  //         type: 'PERSONAL'
  //       }
  //     ]
  //   };

  //   try {
  //     const config = {
  //       headers: {
  //         'revision': hash,
  //       },
  //     };

  //     const { status, data } = await axiosInstance.put(URLS.addressPromise(promiseResp?.promise_id), bodyData, config as any)
  //     if (status === 200) {
  //       // replaceAllProducts(data, pincode, itemList)
  //       getFeasiableFullFillmentOption(data, pincode, itemList)
  //     }
  //   } catch (err: any) {
  //     handleError(err.message, "error");
  //     setLoader(false);
  //     setLoaderText(false)
  //   }
  // }

  // const getFeasiableFullFillmentOption = async (promiseResp: any, pincode: any, itemList: any) => {
  //   let shipToPostCode = pincode
  //   let countryCode = "CL"
  //   let productString = JSON.stringify(itemList)
  //   let convertHeaderData = shipToPostCode + countryCode + productString
  //   const hash = CryptoJS.MD5(convertHeaderData).toString();
  //   const bodyData = getProducts(itemList);
  //   try {
  //     const config = {
  //       headers: {
  //         'revision': hash,
  //       },
  //     };
  //     const { status, data } = await axiosInstance.post(URLS.fullFilmentOptions(promiseResp?.promise_id), bodyData, config as any)
  //     if (status === 200) {

  //       setFulfilmentOptionResp(data)
  //       setOpenDrawer(true);
  //       setLoader(false);
  //       setLoaderText(false)
  //     }
  //   } catch (err: any) {
  //     handleError(err.message, "error");
  //     setLoader(false);
  //     setLoaderText(false)
  //   }
  // }

  // const handleOnselection = async (bodyData: any) => {
  //   let shipToPostCode = dataItemList?.items
  //   let countryCode = "CL"
  //   let productString = JSON.stringify(dataItemList?.items)
  //   let convertHeaderData = shipToPostCode + countryCode + productString
  //   const hash = CryptoJS.MD5(convertHeaderData).toString();
  //   setLoader(true);
  //   try {
  //     const config = {
  //       headers: {
  //         'revision': dataItemList?.revision,
  //       },
  //     };
  //     const { status, data } = await axiosInstance.post(URLS.selection(dataItemList?.promiseId), bodyData, config as any)
  //     if (status === 200) {
  //       // handleError('Selection Success !', 'success')
  //       dispatch(setNotificationMessage({
  //         display: true,
  //         severity: "success",
  //         message: "Selected Success!",
  //       }));
  //     }
  //   } catch (err: any) {
  //     setLoader(false);
  //     handleError(err.message, "error");
  //   }
  //   setLoader(false);
  // }


  useEffect(() => {
    (async () => {
      getInitialData();
    })();
  }, [getInitialData]);

  return (
    <>
      {loader && <PageLoader />}
      {loaderText && <LoaderText text={"Fetching fulfilment options for your cart. Please hold for a moment."} />}

      <DetailsPage
        productDetails={productDetails}
        quantity={quantity}
        setQuantity={setQuantity}
      />
      {/* <Modal
        open={openPlaceOrderModal}
        // onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "20px",
            boxShadow: 24,
            p: 3,
            width: screenSizeDownSm ? "90vw" : "500px",
            minHeight: "80vh",
            ":focus": { border: 0 },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box display={"flex"} justifyContent={"space-between"} sx={{ position: "relative" }}>
            <Typography sx={{ width: "100%" }} variant="h6" color={"primary"} fontWeight={600}>
              <Typography variant="caption" color={"GrayText"}>
                This window will close automatically upon successful payment.
              </Typography>
              <p>Open any UPI App & scan the QR Code to make payment</p>
            </Typography>
            <ClearOutlinedIcon
              onClick={() => {
                setOpenPlaceOrderModal(false);
                setQrCode(null);
                setUpiIntentString(null);
              }}
              sx={{
                ":hover": { cursor: "pointer" },
                color: theme.palette.primary.main,
              }}
            />
          </Box>
          <img
            src={`data:image/png;base64, ${qrCode}`}
            height="35vh"
            // style={{
            //   // position: 'absolute',
            //   // top: '50%',
            //   // left: '50%',
            //   // transform: 'translate(-50%, -50%)',
            //   height: "35vh",
            // }}
            alt="image"
          />
          <Typography>{totalAmount}</Typography>
          <Box>
            <Typography sx={{ width: "100%" }} variant="h6" color={"primary"} fontWeight={600}>
              or if you are using a mobile device, simply click <a href={upiIntentString}>here.</a>
            </Typography>
          </Box>
        </Box>
      </Modal> */}
      {paymentDrawer && <Payment
        key={(openDrawer as unknown) as React.Key}
        tooltipIsOpen={tooltipIsOpen}
        setTooltipIsOpen={setTooltipIsOpen}

      />}
    </>
  );
};

export default ProductDetails;
