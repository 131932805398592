import activeToggleIcon from "../../assets/images/activeToggleIcon.svg";
import logo from "../../assets/images/cloud.svg";
import illustration from "../../assets/images/illustration.svg";
import cloud from "../../assets/images/cloud.svg";
import analytics from "../../assets/images/analytics.svg";
import DrawerBottomElement from "../../assets/images/cloud.svg";
import HeaderBackground from "../../assets/images/cloud.svg";
import ImageBackground from "../../assets/images/bg.jpeg";
import pack from "../../assets/images/pack.jpg";
import blueTextLogo from "../../assets/images/blueTextLogo.svg";
import blueTextLogoImg from "../../assets/images/blueTextLogo.png";

export const images = {
	activeToggleIcon,
	DrawerBottomElement,
	HeaderBackground,
	logo,
	illustration,
	cloud,
	analytics,
	ImageBackground,
	pack,
	blueTextLogo,
	blueTextLogoImg,
};
