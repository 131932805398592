import { FormModel } from "components/Form";
import { URLS } from "utils/constants/urls";


export const CouponsFilter = (): FormModel[] => {
  return [
    {
      label: 'Brand',
      value: "",
      size: "medium",
      autoFocus: true,
      type: "autocomplete",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "brand",
      validators: [],
      responsive: { xs: 12 },
      required: false,
      url: URLS.filterAutocompleteUrl,
    },
    {
      label: 'Category',
      value: "",
      size: "medium",
      type: "autocomplete",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "category",
      validators: [],
      responsive: { xs: 12 },
      required: false,
      url: URLS.filterAutocompleteUrl,
    },
  ];
};
