import { createSlice } from '@reduxjs/toolkit';
import { removeFromStorage, saveToStorage } from '../../storage';
const cancelValues = [
  'PLACED',
  'RELEASE_ACCEPTED',
  'PARTIALLY_READY_TO_PICK',
  'READY_TO_PICK',
  'PARTIALLY_PICKED',
  'PARTIALLY_RELEASED',
  // 'PICKED',
  // 'PARTIALLY_READY_TO_PACK',
  // 'READY_TO_PACK',
  // 'PARTIALLY_PACKED',
  // 'PACKED',
  'RELEASED',
  'CREATED',
  // 'CARRIER_BOOKED',P
  // 'OUT-FOR_PICKUP',
  'RELEASED',
  // 'IN_TRANSIT',
  // 'RECEIVED',
];
const returnValues = ['DELIVERED', 'PICKED_UP'];

interface initStateProps {
  orderHistoryApiData: any[];
  orderHistoryCheckedData: any[];
  row: number;
  page: number;
  totalElements: number;
}
const orderHistorySlice = createSlice({
  name: 'orderHistorySlice',
  initialState: {
    orderHistoryApiData: [],
    orderHistoryCheckedData: [],
    row: 10,
    page: 0,
    totalElements: 0,
  },
  reducers: {
    setCheckedOrders(state, action: { payload: { checked: boolean; item: any } }) {
      const { checked, item } = action.payload;
      if (checked) {
        state.orderHistoryCheckedData = [...state.orderHistoryCheckedData, item] as never;
      } else {
        state.orderHistoryCheckedData = state.orderHistoryCheckedData.filter((i: any) => i.id !== item.id);
      }
    },
    setOrderHistoryApiData(state, action) {
      state.orderHistoryApiData = action.payload;
    },
    setRow(state, action) {
      state.row = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setTotalElements(state, action) {
      state.totalElements = action.payload;
    },
  },
});

const getQuantity = (q: number, cq: null | number) => {
  if (!cq) return q;
  return q - cq;
};
const getCancellableData = (items: any) => {
  if (!items.length) return;
  const obj = items
    .filter((i: any) => cancelValues.includes(i.customerOrder.orderStatus))
    .filter((i: any) => i.customerOrder.orderNumber === items[0].customerOrder.orderNumber)
    .filter((i: any) => getQuantity(i.quantity, i.cancelledQuantity) > 0);
  if (obj.length) {
    const cancelData = {
      orderNumber: obj[0].customerOrder.orderNumber,
      promiseId: obj[0].customerOrder.promiseId,
      posNo: obj[0].customerOrder.posNo,
      storeId: obj[0].customerOrder.storeId,
      products: obj.map((item: any) => ({
        productCode: item.productCode,
        variantCode: item.variantCode,
        quantity: getQuantity(item.quantity, item.cancelledQuantity),
        price: item.price,
        orderedQuantity: item.quantity,
        productName: item.productName,
        variantName: item.variantName,
      })),
    };

    return cancelData;
  }
};

const getReturnableData = (items: any) => {
  if (!items.length) return;
  const obj = items
    .filter((i: any) => returnValues.includes(i.customerOrder.orderStatus))
    .filter((i: any) => i.customerOrder.orderNumber === items[0].customerOrder.orderNumber)
    .filter((i: any) => getQuantity(i.quantity, i.cancelledQuantity) > 0);
  if (obj.length) {
    const returnData = {
      orderNumber: obj[0].customerOrder.orderNumber,
      promiseId: obj[0].customerOrder.promiseId,
      posNo: obj[0].customerOrder.posNo,
      storeId: obj[0].customerOrder.storeId,
      products: obj.map((item: any) => ({
        productCode: item.productCode,
        variantCode: item.variantCode,
        quantity: getQuantity(item.quantity, item.cancelledQuantity),
        amount: item.price,
        orderedQuantity: item.quantity,
        productName: item.productName,
        variantName: item.variantName,
      })),
    };

    return returnData;
  }
};

export default orderHistorySlice.reducer;

export const { setCheckedOrders, setOrderHistoryApiData, setPage, setRow, setTotalElements } =
  orderHistorySlice.actions;

// export const createOrderPromiseResponse = (state: { createOrderSlice: initStateProps }) => state.createOrderSlice.promiseResponse;
// export const createOrderPromisePayloadData = (state: { createOrderSlice: initStateProps }) => state.createOrderSlice.promisePayloadData;
// export const orderSummaryResponse = (state: { createOrderSlice: initStateProps }) => state.createOrderSlice.orderSummaryResponse;
// export const revisionId = (state: { createOrderSlice: initStateProps }) => state.createOrderSlice.revisionId;
export const cancellableOrders = (state: { orderHistorySlice: initStateProps }) =>
  getCancellableData(state.orderHistorySlice.orderHistoryCheckedData);
export const returnableOrders = (state: { orderHistorySlice: initStateProps }) =>
  getReturnableData(state.orderHistorySlice.orderHistoryCheckedData);
export const orderHistoryRow = (state: { orderHistorySlice: initStateProps }) => state.orderHistorySlice.row;
export const orderHistoryPage = (state: { orderHistorySlice: initStateProps }) => state.orderHistorySlice.page;
export const orderHistoryApiData = (state: { orderHistorySlice: initStateProps }) =>
  state.orderHistorySlice.orderHistoryApiData;
export const orderHistoryCheckedData = (state: { orderHistorySlice: initStateProps }) =>
  state.orderHistorySlice.orderHistoryCheckedData;
export const totalOrderHistoryElements = (state: { orderHistorySlice: initStateProps }) =>
  state.orderHistorySlice.totalElements;
