import { createSlice } from "@reduxjs/toolkit";
import { removeFromStorage, saveToStorage } from "../../storage";

interface initStateProps {
	rewards: any;
}
const rewardsSlice = createSlice({
	name: "rewardsSlice",
	initialState: {
		returnData: null,
	},
	reducers: {
		setReturOrderData(state, action) {
			state.returnData = action.payload;
		},
	},
});

export default rewardsSlice.reducer;

export const { setReturOrderData } = rewardsSlice.actions;

export const rewards = (state: { rewardsSlice: initStateProps }) => state.rewardsSlice.rewards;
