import { Grid, useMediaQuery, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { routes } from "utils/constants/routes";

export const Footer = () => {
    const theme = useTheme()
    const navigate = useNavigate()
    const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const screenSizeUpLg = useMediaQuery(theme.breakpoints.up('lg'));

    const data = [
        {
            label: 'Terms of Usage',
            url: routes.terms
        },
        {
            label: 'Privacy Policy',
            url: routes.privacyPolicy
        },
        {
            label: 'About Us',
            url: routes.about
        },
        {
            label: 'Contact Us',
            url: routes.contact
        },

    ]
    return (
        <Grid container alignItems={'center'} rowSpacing={screenSizeDownSm ? 2 : 0}
            sx={{
                backgroundColor: theme.palette.secondary.dark,
                py: 2,
                px: screenSizeUpLg ? 10 : 4,
                gap: 2,
                justifyContent: 'space-around'
            }}
        >
            {data.map((i: any, index: number) =>
                <Grid key={index} item sx={{ cursor: 'pointer', color: 'white' }} onClick={() => navigate(i.url)}>
                    {i.label}
                </Grid>
            )}
        </Grid >
    )
}