import {
  FC,
  MouseEvent,
  RefObject,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Grid,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import Form, { FormDataModel } from "../../../components/Form";
import { SignUpForm } from "./SignUpForm";
import PrimaryButton from "../../../components/Button/PrimaryButton";
import { removeErrorFieldsFromValues } from "../../../utils/validators";
import { URLS } from "../../../utils/constants/urls";
import { useDispatch } from "react-redux";
import axiosInstance from "../../../utils/redux/axios";
import strings from "../../../common/Translation/Translate";
import { setNotificationMessage } from "../../../utils/redux/reducer/Notification-details-slice";
import Captcha from "../../../components/Captcha/Captcha";
import { SignUpFormOptional } from "./SignUpFormOptional";
// import { ChangeEmailFormDialog } from "components/Dialog/ChangeEmailFormDialog";

const SignUp: FC<any> = ({ setDrawerState, setMessage }: { setDrawerState: any, setMessage: any }) => {
  const theme = useTheme();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  let signUpForm: RefObject<Form | null | undefined> = useRef();
  // let signUpFormOptional: RefObject<Form | null | undefined> = useRef();
  const [hasError, setHasError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [captchaRes, setCaptchaRes] = useState<string>("");
  const [captaFlag, setCaptaFlag] = useState<boolean>(false);
  const verifyCallback = (response: string, captchaFlag: boolean) => {
    setCaptaFlag(captchaFlag);
    setCaptchaRes(response);
  };


  const handleSignUp = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const signupFormData = signUpForm.current as {
      getFormData: () => { formData: FormDataModel; isFormValid: boolean };
    };
    const signUpFormData = signupFormData.getFormData();
    const formData1 = signUpFormData.formData;
    const isFormValid1 = signUpFormData.isFormValid
    const bodyData1 = removeErrorFieldsFromValues(formData1);

    // const signupFormDataOptional = signUpFormOptional.current as {
    //   getFormData: () => { formData: FormDataModel; isFormValid: boolean };
    // };
    // const signUpFormDataOptional = signupFormDataOptional.getFormData();
    // const formData2 = signUpFormDataOptional.formData;
    // const isFormValid2 = signUpFormDataOptional.isFormValid
    // const bodyData2 = removeErrorFieldsFromValues(formData2);

    const body = {
      "firstName": bodyData1.firstName,
      "lastName": bodyData1.lastName,
      "phone": bodyData1.phone,
      "email": bodyData1.email,
      "password": bodyData1.password,
      // "pan": "",
      // "aadhaarNumber": "",
      "captchaResponse": null
      // {
      //   "captchaRes": captchaRes
      // }
    }
    try {
      setHasError(false);
      if (isFormValid1) {
        if (!captchaRes) {
          setCaptaFlag(true);
          return false;
        }
        setLoading(true);
        const { status, data } = await axiosInstance.post(URLS.signup, body);
        if (status === 200) {
          setLoading(false);
          dispatch(
            setNotificationMessage({
              display: true,
              severity: "success",
              message: "Congratulations, your regstration was successful!!"
            })
          );
          dispatch(setDrawerState(1))
        }
      } else {
        setHasError(true);
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: error.response.data.message,
          })
        );
      }
    }
  };

  return (
    <>
      <Box bgcolor={theme.palette.secondary.light}>
        <Grid
          container
          py={2}
          px={5}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid item>
            <Typography fontWeight={"bold"} color={"primary"} fontSize={'20px'}>
              {strings.signUp}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          mt={1}
          px={4}
          bgcolor={theme.palette.secondary.light}
          spacing={1}
          flexDirection={"column"}
        >
          <Grid item>
            <Grid container columnSpacing={2} rowSpacing={1}>
              <Form
                hasError={hasError}
                ref={signUpForm as RefObject<Form>}
                model={SignUpForm(strings)}
                values={{}}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography color={"secondary"} fontWeight={"bold"} my={1}>
              Optional Details
            </Typography>
          </Grid>
          {/* <Grid item>
            <Grid container columnSpacing={2} rowSpacing={1}>
              <Form
                hasError={hasError}
                ref={signUpFormOptional as RefObject<Form>}
                model={SignUpFormOptional(strings)}
                // onChange={handleOptionalFormChange}
                values={{}}
              />
            </Grid>
          </Grid> */}
          <Grid item xs={12}>
            <Captcha verify={verifyCallback} />
            <Typography variant="subtitle2" color="error">
              {captaFlag ? strings.captchaValidation : ""}
            </Typography>
          </Grid>
          <Grid item xs={5.2}>
            <PrimaryButton
              disabled={loading}
              sx={{ mt: 1, py: 1 }}
              onClick={handleSignUp}
              type="submit"
            >
              {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
              {strings.signUp}
            </PrimaryButton>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Box display={"flex"}>
              <Typography fontSize={'0.75rem'}>{strings.HaveAnAccount}&nbsp;</Typography>
              <Link
                sx={{ cursor: "pointer" }}
                color={theme.palette.secondary.main}
                onClick={() => setDrawerState(1)}
                underline={"hover"}
              >
                <Typography fontWeight={500} fontSize={'0.75rem'}>{strings.LoginHeretext}</Typography>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* <ChangeEmailFormDialog
        open={changeEmailFormDialogOpen}
        handleClose={setChangeEmailFormDialogOpen}
        email={email}
        resendOtpFunc={resendOtpFunc}
        verifyOtpMailType={"REGISTRATION"}
        successMessage={strings.registrationSuccessText}
        header={strings.registrationTextLower}
        setDrawerState={setDrawerState}
        setDrawerMessage={setMessage}
        caseType='signup'
      /> */}
    </>
  );
};

export default SignUp;
