import { Validators } from "../../../utils/validators";
import { FormModel } from "../../../components/Form";

export const LoyaltyForm = (strings: any): FormModel[] => {
	const arrayFields = [
		{
			label: "Loyatly Points",
			value: "",
			size: "small",
			autoFocus: false,
			type: "text",
			typeValue: "text",
			variant: "outlined",
			placeholder: "",
			field: "reward_value",
			validators: [{ check: Validators.required, message: strings.requiredField }],
			responsive: { xs: 12, sm: 6 },
			required: true,
			inputProps: { readOnly: true },
			disabled: true,
		},
		{
			label: "Loyalty Tier",
			value: "",
			size: "small",
			autoFocus: false,
			type: "text",
			typeValue: "text",
			variant: "outlined",
			placeholder: "",
			field: "loyalty_tier",
			validators: [
				{
					check: Validators.required,
					message: strings.requiredField,
				},
			],
			responsive: { xs: 12, sm: 6 },
			required: true,
			inputProps: { readOnly: true },
			disabled: true,
		},
	];
	return arrayFields;
};
