import { createSlice } from "@reduxjs/toolkit";
import { clearStorage, fetchFromStorage, removeFromStorage, saveToStorage } from "../../storage";
import { identifiers } from "../../constants/identifiers";
import { URLS } from "../../constants/urls";
import axiosInstance from "../axios";
import strings from "../../../common/Translation/Translate";

const fromStorage = fetchFromStorage(identifiers.access_token) as any;
const link = fetchFromStorage(identifiers.preview);
interface AuthenticationState {
	user: any;
	authenticated: boolean;
	privilege: any;
	translation: any;
	priviewLink: string | null;
	languageList: [] | null;
	selectedlanguage: any;
	redeemPercent: any;
	theme: {
		primary: string;
		primaryTextColor: string;
		secondaryMain: string;
		secondaryDark: string;
		secondaryLight: string;
		secondaryTextColor: string;
		hoverColor: string;
		buttonGradiant?: string;
		headerColor?: string;
	};
	drawerStateGlobal: 1 | 2 | 3;
	openAuthenticationDrawer: boolean;
	initialData: any;
}
const authenticationSlice = createSlice({
	name: "authenticationSlice",
	initialState: {
		authenticated: fromStorage ? true : false,
		user: fetchFromStorage(identifiers.userDetails) ? JSON.parse(fetchFromStorage(identifiers.userDetails) as string) : null,
		priviewLink: link || "",
		privilege: fetchFromStorage(identifiers.privilegeList),
		redeemPercent: fetchFromStorage("redeemPercent") ? JSON.parse(fetchFromStorage("redeemPercent") as string) : null,
		openAuthenticationDrawer: false,
		drawerStateGlobal: 1,
	},
	reducers: {
		setAuthentication(state, action) {
			if (action.payload) {
				// const decoded = jwt_decode(action.payload.token) as any;
				// console.log(decoded);
				const userDetails = JSON.stringify({ username: action.payload.username, email: action.payload.email });
				saveToStorage(identifiers.access_token, action.payload.access_token);
				saveToStorage(identifiers.privilegeList, action.payload.privileges);
				saveToStorage(identifiers.refresh_token, action.payload.refresh_token);
				saveToStorage(identifiers.preview, action.payload.previewLink);
				state.priviewLink = action.payload.priviewLink;
				state.authenticated = true;
				state.user = { username: action.payload.username, email: action.payload.email };
				saveToStorage(identifiers.userDetails, userDetails);
				state.privilege = action.payload.privileges;
				state.redeemPercent = action.payload.redeemPercent || 10;
				saveToStorage("redeemPercent", action.payload.redeemPercent || 10);
			} else {
				removeFromStorage(identifiers.access_token);
				state.authenticated = false;
			}
		},
		logout(state) {
			clearStorage("local");
			state.authenticated = false;
		},
		setUser(state, action) {
			state.user = { ...state.user, ...action.payload };
		},
		setOpenAuthenticationDrawer(state, action) {
			state.openAuthenticationDrawer = action.payload;
		},
		setDrawerStateGlobal(state, action) {
			state.drawerStateGlobal = action.payload;
		},
	},
});

export const refreshToken = (refreshtoken?: string) => async (dispatch: any) => {
	const body = new URLSearchParams();
	const token = fetchFromStorage(identifiers.refresh_token);

	body.append("refresh_token", refreshtoken || token || "");
	body.append("scope", "webclient");
	body.append("grant_type", "refresh_token");
	const config = {
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
		},
		auth: {
			username: process.env.REACT_APP_CLIENT,
			password: process.env.REACT_APP_SECRET,
		},
	};

	try {
		const { status, data } = await axiosInstance.post(URLS.login, body, config as any);
		if (status === 200) {
			dispatch(setAuthentication(data));
		}
	} catch (e) {
		return e;
	}
};

export default authenticationSlice.reducer;

export const { setAuthentication, logout, setUser, setOpenAuthenticationDrawer, setDrawerStateGlobal } = authenticationSlice.actions;

export const User = (state: { authenticationSlice: AuthenticationState }) => state.authenticationSlice.user;
export const getTheme = (state: { authenticationSlice: AuthenticationState }) => state.authenticationSlice.theme;
export const Privilege = (state: { authenticationSlice: AuthenticationState }) => state.authenticationSlice.privilege;
export const translation = (state: { authenticationSlice: AuthenticationState }) => state.authenticationSlice.translation;
export const languages = (state: { authenticationSlice: AuthenticationState }) => state.authenticationSlice.languageList;
export const defaultLanguage = (state: { authenticationSlice: AuthenticationState }) => state.authenticationSlice.selectedlanguage;
export const redeemPercentage = (state: { authenticationSlice: AuthenticationState }) => state.authenticationSlice.redeemPercent;
export const Authenticated = (state: { authenticationSlice: AuthenticationState }) => state.authenticationSlice.authenticated;
export const priviewLink = (state: { authenticationSlice: AuthenticationState }) => state.authenticationSlice.priviewLink;
export const openAuthenticationDrawer = (state: { authenticationSlice: AuthenticationState }) =>
	state.authenticationSlice.openAuthenticationDrawer;
export const drawerStateGlobal = (state: { authenticationSlice: AuthenticationState }) => state.authenticationSlice.drawerStateGlobal;

export const initData = (state: { authenticationSlice: AuthenticationState }) => state.authenticationSlice.initialData;
