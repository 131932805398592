import { Avatar, Badge, Box, CssBaseline, Grid, IconButton, MenuItem, Popover, Select, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { useDispatch, useSelector } from 'react-redux';
import { createDropdownData } from '../../utils/validators/HelperFunctions';
import {
  defaultLanguage,
  languages,
  Authenticated,
  User,
  initData,
  setOpenAuthenticationDrawer,
  getTheme,
  setAuthentication,
  setDrawerStateGlobal,
} from '../../utils/redux/reducer/authentication-slice';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import cbt_logo from '../../assets/images/cbt_logo.png';
import HeaderBackground from 'assets/images/HeaderBackground';
import LogoutIcon from "../../assets/images/LogoutIcon";
import strings from 'common/Translation/Translate';
import PrimaryButton from 'components/Button/PrimaryButton';
import { useState } from 'react';
import { identifiers } from 'utils/constants/identifiers';
import { URLS } from 'utils/constants/urls';
import axiosInstance from 'utils/redux/axios';
import { fetchFromStorage, clearStorage } from 'utils/storage';
import LoginIcon from '@mui/icons-material/Login';
import { routes } from 'utils/constants/routes';
import AuthenticationDrawer from 'screens/Authentication/AuthenticationDrawer/AuthenticationDrawer';
import { Footer } from './Footer';
import Logo from 'assets/images/Logo';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { toggleDrawer } from 'utils/redux/reducer/cart-slice';
import { totalQuantity } from 'utils/redux';
type HeaderProps = {
  children: any;
};

export const Header = ({ children }: HeaderProps) => {
  const defaultTheme = useTheme();
  const screenSizeDownMd = useMediaQuery(defaultTheme.breakpoints.down('md'));
  const languageData = createDropdownData(useSelector(languages), ['value', 'text']) || [];
  const lang = useSelector(defaultLanguage);
  const user = useSelector(User);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialData = useSelector(initData);
  const location = useLocation();
  const isLogin = useSelector(Authenticated);
  const totalCartItems = useSelector(totalQuantity)



  const handleLogout = async () => {
    const body: { refreshToken: string | null } = {
      refreshToken: fetchFromStorage(identifiers.refresh_token),
    };
    try {
      const { status }: { status: number } = await axiosInstance.post(URLS.logout, body);
      if (status === 200) {
        clearStorage('local');
        dispatch(setAuthentication(null));
      }
    } catch (e) {
      navigate('/')
      clearStorage('local');
      dispatch(setAuthentication(null));
    }
    // finally {
    //   getInitialData();
    //   getTheme();
    // }
  };
  const handleCartDrawer = () => {
    dispatch(toggleDrawer())
  }


  const BeforeLoggedInComponent = () => (
    <>
      {screenSizeDownMd ?
        <LoginIcon
          sx={{ color: defaultTheme.palette.primary.main }}
          onClick={() => dispatch(setOpenAuthenticationDrawer(true))}
        />
        :
        <Box display={'flex'} gap={1}>
          <PrimaryButton
            variant="contained"
            fullWidth
            sx={{ backgroundColor: defaultTheme.palette.primary.main, flex: 2, width: '10vw' }}
            onClick={() => {
              dispatch(setDrawerStateGlobal(3))
              dispatch(setOpenAuthenticationDrawer(true))
            }}
          >
            <Typography fontSize={'14px'} fontWeight={600}>Sign Up</Typography>
          </PrimaryButton>
          <PrimaryButton
            variant="contained"
            fullWidth
            sx={{ backgroundColor: defaultTheme.palette.primary.main, flex: 2, width: '10vw' }}
            onClick={() => dispatch(setOpenAuthenticationDrawer(true))}
          >
            <Typography fontSize={'14px'} fontWeight={600}>{strings.loginText}</Typography>
          </PrimaryButton>
        </Box>
      }
    </>);

  const AfterLoggedInComponent = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const openPopOver = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const openPop = Boolean(anchorEl);


    const handleClose = () => {
      setAnchorEl(null);
    };
    return useMediaQuery(defaultTheme.breakpoints.up('md')) ?
      (<Box display={"flex"} gap={2} alignItems={"center"} height={"10vh"} >
        <Box display={"flex"} flexDirection={"column"} color={"white"} mr='20px'>
          <Badge badgeContent={totalCartItems} color="primary">

            <ShoppingCartIcon fontSize='large' onClick={handleCartDrawer} sx={{ cursor: "pointer" }} />
          </Badge>
        </Box>
        <Box display={"flex"} gap={1} alignItems={"center"}
          onClick={() => navigate(routes.user_profile)}
          sx={{
            ":hover": {
              cursor: "pointer"
            }
          }}>

          <Avatar sx={{
            bgcolor: defaultTheme.palette.primary.main,
            color: "white",
            width: '34px',
            height: '34px',
            fontSize: '0.87rem',
            fontWeight: 500,
            textTransform: "uppercase"
          }}>
            {user?.username && user.username.split(" ")[0][0]}{user.username.split(" ")[1][0]}
          </Avatar>
          <Box display={"flex"} flexDirection={"column"} color={"white"}>
            <Typography fontWeight={"500"} fontSize={"0.87rem"}>{user.username}</Typography>
            <Typography fontSize={"0.68rem"}>{user.email}</Typography>
          </Box>
        </Box>
        <IconButton size="small" onClick={handleLogout}>
          <LogoutIcon />
        </IconButton>
      </Box >) :
      (<>
        <Box display={"flex"} flexDirection={"column"} color={"white"} mr='20px'>
          <Badge badgeContent={totalCartItems} color="primary">

            <ShoppingCartIcon fontSize='large' onClick={handleCartDrawer} sx={{ cursor: "pointer" }} />
          </Badge>
        </Box>
        <Avatar sx={{
          bgcolor: defaultTheme.palette.primary.main,
          color: "white",
          width: '34px',
          height: '34px',
          fontSize: '0.87rem',
          fontWeight: 500,
          textTransform: "uppercase"
        }}
          onClick={(e: any) => openPopOver(e)}>
          {user?.username && user.username.split(" ")[0][0]}{user.username.split(" ")[1][0]}
        </Avatar>
        <Popover
          id='spring-popper'
          open={openPop}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Grid container flexDirection={'column'} gap={1} py={2} px={2}>
            <Grid item>
              <Grid container alignItems={'center'}>
                <Grid item>
                  <Avatar sx={{
                    bgcolor: defaultTheme.palette.primary.main,
                    color: "white",
                    width: '34px',
                    height: '34px',
                    fontSize: '0.87rem',
                    fontWeight: 500,
                    textTransform: "uppercase"
                  }}>
                    {user?.username && user.username.split(" ")[0][0]}{user.username.split(" ")[1][0]}
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    onClick={() => { handleClose(); navigate(routes.user_profile) }}
                    className={'cursor-pointer'}
                    sx={{ mx: 2, fontWeight: 600 }}>
                    {strings.updateProfile_title_text}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems={'center'} justifyContent={'start'}>
                <IconButton size="small" sx={{ p: 0 }} onClick={handleLogout}>
                  <LogoutIcon />
                </IconButton>
                <Typography
                  variant="body2"
                  onClick={handleLogout}
                  className={'cursor-pointer'}
                  sx={{ mx: 2, fontWeight: 600 }}>
                  {strings.logoutText}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Popover>
      </>
      );
  }


  return (
    <>
      <Box sx={{ display: 'flex', height: '100%' }} className="header-div">
        <CssBaseline />
        <Box sx={{
          height: { xs: 'calc(100vh - 100px)', md: 'calc(100vh - 55px)' },
          overflow: 'auto'
        }}>
          <Box sx={{ width: '100vw' }}>
            <MuiAppBar
              sx={{
                height: '80px',
                position: 'static',
                backgroundColor: defaultTheme.palette.secondary.dark,
              }}
              elevation={0}
            >
              <Toolbar>
                <Box
                  sx={{
                    paddingRight: '1%',
                    marginY: '1%',
                    display: 'flex',
                    gap: '10px',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      alignItems: 'center',
                      color: defaultTheme.palette.primary.main,
                      cursor: 'pointer'
                    }}
                    onClick={() => navigate(routes.root)}
                  >
                    <Logo />
                  </Box>
                  <Box sx={{
                    display: "flex",
                    flex: 0.5,
                    gap: "10px",
                    justifyContent: "flex-end",
                    alignItems: "center"
                  }}
                  >
                    {isLogin ? (
                      <AfterLoggedInComponent />
                    ) : (
                      <BeforeLoggedInComponent />
                    )}
                  </Box>
                </Box>
              </Toolbar>
            </MuiAppBar>
            {children}
          </Box>
        </Box>
      </Box >
      <footer>
        <Footer />
      </footer>
      <AuthenticationDrawer />
    </>
  );
};

export default Header;