import { FC, MouseEvent, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertColor, Card, CardContent, Grid, Skeleton, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import Form, { FormDataModel } from "../../components/Form";
import { UserProfileForm } from "./UserProfileForm";
import PrimaryButton from "../../components/Button/PrimaryButton";
import { removeErrorFieldsFromValues } from "../../utils/validators";
import { URLS } from "../../utils/constants/urls";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../utils/redux/axios";
import strings from "../../common/Translation/Translate";
import { createDropdownData } from "../../utils/validators/HelperFunctions";
import PageLoader from "components/PageLoader";
import { FormDialog } from "components/Dialog/FormDialog";
import Notification from "components/Notification";
import { ChangeEmailFormDialog } from "components/Dialog/ChangeEmailFormDialog";
import { refreshToken, setUser } from "utils/redux/reducer/authentication-slice";
import { Box } from "@mui/system";
import { ChangePasswordModal } from "./ChangePasswordModal";
import { ReedemCoupons } from "utils/validators/HelperComponents";
import ListPage from "screens/Common/ListPage";
import HistoryTabs from "./HistoryTabs";
import { rewards, setRewardPoints } from "utils/redux/reducer/rewards-slice";
import Address from './Address'
import { LoyaltyForm } from "./Loyalty/LoyaltyForm";
import { on } from "events";



const UserProfile: FC<any> = () => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const screenSizeDownMd = useMediaQuery(theme.breakpoints.down("md"));
	const screenSizeDownSm = useMediaQuery(theme.breakpoints.down("sm"));
	let formRef: RefObject<Form | null | undefined> = useRef();
	let loyaltyFormRef: RefObject<Form | null | undefined> = useRef();
	const [hasError, setHasError] = useState<any>({ err: false, msg: "" });
	const [loading, setLoading] = useState<boolean>(false);
	const [userDetails, setUserDetails] = useState<any>(null);
	const [openChangePasswordModal, setOpenChangePasswordModal] = useState<boolean>(false);
	const [resetDisabled, setResetDisabled] = useState<boolean>(true);
	const [message, setMessage] = useState<{
		display: boolean;
		severity: AlertColor | null;
		message: any;
	}>({
		display: false,
		severity: null,
		message: "",
	});
	const closeNotification = (value: boolean) => {
		setMessage({ ...message, display: value });
	};

	const handleMessage = (message: { display: boolean; severity: AlertColor | null; message: any }) => {
		setMessage(message);
	};
	// const changeValueFunc = () => setOpenDialog(true)

	// const rewards_Points = useSelector(state:any => state.rewardsSlice.rewards)
	const rewards_points = useSelector(rewards);


	const onChange = () => {
		setResetDisabled(false)
	}
	const getRewardPoints = async () => {

		try {
			const config = {
				headers: {
					"x-customer-id": userDetails.customerNumber,
				}
			};
			const { status, data } = await axiosInstance.get(URLS.getLoyaltyPoints, config);
			if (status === 200) {
				const filteredReward = data.reward_balance.find((reward: { reward_name: string }) => reward.reward_name === "welcome");
				const body = {
					reward_value: filteredReward.reward_value || 0,
					loyalty_tier: data.customer.tier_type
				}
				dispatch(setRewardPoints(body));
			}
		} catch (e) {
			console.log(e);
		}

	};

	useEffect(() => {

		if (userDetails) {
			setLoading(true)
			getRewardPoints()
			setLoading(false)
		}

	}, [userDetails]);

	const getInitialData = useCallback(async () => {
		try {
			setLoading(true);
			const res1 = await axiosInstance.get(URLS.userProfile);
			if (res1.status === 200) {
				setUserDetails(res1.data);
				setLoading(false);
				dispatch(setUser(res1.data));

			}
		} catch (error: any) {
			setLoading(false);
			setMessage({
				display: true,
				severity: "error",
				message: error.response?.data.error_description || error.response?.data.message || "An error occurred",
			});
		}
	}, []);

	useEffect(() => {
		getInitialData();
	}, [getInitialData]);

	const updateProfileBtnOnClick = async () => {
		try {
			setHasError(false);
			const { getFormData } = formRef.current as {
				getFormData: () => { formData: FormDataModel; isFormValid: boolean };
			};
			const { formData, isFormValid } = getFormData();
			if (isFormValid) {
				setLoading(true);
				const body = removeErrorFieldsFromValues(formData);
				const { status } = await axiosInstance.put(URLS.userProfile, {
					...body,
				});
				if (status === 200) {
					setLoading(false);
					setMessage({
						display: true,
						severity: "success",
						message: "Profile has been updated Successfully",
					});
					dispatch(refreshToken() as any);
					await getInitialData();
					setResetDisabled(true)
				}
			} else {
				setHasError(true);
			}
		} catch (error: any) {
			setLoading(false);
			if (error.response) {
				setMessage({
					display: true,
					severity: "warning",
					message: error.response.data.message,
				});
			}
		}

	};

	const changePasswordBtnOnClick = () => {
		setOpenChangePasswordModal(true);
	};
	const handleReset = () => {
		formRef.current?.resetForm();
		setResetDisabled(true)
		setUserDetails(JSON.parse(JSON.stringify(userDetails)));


	}
	return (
		<>
			{loading && <PageLoader />}
			{message.display && (
				<Notification
					isOpen={message.display}
					message={message.message}
					severity={message.severity as AlertColor}
					closeNotification={closeNotification}
				/>
			)}
			{userDetails ? (
				<>

					<Card
						sx={{
							marginTop: 2,
							marginX: screenSizeDownMd ? (screenSizeDownSm ? 3 : 8) : 20,
							paddingX: screenSizeDownMd ? (screenSizeDownSm ? 1 : 2) : 3,
						}}
					>
						<CardContent>

							<ReedemCoupons loading={false} setLoading={setLoading} />
						</CardContent>
					</Card>
					<Typography
						color={"primary"}
						fontWeight={"600"}
						fontSize="20px"
						sx={{
							marginX: screenSizeDownMd ? (screenSizeDownSm ? 5 : 10) : 25,
							marginTop: 2,
						}}
					>
						{strings.update_profile_title_text}
					</Typography>
					<Card
						sx={{
							marginTop: 2,
							marginX: screenSizeDownMd ? (screenSizeDownSm ? 3 : 8) : 20,
							paddingX: screenSizeDownMd ? (screenSizeDownSm ? 1 : 2) : 3,
						}}
					>
						<CardContent>
							<Grid container columnSpacing={5} rowSpacing={1}>
								<Form
									hasError={hasError}
									onChange={onChange}
									ref={formRef as RefObject<Form>}
									model={UserProfileForm(strings)}
									// onChange={handleOptionalFormChange}
									values={userDetails}
								/>
								<Grid item xs={12}>
									<Grid
										container
										alignItems={"center"}
										paddingX={screenSizeDownMd ? 0 : 1}
										spacing={2}
										sx={{
											[theme.breakpoints.down("sm")]: {
												justifyContent: "center",
												paddingX: 0,
											},
										}}
									>
										<Grid item xs={12} sm={6} md={3} lg={3}>
											<PrimaryButton
												onClick={updateProfileBtnOnClick}
												sx={{
													minHeight: "44px",
												}}
											>
												{strings.update_profile_update_btn}
											</PrimaryButton>
										</Grid>
										<Grid item xs={12} sm={6} md={3} lg={3}>
											<PrimaryButton
												onClick={handleReset}
												sx={{
													minHeight: "44px",
												}}
												disabled={resetDisabled}
											>
												RESET
											</PrimaryButton>
										</Grid>
										<Grid item xs={12} sm={6} md={4} lg={3}>
											<PrimaryButton
												onClick={changePasswordBtnOnClick}
												variant="outlined"
												fullWidth
												sx={{
													minHeight: "44px",
													fontWeight: 600,
													whiteSpace: "nowrap",
													[theme.breakpoints.down("md")]: {
														textAlign: "center",
													},
													border: 0.5,
												}}
											>
												{strings.changePasswordText}
											</PrimaryButton>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
					<Typography
						color={"primary"}
						fontWeight={"600"}
						fontSize="20px"
						sx={{
							marginX: screenSizeDownMd ? (screenSizeDownSm ? 5 : 10) : 25,
							marginTop: 2,
						}}
					>
						Loyalty Points
					</Typography>
					<Card
						sx={{
							marginTop: 2,
							marginX: screenSizeDownMd ? (screenSizeDownSm ? 3 : 8) : 20,
							paddingX: screenSizeDownMd ? (screenSizeDownSm ? 1 : 2) : 3,
						}}
					>
						<CardContent>
							<Grid container columnSpacing={5} rowSpacing={1}>
								<Form
									ref={loyaltyFormRef as RefObject<Form>}
									model={LoyaltyForm(strings)}
									values={rewards_points}
								// onChange={handleOptionalFormChange}
								/>
							</Grid>
						</CardContent>
					</Card>
					<Typography
						color={"primary"}
						fontWeight={"600"}
						fontSize="20px"
						sx={{
							marginX: screenSizeDownMd ? (screenSizeDownSm ? 5 : 10) : 25,
							marginTop: 2,
						}}
					>
						Address
					</Typography>
					<Card
						sx={{
							marginTop: 2,
							marginX: screenSizeDownMd ? (screenSizeDownSm ? 3 : 8) : 20,
							paddingX: screenSizeDownMd ? (screenSizeDownSm ? 1 : 2) : 3,
						}}
					>
						<Address handleMessage={handleMessage} />
					</Card>
					<Card
						sx={{
							marginY: 2,
							marginX: screenSizeDownMd ? (screenSizeDownSm ? 3 : 8) : 20,
							paddingX: screenSizeDownMd ? (screenSizeDownSm ? 1 : 2) : 3,
						}}
					>
						<CardContent>
							<HistoryTabs loading={loading} setLoading={setLoading} />
						</CardContent>
					</Card>
				</>
			) : (
				<Box height={"80vh"} width={"100vw"}></Box>
			)}
			<ChangePasswordModal open={openChangePasswordModal} setOpen={setOpenChangePasswordModal} />

		</>
	);
};

export default UserProfile;
