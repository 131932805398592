import React, { useEffect } from 'react'
import { FC, MouseEvent, RefObject, useRef, useState } from "react";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { Card, Divider, FormControl, FormControlLabel, Grid, Modal, Radio, RadioGroup, Switch, Typography, useMediaQuery } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import strings from "common/Translation/Translate";
import PrimaryButton from "components/Button/PrimaryButton";
import DrawerMenu from "components/DrawerMenu";
import PageLoader from "components/PageLoader";
import { ToolTip } from "components/Tooltip/ToolTip";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { URLS } from "utils/constants/urls";
import { Authenticated } from "utils/redux";
import axiosInstance from "utils/redux/axios";
import { setNotificationMessage } from "utils/redux/reducer/Notification-details-slice";
import { setOpenAuthenticationDrawer } from "utils/redux/reducer/authentication-slice";
import { getDrawerWidth } from "utils/validators/HelperFunctions";
import Form, { FormDataModel } from 'components/Form';
import { PaymentForm } from './PaymentForm';
import { removeErrorFieldsFromValues } from "../../utils/validators";
import { v4 as uuidv4 } from 'uuid';
import { PlaceOrderForm } from 'screens/RewardsPage/PlaceOrderForm';
interface PaymentObject {
      payment_type: any,
      amount: number,
      payment_ref_id: string,
      currency_code: string,
      payment_gateway: string,
      payment_datetime: string,
      transaction_id: string,
}

const Payment = ({
      orderSummary,
      orderDetails,
      promisePayLoadData,
      address,
      fullfilmentOptions,
      handleOnselection,
      promiseResp,
      dataItemList,
      addressData,
      leftOutAmount,
      getFeasiableFullFillmentOption,
      setIsCartProductChangeable,
      variantObject,
      quantity
}: {
      orderDetails: any;
      orderSummary: any;
      promisePayLoadData?: any
      address: any
      fullfilmentOptions?: any
      handleOnselection: (bodyData: any) => void
      promiseResp: any,
      dataItemList: any,
      addressData: any,
      leftOutAmount: any,
      getFeasiableFullFillmentOption: any,
      setIsCartProductChangeable: any,
      variantObject: any,
      quantity: any
}) => {
      const theme = useTheme();
      const isLogin = useSelector(Authenticated);
      const dispatch = useDispatch();
      const navigate = useNavigate();
      const screenSizeDownXl = useMediaQuery(theme.breakpoints.down("xl"));
      const screenSizeDownLg = useMediaQuery(theme.breakpoints.down("lg"));
      const screenSizeDownMd = useMediaQuery(theme.breakpoints.down("md"));
      const screenSizeDownSm = useMediaQuery(theme.breakpoints.down("sm"));
      const screenSizeUpLg = useMediaQuery(theme.breakpoints.up("lg"));
      const screenSizeUpMd = useMediaQuery(theme.breakpoints.up("md"));
      const screenSizeUpSm = useMediaQuery(theme.breakpoints.up("sm"));
      const [hasError, setHasError] = useState(false as boolean);
      const [payments, setPayments] = useState<PaymentObject[]>([]);
      const paymentFormRef: RefObject<Form | null | undefined> = useRef(null);
      const [formData, setFormData] = useState({});
      const [selectedDeliveryOption, setSelectedDeliveryOption] = React.useState('');
      const [selectedReturnOption, setSelectedReturnOption] = React.useState('');
      const [displayButtons1, setDisplayButtons1] = React.useState(false as boolean)
      const [displayButtons2, setDisplayButtons2] = React.useState(false as boolean)
      const [commitStatus, setCommitStatus] = React.useState('OPEN' as string)
      const [loader, setLoader] = useState<boolean>(false);
      const [promiseError, setPromiseError] = useState(false);

      useEffect(() => {
            setSelectedDeliveryOption('')
            setSelectedReturnOption('')
      }, [fullfilmentOptions])
      const selectDefaultDeliveryOption = (defaultOptionId: any) => {
            setSelectedDeliveryOption(defaultOptionId)
            return defaultOptionId
      }
      const selectDefaultReturnOption = (defaultOptionId: any) => {
            setSelectedReturnOption(defaultOptionId)
            return defaultOptionId
      }


      const handleChangeReason = (event: any, data: any) => {
            let selectedOptions = [] as any;
            setSelectedDeliveryOption(event.target.value);
      }

      const handleError = (message: any, type: string) => {
            return dispatch(
                  setNotificationMessage({
                        display: true,
                        severity: message === "success" ? "success" : "error",
                        message: type === "success" ? message : message?.response?.data.message,
                  })
            );
      };
      const handleOnSaveSelectedDeliveryOption = () => {
            let selectedOptions = [] as any;
            fullfilmentOptions?.promise_groups?.forEach((group: any) => {
                  const { promise_group_id, promise_options } = group;
                  promise_options.forEach((option: any) => {
                        if (selectedDeliveryOption === option.option_id)
                              selectedOptions.push({
                                    group_id: promise_group_id,
                                    selected_option_id: option.option_id
                              })
                  });
            });

            const bodyData = {
                  promise_groups: selectedOptions
            };
            setDisplayButtons1(true)
            handleOnselection(bodyData)
      }

      const handleOnSaveSelectedReturnOption = () => {
            let selectedOptions = [] as any;
            fullfilmentOptions?.return_promise_groups?.forEach((group: any) => {
                  const { promise_group_id, promise_options } = group;
                  promise_options.forEach((option: any) => {
                        if (selectedReturnOption === option.option_id)
                              selectedOptions.push({
                                    group_id: promise_group_id,
                                    selected_option_id: option.option_id
                              })
                  });
            });


            const bodyData = {
                  return_promise_groups: selectedOptions
            };
            setDisplayButtons2(true)
            handleOnselection(bodyData)
      }

      const handleOnCommit = (statusData: string) => {
            const bodyData = {
                  status: statusData
            }
            handleOnStatusUpdate(bodyData)
            setIsCartProductChangeable(!(statusData === 'COMMITTED'))
            // fetchAddress(dataItemList?.pinCode, dataItemList?.items, addressData)

      }

      const handleOnStatusUpdate = async (bodyData: any) => {
            setLoader(true);
            try {

                  const { status, data } = await axiosInstance.post(URLS.state(promiseResp?.promise_id), bodyData)
                  if (status === 200) {
                        setCommitStatus(bodyData.status)
                        dispatch(setNotificationMessage({
                              display: true,
                              severity: "success",
                              message: `${bodyData.status} success!`,
                        }));
                        if (bodyData.status === 'OPEN') {
                              // fetchAddress(dataItemList?.pinCode, dataItemList?.items, addressData)
                              setDisplayButtons1(false)
                              setDisplayButtons2(false)
                              setSelectedDeliveryOption('')
                              setSelectedReturnOption('')
                              await getFeasiableFullFillmentOption(promiseResp, dataItemList?.pinCode, dataItemList?.items)

                        }
                  }
                  else {

                        setPromiseError(true)
                  }
            } catch (err: any) {

            }
            setLoader(false);
      }

      const getOrderDetails = () => {
            let orders = orderDetails.orders.map((item: any) => {
                  return {
                        ...item,
                        orderLineId: promisePayLoadData.products[0].co_line_id,
                        cartLineId: promisePayLoadData.products[0].co_line_id,
                  }
            })
            return orders
      }

      const placeOrder = async (paymentList: PaymentObject[]) => {
            setLoader(true);
            let orders = orderDetails.orders;
            let body = {
                  "countryCode": "CL",
                  "currencyCode": "CLP",
                  startTransactionDateTime: promisePayLoadData?.transaction_start_datetime,
                  cartId: promisePayLoadData.cart_id,
                  "orders": getOrderDetails(),
                  "payment": paymentList,
                  "posNo": "1",
                  "promiseId": promiseResp?.promise_id,
                  "promiseVersion": null,
                  "redeemFromLoyaltyPoints": false,
                  "storeId": "ejje-dotcom-001",
                  "supplyTypeCode": "B2C",
                  "transactionId": uuidv4()
            }
            try {
                  const result = await axiosInstance.post(URLS.placeOrder, body);
                  if (result.status === 200) {

                        dispatch(setNotificationMessage({
                              display: true,
                              severity: "success",
                              message: "Order placed successfully",
                        }));

                  }
            } catch (err: any) {

                  dispatch(setNotificationMessage({
                        display: true,
                        severity: "error",
                        message: err.message || "unknown error",
                  }));
            }
            setLoader(false);


      }

      const handlePayment = async (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            const { getFormData } = paymentFormRef.current as {
                  getFormData: () => { formData: FormDataModel; isFormValid: boolean };
            };
            const { formData, isFormValid } = getFormData();
            if (!isFormValid) setHasError(true);

            if (isFormValid && leftOutAmount > 0) {
                  const obj: PaymentObject = {
                        payment_type: formData.payment_method,
                        amount: Number(formData.amount),
                        payment_ref_id: formData.reference_id.toString(),
                        currency_code: formData.currency.toString(),
                        payment_gateway: "nebula-sandbox",
                        payment_datetime: new Date().toISOString(),
                        transaction_id: uuidv4()
                  };
                  setPayments([...payments, obj]);
                  placeOrder([...payments, obj]);
                  paymentFormRef.current?.resetForm();

            }
      };
      const getFulFillmentOptionMethodText = (method: string) => {
            if (method === 'COLLECTION_POINT')
                  return "DROP-OFF HUB"
            if (method === 'LOCAL_PICKUP')
                  return 'AT-HOME PICKUP SERVICE'
            return ''
      }


      return (
            <>

                  <Grid container columnSpacing={4} rowSpacing={1} py={1.5} >
                        <Grid item xs={12}>
                              <>
                                    {/* <Grid container gridTemplateColumns={"1fr"} justifyItems={"center"} py={1.5} >
                                    <Card sx={{ width: '100%' }}>
                                          <Typography fontWeight={600} sx={{ margin: "10px", paddingLeft: "10px" }}>Shipping And Billing Details</Typography>
                                          <Divider
                                                sx={{
                                                      mb: 1,
                                                      borderStyle: "solid",
                                                      borderColor: theme.palette.secondary.main,
                                                }}
                                          />
                                          <Grid item xs={12} sm={12} md={12} sx={{ paddingLeft: "20px", margin: "10px" }}>
                                                <Typography fontWeight={600} mb={1} >Shipping Address:</Typography>
                                                {
                                                      <Typography>  {address && Object.values(address[0] || {}).slice(1, -1).filter((item) => item).join(",")}</Typography>

                                                }
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={12} sx={{ paddingLeft: "20px", margin: "10px" }}>
                                                <Typography fontWeight={600} mb={1}>Billing Address:</Typography>
                                                {
                                                      <Typography>  {address && Object.values(address[1] || {}).slice(1, -1).filter((item) => item).join(",")}</Typography>

                                                }
                                          </Grid>
                                    </Card>
                              </Grid>

                              <Card sx={{ py: 2 }}>
                                    <Typography fontWeight={600} sx={{ margin: "10px", paddingLeft: "10px" }}>Order Details</Typography>
                                    <Divider
                                          sx={{
                                                mb: 1,
                                                borderStyle: "solid",
                                                borderColor: theme.palette.secondary.main,
                                          }}
                                    />
                                    <Box display={"flex"} alignItems={"center"} width={"100%"} sx={{ pl: 3 }}>
                                          <Box width={'30%'}>
                                                <Box display={"flex"}>
                                                      <Typography fontWeight={600}>Store Id</Typography>
                                                </Box>
                                                <Box display={"flex"}>
                                                      <Typography fontWeight={600}>Pos Id</Typography>
                                                </Box>
                                                <Box display={"flex"}>
                                                      <Typography fontWeight={600}>Supply Type Code</Typography>
                                                </Box>
                                                <Box display={"flex"}>
                                                      <Typography fontWeight={600}>Transaction Date</Typography>
                                                </Box>
                                          </Box>
                                          <Box>
                                                <Box display={"flex"}>
                                                      <Typography >{promisePayLoadData?.store_id}</Typography>
                                                </Box>
                                                <Box display={"flex"}>
                                                      <Typography >{promisePayLoadData?.pos_id}</Typography>
                                                </Box>
                                                <Box display={"flex"}>
                                                      <Typography >{'B2C'}</Typography>
                                                </Box>
                                                <Box display={"flex"}>
                                                      <Typography >{new Date(promisePayLoadData?.transaction_start_datetime).toLocaleString()}</Typography>
                                                </Box>
                                          </Box>
                                    </Box>
                              </Card> */}
                              </>


                              <Box >
                                    <Typography fontWeight={600} sx={{ p: 2 }}>Choose Delivery Option</Typography>
                                    <Divider
                                          sx={{
                                                mb: 1,
                                                borderStyle: "solid",
                                                borderColor: theme.palette.secondary.main,
                                          }}
                                    />
                                    {promiseError && <Typography>Product not serviceable </Typography>}
                                    {(fullfilmentOptions?.promise_groups || []).map((items: any, index: number) => {
                                          let productName = items?.lines.map((name: any) => name.sku_id).join(', ');
                                          let selectedValue =
                                                items?.promise_options.filter((cv: any) => cv.option_Flag && cv.option_id).length === 0
                                                      ? ''
                                                      : items.promise_options.filter((cv: any) => cv.option_Flag && cv.option_id)[0]?.option_id;
                                          const anyFalse = items?.promise_options.some((option: any) =>
                                                option.skus.some((sku: any) => sku.fulfillable_state === false)
                                          );
                                          const defaultOptionId = items?.promise_options[0]?.option_id;
                                          return (
                                                <div
                                                      style={{ paddingLeft: "20px" }}
                                                >
                                                      <div >

                                                            <div

                                                            >

                                                                  {items?.promise_options.length === 0 ? ('No Fulfillment options Available') : <FormControl>
                                                                        <RadioGroup
                                                                              name="controlled-reason"
                                                                              value={selectedDeliveryOption ? selectedDeliveryOption : selectDefaultDeliveryOption(defaultOptionId)}
                                                                              onChange={(e: any) => { handleChangeReason(e, items?.promise_options) }}
                                                                              sx={{ width: '100%' }}

                                                                        >
                                                                              {(items?.promise_options || []).map((data: any) => {
                                                                                    let amt = data?.delivery_cost?.amount / data?.delivery_cost?.fraction

                                                                                    return (

                                                                                          <div>

                                                                                                <FormControlLabel value={data?.option_id} control={<Radio disabled={displayButtons1} />} label={<Box width={"100%"} sx={{ p: 2, display: 'flex', gap: '20px' }}>
                                                                                                      <Box>
                                                                                                            <Box display={"flex"}>
                                                                                                                  <Typography sx={{ fontWeight: "bold" }}>Delivery Method</Typography>
                                                                                                            </Box>
                                                                                                            <Box display={"flex"}>
                                                                                                                  <Typography fontWeight={600}>Delivery Charge</Typography>
                                                                                                            </Box>
                                                                                                            <Box display={"flex"}>
                                                                                                                  <Typography fontWeight={600}>Estimated Delivery Date</Typography>
                                                                                                            </Box>
                                                                                                      </Box>
                                                                                                      <Box>
                                                                                                            <Box display={"flex"}>
                                                                                                                  <Typography sx={{ fontWeight: "bold" }}>{(data?.delivery_method).replaceAll('_', " ")}</Typography>
                                                                                                            </Box>
                                                                                                            <Box display={"flex"}>
                                                                                                                  <Typography>{!data?.delivery_cost ? 'Free' : `$ ${amt}`}</Typography>
                                                                                                            </Box>
                                                                                                            <Box display={"flex"}>
                                                                                                                  <Typography >{new Date(data?.promise_delivery_date).toLocaleString()}</Typography>
                                                                                                            </Box>
                                                                                                      </Box>
                                                                                                </Box>} />
                                                                                          </div>
                                                                                    )
                                                                              })}
                                                                        </RadioGroup>
                                                                  </FormControl>}
                                                            </div>
                                                            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', gap: '15px' }}>
                                                                  {!displayButtons1 && items?.promise_options.length !== 0 && selectedDeliveryOption && <PrimaryButton onClick={handleOnSaveSelectedDeliveryOption}>
                                                                        <Typography fontSize={"14px"}>{loader && <PageLoader />}Select Delivery Option</Typography>
                                                                  </PrimaryButton>}
                                                                  {displayButtons1 && !(commitStatus === 'COMMITTED') && <PrimaryButton onClick={() => {
                                                                        setDisplayButtons1(false)
                                                                  }}>
                                                                        <Typography fontSize={"14px"}>Choose Different  Delivery Option </Typography>
                                                                  </PrimaryButton>}

                                                            </Box>
                                                      </div>
                                                </div>
                                          )
                                    })}

                              </Box>
                        </Grid>

                        <Grid item xs={12}>

                              <Box>
                                    {fullfilmentOptions && (fullfilmentOptions?.return_promise_groups || []).map((items: any, index: number) => {
                                          let productName = items?.lines.map((name: any) => name.sku_id).join(', ');
                                          let selectedValue =
                                                items?.promise_options.filter((cv: any) => cv.option_Flag && cv.option_id).length === 0
                                                      ? ''
                                                      : items.promise_options.filter((cv: any) => cv.option_Flag && cv.option_id)[0]?.option_id;

                                          const defaultOptionId = items?.promise_options[0]?.option_id;
                                          return (
                                                <Card sx={{ mt: 2 }}
                                                >
                                                      <div >
                                                            <Typography fontWeight={600} flex={5} mr={1} margin="10px" paddingLeft="10px" paddingTop="16px">
                                                                  Choose Return Option
                                                            </Typography>

                                                            <Divider
                                                                  sx={{


                                                                        borderColor: theme.palette.secondary.main,
                                                                  }}
                                                            />
                                                            <div
                                                                  style={{ paddingLeft: "20px" }}

                                                            >

                                                                  {items?.promise_options.length === 0 ? ('No Fulfillment options Available') : <FormControl>
                                                                        <RadioGroup
                                                                              name="controlled-reason"
                                                                              value={selectedReturnOption ? selectedReturnOption : selectDefaultReturnOption(defaultOptionId)}
                                                                              onChange={(e: any) => { handleChangeReason(e, items?.promise_options) }}
                                                                              sx={{ width: '100%' }}

                                                                        >
                                                                              {(items?.promise_options || []).map((data: any) => {
                                                                                    let amt = data?.delivery_cost?.amount / data?.delivery_cost?.fraction

                                                                                    return (

                                                                                          <div>

                                                                                                <FormControlLabel value={data?.option_id} control={<Radio disabled={displayButtons2} />} label={<Box width={"100%"} sx={{ p: 2, display: 'flex', gap: '20px' }}>
                                                                                                      <Box>
                                                                                                            <Box display={"flex"}>
                                                                                                                  <Typography sx={{ fontWeight: "bold" }}>Return Method</Typography>
                                                                                                            </Box>
                                                                                                            <Box display={"flex"}>
                                                                                                                  <Typography fontWeight={600}>Return Charge</Typography>
                                                                                                            </Box>
                                                                                                            <Box display={"flex"}>
                                                                                                                  <Typography fontWeight={600}>Estimated Return Date</Typography>
                                                                                                            </Box>
                                                                                                      </Box>
                                                                                                      <Box>
                                                                                                            <Box display={"flex"}>
                                                                                                                  <Typography sx={{ fontWeight: "bold" }}>{getFulFillmentOptionMethodText(data?.return_method)}</Typography>
                                                                                                            </Box>
                                                                                                            <Box display={"flex"}>
                                                                                                                  <Typography>{!data?.delivery_cost ? 'Free' : `$ ${amt}`}</Typography>
                                                                                                            </Box>
                                                                                                            <Box display={"flex"}>
                                                                                                                  <Typography >{new Date(data?.promise_return_date).toLocaleString()}</Typography>
                                                                                                            </Box>
                                                                                                      </Box>
                                                                                                </Box>} />
                                                                                          </div>
                                                                                    )
                                                                              })}
                                                                        </RadioGroup>
                                                                  </FormControl>}
                                                            </div>
                                                            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', gap: '20px' }}>
                                                                  {!displayButtons2 && items?.promise_options.length !== 0 && selectedReturnOption && <PrimaryButton onClick={handleOnSaveSelectedReturnOption}>
                                                                        <Typography fontSize={"14px"}>{loader && <PageLoader />}Select Return Option</Typography>
                                                                  </PrimaryButton>}
                                                                  {displayButtons2 && !(commitStatus === 'COMMITTED') && <PrimaryButton onClick={() => {
                                                                        setDisplayButtons2(false)
                                                                  }}>
                                                                        <Typography fontSize={"14px"}>Choose Different  Return  Option </Typography>
                                                                  </PrimaryButton>}

                                                            </Box>
                                                      </div>
                                                </Card>
                                          )
                                    })}

                              </Box>
                        </Grid>

                        {displayButtons1 && displayButtons2 && <Box sx={{ width: "100%", paddingLeft: "15px", paddingTop: "20px", marginLeft: "15px" }}>
                              {commitStatus === 'OPEN' && <PrimaryButton onClick={() => { handleOnCommit('COMMITTED') }} >
                                    <Typography fontSize={"14px"}>{loader && <PageLoader />}COMMIT</Typography>
                              </PrimaryButton>}
                              {commitStatus === 'COMMITTED' && <PrimaryButton onClick={() => { handleOnCommit('OPEN') }}>
                                    <Typography fontSize={"14px"}>{loader && <PageLoader />}UNCOMMIT</Typography>
                              </PrimaryButton>}
                        </Box>}


                        {commitStatus === "COMMITTED" && Math.abs(leftOutAmount) > 0 && <Grid item xs={12} sm={12}>
                              <Card sx={{ p: 2, mt: 2 }}>
                                    <Box sx={{ width: "100%", }}>

                                          <Form ref={paymentFormRef as RefObject<Form>} hasError={hasError} model={PaymentForm({ leftOutAmount: Math.abs(leftOutAmount), InputProps: { readOnly: true }, disabled: !(commitStatus === 'COMMITTED') ? "none" : "" })} values={{ ...formData, amount: Number(leftOutAmount || 0).toFixed(2) }} />
                                    </Box>
                              </Card>
                        </Grid>}

                        <Grid item xs={12}>
                              <Box
                                    sx={{
                                          [theme.breakpoints.up("md")]: {
                                                display: "flex",
                                          },
                                    }}
                                    pt={2}
                                    pb={2}
                              >
                                    {commitStatus === 'COMMITTED' && <PrimaryButton onClick={(e: any) => handlePayment(e)} disabled={true || !(commitStatus === 'COMMITTED')}>
                                          <Box display={"flex"} alignItems={"center"} gap={1}>
                                                <Typography fontSize={"14px"}>{leftOutAmount > 0 ? "Pay & " : ""} Checkout</Typography>
                                                <KeyboardDoubleArrowLeftIcon fontSize="small" />
                                          </Box>
                                    </PrimaryButton>}
                              </Box>
                        </Grid>

                  </Grid >

            </>
      );
};

export default Payment;
