import { Validators } from "../../utils/validators";
import { FormModel } from "../../components/Form";
// CASH,
// CREDIT_CARD,
// DEBIT_CARD,
// BANK_TRANSFER,
// CASH_ON_DELIVERY,
// UPI,
// SAMSUNG_PAY,
// MOBILE_WALLET,
// GIFT_CARD,
// LOYALTY_CARD
export const PaymentForm = ({
	leftOutAmount,
	InputProps,
	disabled,
}: {
	leftOutAmount: number;
	InputProps: any;
	disabled: any;
}): FormModel[] => {
	return [
		{
			label: "Payment Method",
			value: "",
			typeValue: "text",
			type: "drop-down",
			placeholder: "",
			options: [
				{ label: "Credit Card", value: "CREDIT_CARD" },
				{ label: "Debit Card", value: "DEBIT_CARD" },
			],
			field: "payment_method",
			variant: "outlined",
			validators: [{ check: Validators.required, message: "This field is required" }],
			required: true,
			responsive: { xs: 12 },
			disabled: disabled,
			size: "small",
		},
		{
			label: `Currency`,
			value: "",
			typeValue: "text",
			type: "drop-down",
			options: [{ label: "CLP", value: "CLP" }],
			placeholder: "",
			field: "currency",
			variant: "outlined",
			validators: [{ check: Validators.required, message: "This field is required" }],
			required: true,
			responsive: { xs: 12 },
			size: "small",
			disabled: disabled,
		},
		{
			label: `Amount`,
			value: leftOutAmount,
			typeValue: "text",
			type: "text",
			placeholder: "",
			field: "amount",
			variant: "outlined",
			disabled: true,

			validators: [
				{ check: Validators.required, message: "This field is required" },
				{ check: Validators.amount, message: "Please enter a valid amount" },
				{ check: Validators.max, message: `Amount cannot exceed ${leftOutAmount}`, num: leftOutAmount },
			],
			required: true,
			responsive: { xs: 12 },
			size: "small",
		},
		{
			label: `Reference ID`,
			value: "",
			typeValue: "text",
			type: "text",
			placeholder: "",
			field: "reference_id",
			variant: "outlined",
			validators: [{ check: Validators.required, message: "This field is required" }],
			required: true,
			responsive: { xs: 12 },
			size: "small",
			disabled: disabled,
		},
	];
};
