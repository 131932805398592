import { Children, FC, useState } from 'react';
import { validateInput } from '../../utils/validators/Validators';
import { FormControl, FormHelperText, Typography, useTheme } from '@mui/material';
import { FormValidators } from '../Form';
import Select, { components, StylesConfig } from 'react-select';
import './styles.scss';
import Paragraph from '../Typography/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import strings from 'common/Translation/Translate';

interface OwnProps {
  field?: string;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  typeValue?: string;
  onChange: (value: string, field: string, error?: { error: boolean; message: string }) => void;
  validators?: FormValidators[];
  hasError?: boolean;
  fieldError?: string | boolean;
  isSearchable?: boolean;
  label?: string;
  disableFuture?: boolean;
  labelId?: string;
  inputValue: any;
  options: any[];
  inputProps?: any;
  className?: string;
  style?: any;
  isMulti?: boolean;
  isClearable?: boolean;
  id: any;
}
const MultiSelect: FC<OwnProps> = ({
  hasError,
  validators,
  onChange,
  field,
  inputValue,
  options,
  fieldError,
  disabled,
  className,
  label,
  id,
  style,
  placeholder,
  isClearable = true,
  isSearchable = true,
  isMulti,
}) => {
  const theme = useTheme();
  const [error, setError] = useState<{
    error: boolean;
    message: string;
  } | null>(null);
  const handleChange = (selectedOption: any) => {
    const inputError = validateInput(validators as FormValidators[], selectedOption);
    setError(inputError);
    onChange(selectedOption, field as string, inputError);
  };
  const arrowDownIcon = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownIcon />
      </components.DropdownIndicator>
    );
  };
  const colourStyles: StylesConfig<any> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white'}),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected || isDisabled || isFocused
          ? theme.palette.primary.main : 'white',
        color: isSelected || isDisabled || isFocused
        ? 'white' : 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: isSelected || isDisabled
              ? theme.palette.primary.main
            : 'white',
        },
        ':hover': {
          ...styles[':hover'],
          backgroundColor: theme.palette.action.hover
        },
      };
    },
  };

  type ValueContainerProps = {
    children: any;
    getValue: any;
    
  }

  const ValueContainer = ({ children, getValue, ...rest }: ValueContainerProps) => {
    var length = getValue().length;
    let childrens = Children.toArray(children);
    let items = childrens.slice(0, 2);
    return (
      <components.ValueContainer {...rest as any}>
        {!(rest as any).selectProps.inputValue && items}
        {length > 2 && ` + ${length-1} ${strings.multiSelectText}`}
      </components.ValueContainer>
    );
  };

  const customMessage = () => (!error && hasError && fieldError ? strings.requiredField

 : '');
  return (
    <>
      {label ? <Paragraph sx={{ fontSize: '0.75rem', marginBottom: '6px'}}>{label}</Paragraph> : null}
      {/* <ToolTip title={`${inputValue.length} selected`}> */}
      <FormControl
        sx={{
          '.select': {
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
          },
          "span[class$='indicatorSeparator']": {
            display: 'none',
          },
          '&.mainError': {
            "div[class$='control']": {
              borderColor: '#d32f2f',
            },
            "div[class$='placeholder']": {
              color: '#d32f2f',
            },
          },      
          "div[class$='control']": {
            color: !disabled ? theme.palette.primary.main : 'gray',
            borderColor: !disabled ? theme.palette.secondary.main : 'lightgray',
            minHeight: '1.4375em',
            backgroundColor: !disabled ? 'white' : '#d8d8d8',
            borderRadius: '0%',
            height: '39px',
          },
          "div[class$='multiValue']": {
            backgroundColor: !disabled ? theme.palette.secondary.dark : 'white',
            paddingRight: !disabled ? 0 : 1,
          },
          "div[class$='MultiValueGeneric']": {
            fontSize: '12px !important',
          },
          "div[class$='MultiValueRemove']": {
            display: !disabled ? 'flex' : 'none',
          },
          '.MuiSvgIcon-root': {
            color: theme.palette.primary.main,
            visibility: !disabled ? 'visible' : 'hidden',
          },
          
          ...style,
        }}
        error={!!((error && error.error) || (!error && hasError && fieldError))}
        className={`dropdown ${className} ${
          (error && error.error) || (!error && hasError && fieldError) ? 'mainError' : ''
        }`}
        fullWidth>
        <Select
          className="select"
          options={options}
          styles={colourStyles}
          theme={(tm: any) => ({
            ...tm,
            borderRadius: 0,
            colors: {
              ...tm.colors,
              primary25: theme.palette.primary.main,
            },
          })}
          isMulti={isMulti}
          components={{ ValueContainer, DropdownIndicator: arrowDownIcon }}
          isDisabled={disabled}
          isSearchable={isSearchable}
          id={id}
          hideSelectedOptions={false}
          isClearable={false}
          value={inputValue}
          placeholder={''}
          closeMenuOnSelect={true}
          onChange={value => {
            handleChange(value);
          }}
          
          classNamePrefix="react-select"
        />
        <FormHelperText>{error && error.error ? error.message : customMessage()}</FormHelperText>
      </FormControl>
      {/* </ToolTip> */}
    </>
  );
};
export default MultiSelect;
