import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { Card, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, TextareaAutosize, Typography, useMediaQuery } from "@mui/material";
import { Box, display, useTheme } from "@mui/system";
import strings from "common/Translation/Translate";
import PrimaryButton from "components/Button/PrimaryButton";
import DrawerMenu from "components/DrawerMenu";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { URLS } from "utils/constants/urls";
import { Authenticated } from "utils/redux";
import axiosInstance from "utils/redux/axios";
import { getDrawerWidth } from "utils/validators/HelperFunctions";
import Replacement from './Replacement';
import PageLoader from "components/PageLoader";
import { userAddress } from "utils/redux/reducer/address-slice";
import { User } from "utils/redux/reducer/authentication-slice";
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';
import { setNotificationMessage } from "utils/redux/reducer/Notification-details-slice";
const ReasonToReturn = [
      {
            id: 1,
            reason: 'Wrong Item Recived',
            possibility: ['Refund']
      },
      {
            id: 2,
            reason: `Item does'nt match as described`,
            possibility: ['Refund']
      },
      {
            id: 3,
            reason: `Defective Or Damage Item`,
            possibility: ['Refund', 'Replacement']
      },
      {
            id: 4,
            reason: `Size/Fit Issue`,
            possibility: ['Refund', 'Replacement']
      },
      {
            id: 5,
            reason: `Changed Mind/Not Needed`,
            possibility: ['Refund']
      },
      {
            id: 6,
            reason: `Unsatisfactory Quality`,
            possibility: ['Refund', 'Replacement']
      },
      {
            id: 7,
            reason: `Late Delivery`,
            possibility: ['Refund']
      },
      {
            id: 8,
            reason: `Duplicate Order`,
            possibility: ['Refund']
      },
      {
            id: 9,
            reason: `Recived Wrong Quantity`,
            possibility: ['Refund']
      },
      {
            id: 10,
            reason: `Gift Return`,
            possibility: ['Refund']
      },
      {
            id: 11,
            reason: `Price discrepancy`,
            possibility: ['Refund']
      },
      {
            id: 12,
            reason: `Ordered the wrong item`,
            possibility: ['Refund']
      },
      {
            id: 13,
            reason: `Product doe'nt work as expected`,
            possibility: ['Refund']
      },
      {
            id: 14,
            reason: `Change in Circumstance`,
            possibility: ['Refund']
      },
      {
            id: 15,
            reason: `Product Expired`,
            possibility: ['Refund']
      },
      {
            id: 16,
            reason: `Missing Accessories`,
            possibility: ['Refund', 'Replacement']
      },
      {
            id: 17,
            reason: `Recived wrong color`,
            possibility: ['Refund']
      },
      {
            id: 18,
            reason: `Not as Advertised`,
            possibility: ['Refund']
      },
      {
            id: 19,
            reason: `Not Satisfactory`,
            possibility: ['Refund']
      },
      {
            id: 20,
            reason: `Others`,
            possibility: ['Refund']
      }
]

const Refund = ({ orderNumber, returnProducts, returnOrderAmount, promiseResp, selectedReturnReason, additionalComment }: { orderNumber: any, returnProducts: any, returnOrderAmount: any, promiseResp: any, selectedReturnReason: any, additionalComment: any }) => {
      const theme = useTheme();
      const isLogin = useSelector(Authenticated);
      const dispatch = useDispatch();
      const navigate = useNavigate();
      const screenSizeDownXl = useMediaQuery(theme.breakpoints.down("xl"));
      const screenSizeDownLg = useMediaQuery(theme.breakpoints.down("lg"));
      const screenSizeDownMd = useMediaQuery(theme.breakpoints.down("md"));
      const screenSizeDownSm = useMediaQuery(theme.breakpoints.down("sm"));
      const screenSizeUpLg = useMediaQuery(theme.breakpoints.up("lg"));
      const screenSizeUpMd = useMediaQuery(theme.breakpoints.up("md"));
      const screenSizeUpSm = useMediaQuery(theme.breakpoints.up("sm"));
      const [selectedReturnMethod, setSelectedReturnMethod] = React.useState('');
      const [choosedfulfillmentOption, setChoosedfulfillmentOption] = React.useState({});
      const [userNeeds, setUserNeeds] = React.useState('');
      const [showStatus, setShowStatus] = React.useState([] as any);
      const [selectdRefund, setSelectedRefund] = React.useState(0);
      const [fulfillmentOption, setFulfilmentOptions] = React.useState('collect_from_pickup')
      const [pickUpModal, setPickUpModal] = React.useState(false)
      const [collectModal, setCollectModal] = React.useState(false)
      const [otherReason, setOtherReason] = React.useState('');
      const drawerRef = useRef(null);
      const [returnValue, setReturnValue] = React.useState('drop');
      const [returnOrder, setReturnOrder] = React.useState(false)
      const [loader, setLoader] = React.useState<boolean>(false);
      const userAdd = useSelector(userAddress)
      const userData = useSelector(User);
      const [hashAvail, setHashAvail] = React.useState('')
      const [fulfillmentOptionResp, setFulfillmentOptionsResp] = React.useState<any>({})
      const [displayButtons, setDisplayButtons] = React.useState(false as boolean)
      const [commitStatus, setCommitStatus] = React.useState('OPEN' as string)
      const [promisePayLoadData, setPromisePayLoadData] = React.useState(null as any)
      const [address, setAddress] = React.useState([] as any);

      useEffect(() => {
            (async () => {
                  if (promiseResp)
                        await getFeasiableFullFillmentOption(promiseResp, userAdd[0]?.addressType == "SHIPPING" ? userAdd[0]?.pinCode : userAdd[1]?.pinCode, returnProducts)
            })();
      }, [promiseResp, userAdd])


      const getFulFillmentOptionMethodText = (method: string) => {
            if (method === 'COLLECTION_POINT')
                  return "Drop-off Hub"
            if (method === 'LOCAL_PICKUP')
                  return 'At-Home Pickup Service'
            return ''
      }

      const getProducts = (itemList: any[]) => {


            let productsPayload = {
                  'products': returnProducts.map((item: any) => (
                        {
                              // "category_id": "11420",
                              "country_of_origin": "CHL",
                              "gtin": "string",
                              "is_courierable": true,
                              "is_dangerous": true,
                              "is_gift": true,
                              "is_returnable": true,
                              "preference": {
                                    "bag_required": true,
                                    "picker_notes": "string"
                              },
                              "co_line_id": uuidv4(),//random
                              // "product_class": "Pet food",
                              // "product_id": "11420",
                              // "product_name": "Belcando water buffalo 400 GR",
                              // "product_type": "Pet food",
                              "qty_same_as_parent": true,
                              "quantity": item.quantity,
                              "requires_shipping": true,
                              "sku_id": item.variantCode
                        }
                  ))
            }


            return productsPayload;
      }

      const getFeasiableFullFillmentOption = async (promiseResp: any, pincode: any, products: any[]) => {
            let shipToPostCode = pincode
            let countryCode = "CL"
            let productString = JSON.stringify(products)
            let convertHeaderData = shipToPostCode + countryCode + productString
            const hash = CryptoJS.MD5(convertHeaderData).toString();
            const bodyData = getProducts(products);
            try {
                  const config = {
                        headers: {
                              'revision': hash,
                        },
                  };
                  const { status, data } = await axiosInstance.post(URLS.fullFilmentOptions(promiseResp?.promise_id), bodyData, config as any)
                  if (status === 200) {
                        setChoosedfulfillmentOption({})
                        setFulfillmentOptionsResp(data)
                  }
            } catch (err: any) {

            }
      }


      const handleOnselection = async (pincode: any, bodyData: any, products: any) => {
            let shipToPostCode = pincode
            let countryCode = "CL"
            let productString = JSON.stringify(products)
            let convertHeaderData = shipToPostCode + countryCode + productString
            const hash = CryptoJS.MD5(convertHeaderData).toString();
            setLoader(true);
            try {
                  const config = {
                        headers: {
                              'revision': hash,
                        },
                  };
                  const { status, data } = await axiosInstance.post(URLS.selection(promiseResp.promise_id), bodyData, config as any)
                  if (status === 200) {
                        // handleError('Selection Success !', 'success')

                  }
            } catch (err: any) {
                  setLoader(false);

            }
            setLoader(false);
      }



      const selectFulfilmentOption = (defaultOptionId: any) => {
            setSelectedReturnMethod(defaultOptionId)
            return defaultOptionId
      }
      const handleOnSave = () => {
            // let selectedOptions = [] as any;
            // fulfillmentOptionResp?.return_promise_groups?.forEach((group: any) => {
            //       const { promise_group_id, promise_options } = group;
            //       promise_options.forEach((option: any) => {
            //             if (selectedReturnMethod === option.option_id)
            //                   selectedOptions.push({
            //                         group_id: promise_group_id,
            //                         selected_option_id: option.option_id
            //                   })
            //       });
            // });


            // const bodyData = {
            //       return_promise_groups: selectedOptions
            // };

            if (!(Object.keys(choosedfulfillmentOption).length === fulfillmentOptionResp.return_promise_groups.length)) {
                  dispatch(setNotificationMessage({
                        display: true,
                        severity: "error",
                        message: "Choose all fulfillment Options",
                  }));
                  return
            }
            const arr = Object.entries(choosedfulfillmentOption)

            const bodyData = {
                  return_promise_groups: arr.map((subarr: any) => ({
                        group_id: subarr[0],
                        selected_option_id: subarr[1]
                  }))
            };
            setDisplayButtons(true)
            handleOnselection(userAdd[0]?.addressType == "SHIPPING" ? userAdd[0]?.pinCode : userAdd[1]?.pinCode, bodyData, returnProducts)
      }
      const handleOnCommit = (statusData: string) => {
            const bodyData = {
                  status: statusData
            }
            handleOnStatusUpdate(bodyData)
            // fetchAddress(dataItemList?.pinCode, dataItemList?.items, addressData)
      }
      const handleOnStatusUpdate = async (bodyData: any) => {
            setLoader(true);
            try {

                  const { status, data } = await axiosInstance.post(URLS.state(promiseResp?.promise_id), bodyData)
                  if (status === 200) {
                        setCommitStatus(bodyData.status)
                        if (bodyData.status === 'OPEN') {
                              setSelectedReturnMethod('')
                              // fetchAddress(dataItemList?.pinCode, dataItemList?.items)
                              setDisplayButtons(false)
                              await getFeasiableFullFillmentOption(promiseResp, userAdd[0]?.addressType == "SHIPPING" ? userAdd[0]?.pinCode : userAdd[1]?.pinCode, returnProducts)
                              setSelectedReturnMethod('')

                        }
                  }
                  else {


                  }
            } catch (err: any) {

            }
            setLoader(false);
      }

      const handleChangeReason = (event: any, dataNum: any) => {
            setSelectedReturnMethod(event.target.value);
            setSelectedRefund(0)

      }
      const handleCheckout = async () => {

            const body = {
                  "additionalPayment": [],
                  "exchangeDetails": null,
                  "isExchange": false,
                  "parentOrderNumber": orderNumber,
                  "posNo": "1",
                  "promiseId": promiseResp.promise_id,
                  "promiseVersion": "string",
                  "refundDetails": {
                        "refundAmount": (Math.abs(returnOrderAmount)).toFixed(2),
                        "refundMethod": selectdRefund
                  },
                  "returnDetails": {
                        "returnComment": additionalComment,

                        'returnItems': returnProducts.map((item: any) => ({
                              "comment": additionalComment,
                              "orderLineId": uuidv4(),
                              "productCode": item.productCode,
                              "quantity": item.quantity,
                              "reason": selectedReturnReason === 20 ? otherReason : ReasonToReturn[selectedReturnReason - 1].reason,
                              "refundAdjustedAmount": 0,
                              "variantCode": item.variantCode,
                        })),
                        "returnReason": selectedReturnReason === 20 ? otherReason : ReasonToReturn[selectedReturnReason - 1].reason,
                  },
                  "startTransactionDateTime": new Date().toISOString(),
                  "status": "string",
                  "storeNo": "ejje-dotcom-001",
                  "transactionId": uuidv4(),
                  cartId: orderNumber
            }

            try {

                  const { status, data } = await axiosInstance.post(URLS.returnOrder, body)

            }
            catch (err: any) {
                  console.log(err)
            }
      }

      const handleOnRefundType = (event: any) => {
            setSelectedRefund(event?.target?.value)
      }

      const handleFulfillmentOptionsSelection = async (groupId: any, e: any) => {
            setChoosedfulfillmentOption({ ...choosedfulfillmentOption, [groupId]: e.target.value })

      }
      return (
            <Box>
                  {fulfillmentOptionResp && (fulfillmentOptionResp?.return_promise_groups || []).map((items: any, index: number) => {
                        let productName = items?.lines.map((name: any) => name.sku_id).join(', ');
                        let selectedValue =
                              items?.promise_options.filter((cv: any) => cv.option_Flag && cv.option_id).length === 0
                                    ? ''
                                    : items.promise_options.filter((cv: any) => cv.option_Flag && cv.option_id)[0]?.option_id;

                        const defaultOptionId = items?.promise_options[0]?.option_id;
                        return (
                              <Card sx={{ mt: 2 }}
                              >
                                    <div >
                                          <Typography fontWeight={600} flex={5} mr={1} margin="10px" paddingLeft="10px" paddingTop="16px">
                                                Choose Return Option
                                          </Typography>

                                          <Divider
                                                sx={{


                                                      borderColor: theme.palette.secondary.main,
                                                }}
                                          />
                                          <div
                                                style={{ paddingLeft: "20px" }}

                                          >

                                                {items?.promise_options.length === 0 ? ('No Fulfillment options Available') : <FormControl>
                                                      <RadioGroup
                                                            name="controlled-reason"
                                                            // value={selectedReturnMethod ? selectedReturnMethod : selectFulfilmentOption(defaultOptionId)}
                                                            onChange={(e: any) => { handleFulfillmentOptionsSelection(items.promise_group_id, e) }}
                                                            sx={{ width: '100%' }}

                                                      >
                                                            {(items?.promise_options || []).map((data: any) => {
                                                                  let amt = data?.delivery_cost?.amount / data?.delivery_cost?.fraction

                                                                  return (

                                                                        <div>

                                                                              <FormControlLabel value={data?.option_id} control={<Radio disabled={displayButtons} />} label={<Box width={"100%"} sx={{ p: 2, display: 'flex', gap: '20px' }}>
                                                                                    <Box>
                                                                                          <Box display={"flex"}>
                                                                                                <Typography sx={{ fontWeight: "bold" }}>Return Method</Typography>
                                                                                          </Box>
                                                                                          <Box display={"flex"}>
                                                                                                <Typography fontWeight={600}>Return Charge</Typography>
                                                                                          </Box>
                                                                                          <Box display={"flex"}>
                                                                                                <Typography fontWeight={600}>Estimated Return Date</Typography>
                                                                                          </Box>
                                                                                    </Box>
                                                                                    <Box>
                                                                                          <Box display={"flex"}>
                                                                                                <Typography sx={{ fontWeight: "bold" }}>{getFulFillmentOptionMethodText(data?.return_method)}</Typography>
                                                                                          </Box>
                                                                                          <Box display={"flex"}>
                                                                                                <Typography>{!data?.delivery_cost ? 'Free' : `$ ${amt}`}</Typography>
                                                                                          </Box>
                                                                                          <Box display={"flex"}>
                                                                                                <Typography >{new Date(data?.promise_return_date).toLocaleString()}</Typography>
                                                                                          </Box>
                                                                                    </Box>
                                                                              </Box>} />
                                                                        </div>
                                                                  )
                                                            })}
                                                      </RadioGroup>
                                                </FormControl>}
                                          </div>


                                    </div>
                              </Card>
                        )
                  })}

                  <div>
                        <Box sx={{ display: 'flex', flexDirection: 'row-reverse', gap: '15px', padding: "15px" }}>
                              {!displayButtons && <PrimaryButton onClick={handleOnSave}>
                                    <Typography fontSize={"14px"}>{loader && <PageLoader />}Select Return Option</Typography>
                              </PrimaryButton>}
                              {displayButtons && commitStatus === 'OPEN' && <PrimaryButton onClick={() => {
                                    setDisplayButtons(false)
                              }}>
                                    <Typography fontSize={"14px"}>Choose Different  Return  Option </Typography>
                              </PrimaryButton>}
                              {displayButtons && <Box sx={{ display: 'flex', gap: '10px' }}>
                                    {commitStatus === 'OPEN' && <PrimaryButton onClick={() => { handleOnCommit('COMMITTED') }}>
                                          <Typography fontSize={"14px"}>{loader && <PageLoader />}Proceed To Refund Option</Typography>
                                    </PrimaryButton>}
                                    {commitStatus === 'COMMITTED' && <PrimaryButton onClick={() => { handleOnCommit('OPEN') }}>
                                          <Typography fontSize={"14px"}>{loader && <PageLoader />}Change Return Option</Typography>
                                    </PrimaryButton>}
                              </Box>}
                        </Box>
                  </div>
                  {commitStatus === "COMMITTED" && <Card sx={{ mt: 2 }}>
                        <Typography sx={{ fontSize: '14px', fontWeight: 600 }} margin="10px" paddingLeft="10px" paddingTop="16px"> Choose Refund Method</Typography>
                        <Divider
                              sx={{
                                    borderColor: theme.palette.secondary.main,
                              }}
                        />
                        <Typography paddingLeft="20px" paddingTop="20px">{`Refund Amount : ${(Math.abs(returnOrderAmount)).toFixed(2)}`}</Typography>
                        <FormControl sx={{ p: 2 }}>
                              <RadioGroup
                                    name="controlled-refund"
                                    value={selectdRefund}
                                    onChange={handleOnRefundType}
                              >
                                    <FormControlLabel value="ORIGINAL_PAYMENT_METHOD" control={<Radio />} label="Refund To Original Payment Method" />
                                    <FormControlLabel value="LOYALTY_CREDIT" control={<Radio />} label="Refund To Loyalty Wallet" />
                              </RadioGroup>
                        </FormControl>
                        {selectdRefund ? <PrimaryButton onClick={handleCheckout}>Checkout</PrimaryButton> : null}
                  </Card>}
            </Box>
      )
}

export default Refund;
