import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


type TabsectionProps = {
    productDetails: any
}

export const Tabsection = ({ productDetails }: TabsectionProps) => {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="DESCRIPTION" {...a11yProps(0)} />
                    {productDetails?.terms && <Tab label="TERMS & CONDITIONS" {...a11yProps(1)} />}
                    {productDetails?.howToUse && <Tab label="HOW TO USE" {...a11yProps(2)} />}
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <div dangerouslySetInnerHTML={{ __html: productDetails.description }}></div>
            </CustomTabPanel>
            {productDetails?.terms &&
                <CustomTabPanel value={value} index={1}>
                    <div dangerouslySetInnerHTML={{ __html: productDetails.terms }}></div>
                </CustomTabPanel>
            }
            {productDetails?.howToUse &&
                <CustomTabPanel value={value} index={2}>
                    <div dangerouslySetInnerHTML={{ __html: productDetails.howToUse }}></div>
                </CustomTabPanel>
            }
        </Box >
    );
}