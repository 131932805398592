import React from 'react'
import { FC, MouseEvent, RefObject, useRef, useState } from "react";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { Button, Card, Divider, FormControl, FormControlLabel, Grid, Modal, Radio, RadioGroup, Switch, Typography, useMediaQuery } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import strings from "common/Translation/Translate";
import PrimaryButton from "components/Button/PrimaryButton";
import DrawerMenu from "components/DrawerMenu";
import PageLoader from "components/PageLoader";
import { ToolTip } from "components/Tooltip/ToolTip";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { URLS } from "utils/constants/urls";
import { Authenticated, cartDrawerState, cartItems, totalAmount, userAddress as userInfo } from "utils/redux";
import axiosInstance from "utils/redux/axios";
import { setNotificationMessage } from "utils/redux/reducer/Notification-details-slice";
import { setOpenAuthenticationDrawer, User } from "utils/redux/reducer/authentication-slice";
import { getDrawerWidth } from "utils/validators/HelperFunctions";
import Form, { FormDataModel } from 'components/Form';
import { removeErrorFieldsFromValues } from "../../utils/validators";
import { v4 as uuidv4 } from 'uuid';
import { changeQuantity, toggleDrawer } from 'utils/redux/reducer/cart-slice';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { removeCartItem } from "utils/redux/reducer/cart-slice";
import CryptoJS from 'crypto-js';

const ProductCard = ({ item }: { item: any }) => {
      const dispatch = useDispatch();
      const maxWidth340px = useMediaQuery('(max-width:340px)');
      const [quantity, setQuantity] = React.useState(0);


      const handleQuantityChange = (sign: string,) => {
            if (sign == "+" && quantity + 1 > item.maxQuantity) {
                  return;
            }
            if (sign == "-" && quantity > 1) {
                  setQuantity(quantity - 1);
            } else if (sign == "+" && quantity < item.maxQuantity) {
                  setQuantity(quantity + 1);
            }

      };

      React.useEffect(() => {
            dispatch(changeQuantity({
                  variantCode: item.variantCode,
                  quantity: quantity || item.quantity
            }))
      }, [quantity]);

      React.useEffect(() => {
            if (item)
                  setQuantity(item.quantity)
      }, [item])

      return (
            <>
                  <Box
                        style={{
                              display: "grid",
                              gridTemplateColumns: "150px auto",
                              gridTemplateRows: "50px auto",
                              justifyContent: "stretch",
                              minHeight: "150px",
                              margin: "10px",
                              columnGap: '10px',
                              padding: "5px",
                        }}
                  >
                        <DeleteOutlineOutlinedIcon
                              fontSize="large"
                              sx={{
                                    gridColumn: "1/-1",
                                    justifySelf: "flex-end",
                                    cursor: "pointer",
                              }}
                              onClick={() => {
                                    dispatch(removeCartItem({ variantCode: item.variantCode }));
                              }}
                        />
                        <Box
                              sx={{
                                    textAlign: "center",
                                    maxHeight: "130px",
                                    minHeight: "130px"
                              }}
                        >

                              <img
                                    src={item?.imageUrl}
                                    style={{
                                          maxWidth: maxWidth340px ? "60px" : "100px",
                                          objectFit: "contain",
                                          maxHeight: "150px",
                                    }}
                              />

                        </Box>
                        <Box>
                              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
                                    <span>{item.productName}</span>
                                    <span>{item.variantCode}</span>
                              </Box>
                              <Box display="flex" justifyContent="space-between">
                                    <span

                                    >
                                          {item.price} &times; {item.quantity}
                                    </span>
                                    <span style={{
                                          fontWeight: "bold",
                                          fontSize: "18px",
                                    }}>
                                          {item.totalPrice}
                                    </span>
                              </Box>

                              <Box
                                    sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          marginTop: "10px",
                                          width: maxWidth340px ? "50px" : "100px",
                                    }}
                              >
                                    <RemoveCircleIcon
                                          sx={{
                                                cursor: "pointer",
                                          }}
                                          fontSize="large"
                                          onClick={() => handleQuantityChange("-")}
                                    />

                                    {item.quantity}
                                    <AddCircleIcon
                                          sx={{
                                                cursor: "pointer",
                                          }}
                                          fontSize="large"
                                          onClick={() => {
                                                handleQuantityChange("+");
                                          }}
                                    />
                              </Box>
                        </Box>
                  </Box>
                  <Divider />

            </>
      )
}

export default ProductCard;
