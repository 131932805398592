import React from 'react'
import { FC, MouseEvent, RefObject, useRef, useState } from "react";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { Button, Card, Divider, FormControl, FormControlLabel, Grid, Modal, Radio, RadioGroup, Switch, Typography, useMediaQuery } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import strings from "common/Translation/Translate";
import PrimaryButton from "components/Button/PrimaryButton";
import DrawerMenu from "components/DrawerMenu";
import PageLoader from "components/PageLoader";
import { ToolTip } from "components/Tooltip/ToolTip";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { URLS } from "utils/constants/urls";
import { Authenticated, cartDrawerState, cartItems, totalAmount, userAddress as userInfo } from "utils/redux";
import axiosInstance from "utils/redux/axios";
import { setNotificationMessage } from "utils/redux/reducer/Notification-details-slice";
import { setOpenAuthenticationDrawer, User } from "utils/redux/reducer/authentication-slice";
import { getDrawerWidth } from "utils/validators/HelperFunctions";
import Form, { FormDataModel } from 'components/Form';
import { removeErrorFieldsFromValues } from "../../utils/validators";
import { v4 as uuidv4 } from 'uuid';
import { paymentDrawerState, toggleDrawer } from 'utils/redux/reducer/cart-slice';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { removeCartItem } from "utils/redux/reducer/cart-slice";
import CryptoJS from 'crypto-js';
import ProductCard from './ProductCard';
import { setPromiseResponse as storePromiseResponse, setPromisePayloadData, setOrderSummaryResponse, setRevisionId } from 'utils/redux/reducer/create-order-slice';
import { togglePaymentDrawer } from 'utils/redux/reducer/cart-slice';

const Cart = ({
}: {
      }) => {

      const theme = useTheme();
      const isLogin = useSelector(Authenticated);
      const dispatch = useDispatch();
      const navigate = useNavigate();
      const cartProducts = useSelector(cartItems);
      const maxWidth340px = useMediaQuery('(max-width:340px)');
      const screenSizeDownXl = useMediaQuery(theme.breakpoints.down("xl"));
      const screenSizeDownLg = useMediaQuery(theme.breakpoints.down("lg"));
      const screenSizeDownMd = useMediaQuery(theme.breakpoints.down("md"));
      const screenSizeDownSm = useMediaQuery(theme.breakpoints.down("sm"));
      const screenSizeUpLg = useMediaQuery(theme.breakpoints.up("lg"));
      const screenSizeUpMd = useMediaQuery(theme.breakpoints.up("md"));
      const screenSizeUpSm = useMediaQuery(theme.breakpoints.up("sm"));
      const [hasError, setHasError] = useState(false as boolean);
      const paymentFormRef: RefObject<Form | null | undefined> = useRef(null);
      const [formData, setFormData] = useState({});
      const [selectedReason, setSelectedReason] = React.useState('');
      const [displayButtons, setDisplayButtons] = React.useState(true)
      const [commitStatus, setCommitStatus] = React.useState('OPEN' as string)
      const [shouldCallPromise, setShouldCallPromise] = React.useState(true as boolean)
      const [promiseResponse, setPromiseResponse] = useState(null as any)
      const [loader, setLoader] = useState<boolean>(false);
      const cartDrawer = useSelector(cartDrawerState)
      const cartTotal = useSelector(totalAmount)
      const user = useSelector(User)
      const userAddress = useSelector(userInfo)

      const onDrawerClose = () => {
            dispatch(toggleDrawer())
            setShouldCallPromise(true)
      }

      React.useEffect(() => {
            if (cartDrawer && shouldCallPromise && cartProducts.length && user && userAddress.length >= 1) {
                  callingPromise(userAddress[0].addressType == "SHIPPING" ? userAddress[0].pinCode : userAddress[1].pinCode, userAddress)
            }

      }, [cartDrawer, user, userAddress, cartProducts])

      React.useEffect(() => {
            if (cartDrawer && promiseResponse && cartProducts)
                  (async () => {
                        updateProducts(userAddress[0].addressType == "SHIPPING" ? userAddress[0].pinCode : userAddress[1].pinCode);
                  })()
      }, [promiseResponse, cartProducts])


      const getProducts = (itemList: any) => {
            let body: any = {}
            body.products = itemList.map((item: any) => (
                  {
                        "country_of_origin": "CHL",
                        "gtin": "string",
                        "is_courierable": true,
                        "is_dangerous": true,
                        "is_gift": true,
                        "is_returnable": true,
                        "preference": {
                              "bag_required": true,
                              "picker_notes": "string"
                        },
                        "co_line_id": item.co_line_id,//random
                        // "product_class": "Pet food",
                        // "product_id": "11420",
                        // "product_name": "Belcando water buffalo 400 GR",
                        // "product_type": "Pet food",
                        "qty_same_as_parent": true,
                        "quantity": item.quantity,
                        "requires_shipping": true,
                        "sku_id": item.variantCode
                  }
            ))
            return body
      }

      const handleProceedToCheckout = () => {
            placeOrderFunc();
            dispatch(togglePaymentDrawer())
            dispatch(toggleDrawer())
            setShouldCallPromise(true)
      }

      // let qty = {
      //       sku_id: variantObject.variantCode,
      //       quantity: {
      //           number_of_units: quantity,
      //           unit_size: "1",
      //           unit_size_fraction: "1",
      //           unit_of_measure: "EA",
      //       },
      //   };

      const callingPromise = async (pincode: any, addressData: any) => {
            const emailId = user ? user.email : null
            let shipToPostCode = pincode
            let countryCode = "CL"
            let productString = JSON.stringify(getProducts(cartProducts))
            let convertHeaderData = shipToPostCode + countryCode + productString
            const hash = CryptoJS.MD5(convertHeaderData).toString();

            let bodyData = {
                  cart_id: uuidv4(),
                  order_type: 'FRW',
                  channel: 'ONLINE',
                  transaction_start_datetime: new Date().toISOString(),
                  source_trigger: 'CART',
                  currency_code: 'CLP',
                  customer_profile_info: {
                        "profile_id": emailId,
                        "profile_type": "CONSUMER"
                  },
                  store_id: "ejje-dotcom-001",
                  pos_id: '1',
                  "ship_to_address": {
                        "address_id": addressData[0].addressType == "SHIPPING" ? addressData[0].addressId : addressData[1].addressId,
                        "phone_numbers": [
                              {
                                    "type": "PERSONAL",
                                    "country_code": "CL",
                                    "number": "1212121212"
                              }
                        ],
                        "country_code": "CL",
                        "postal_code": pincode
                  },
            };


            setLoader(true);

            try {
                  const config = {
                        headers: {
                              'revision': hash,
                        },
                  };
                  const { status, data } = await axiosInstance.post(URLS.promise, bodyData, config as any)
                  if (status === 201) {
                        dispatch(storePromiseResponse(data))
                        setPromiseResponse(data)
                        setShouldCallPromise(false)

                  }
            } catch (err: any) {


            }
            setLoader(false);
      }

      const updateProducts = async (pincode: string) => {
            let shipToPostCode = pincode
            let countryCode = "CL"
            let productString = JSON.stringify(getProducts(cartProducts))
            let convertHeaderData = shipToPostCode + countryCode + productString
            const hash = CryptoJS.MD5(convertHeaderData).toString();

            const products = cartProducts.map((item: any) => ({
                  "article_id": item.productCode,
                  "country_of_origin": "Chile",
                  "gtin": "string",
                  "is_courierable": true,
                  "is_dangerous": true,
                  "is_gift": true,
                  "is_returnable": true,
                  "preference": {
                        "bag_required": true,
                        "picker_notes": "string"
                  },
                  "co_line_id": item.co_line_id,
                  "category": item.category,
                  "product_id": item.id,
                  "product_name": item.productName,
                  "product_type": item.category,
                  "qty_same_as_parent": false,
                  "quantity": {
                        "number_of_units": item.quantity,
                        "unit_of_measure": "EA",
                        "unit_size": 1,
                        "unit_size_fraction": 1
                  },
                  "requires_shipping": true,
                  "sku_id": item.variantCode
            }))
            setLoader(true);
            try {
                  const config = {
                        headers: {
                              'revision': hash,
                        },
                  };
                  const body = {
                        products
                  }

                  const { status, data } = await axiosInstance.put(URLS.products(promiseResponse.promise_id), body, config as any)
                  if (status === 201) {

                  }

            } catch (err: any) {


            }
            setLoader(false);

      }

      const placeOrderFunc = async () => {

            if (!isLogin) {
                  dispatch(setOpenAuthenticationDrawer(true));
            } else {

                  const body = {
                        "billingAddressId": 2222,
                        "billingAddressName": "string",
                        "countryCode": "CL",
                        "currencyCode": "CL",
                        "orders": cartProducts.map((item: any) => ({
                              'orderLineId': item.co_line_id,
                              'quantity': item.quantity,
                              'variantId': item.variantId
                        })),
                        "payment": [
                              {
                                    "amount": 0,
                                    "currency_code": "CL",
                                    "payment_datetime": "string",
                                    "payment_gateway": "string",
                                    "payment_ref_id": "string",
                                    "payment_type": "CASH",
                                    "transaction_id": "string"
                              }
                        ],
                        "posNo": "string",
                        "promiseId": "string",
                        "promiseVersion": "string",
                        "redeemFromLoyaltyPoints": true,
                        "shippingAddressId": 3333333,
                        "shippingAddressName": "string",
                        "storeId": "string",
                        "supplyTypeCode": "string"
                  }
                  try {
                        setLoader(true);

                        const { status, data } = await axiosInstance.post(URLS.getOrderSummary, body);
                        if (status === 200) {
                              // setOpenDrawer(true);
                              // setOrderDetails(body);
                              // setOrderSummary(data);
                              // fetchAddress(pincode, itemList)
                              // setLoader(false);
                              dispatch(setOrderSummaryResponse(data))

                        }
                  } catch (error: any) {
                        let errorMessage = 'An unknown error occurred';

                        if (error?.response && error?.response.data) {
                              errorMessage = error.response.data.error_description || error.response.data.message || errorMessage;
                        }
                        dispatch(
                              setNotificationMessage({
                                    display: true,
                                    severity: "error",
                                    message: errorMessage
                              })
                        );
                  }
                  setLoader(false);


            }
      };

      return (
            <Box sx={{ zIndex: "100" }}>
                  {loader && <PageLoader />}
                  <DrawerMenu
                        open={cartDrawer}
                        handleClose={onDrawerClose}
                        width={getDrawerWidth(screenSizeDownSm, screenSizeDownMd, screenSizeUpLg)}

                  >
                        <Box display={"flex"} alignItems="center" justifyContent={"space-between"} px={3} py={2} >
                              <Typography id="modal-modal-title" variant="h5" fontWeight={600} color={"primary"}>
                                    Cart Items
                              </Typography>
                              <ClearOutlinedIcon
                                    onClick={onDrawerClose}
                                    sx={{
                                          ":hover": { cursor: "pointer" },
                                          color: theme.palette.primary.main,
                                    }}
                              />
                        </Box>
                        <Divider
                              sx={{
                                    mx: 3,
                                    borderStyle: "dashed",
                                    borderColor: theme.palette.secondary.main,
                              }}
                        />

                        <Box>

                              {(cartProducts as any).length === 0 ? <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",

                              }}> <h3>Cart is empty</h3></div> :
                                    (
                                          <Box padding="20px">
                                                <Card sx={{ padding: '7px' }}>


                                                      <Box
                                                            sx={{

                                                                  display: "grid",
                                                                  gridTemplateColumns: '1fr',
                                                                  padding: "20px",





                                                            }}
                                                      >
                                                            <>
                                                                  {cartProducts.map((item: any) => (
                                                                        <>
                                                                              <ProductCard item={item} />
                                                                        </>
                                                                  ))}
                                                            </>
                                                            <Box display={"flex"} justifyContent="space-between" mx={1} mt="10px">
                                                                  <Typography color={"primary"} fontWeight={600} flex={5} mr={1}>
                                                                        Cart Total
                                                                  </Typography>
                                                                  <Typography color={"primary"} fontWeight={600} flex={4} textAlign={"end"}>
                                                                        {(cartTotal || 0).toFixed(2)}
                                                                  </Typography>

                                                            </Box>

                                                      </Box>




                                                </Card>
                                                <Box mt={2} >
                                                      <PrimaryButton fullWidth onClick={handleProceedToCheckout}>
                                                            Proceed to Checkout
                                                      </PrimaryButton>
                                                </Box>

                                          </Box>
                                    )}
                        </Box>

                  </DrawerMenu>
            </Box>
      );
};

export default Cart;
