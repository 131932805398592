import React from "react";
import ErrorIcon from '@mui/icons-material/Error';
import { Link, Typography, Box } from "@mui/material";
import strings from "../../common/Translation/Translate";

const NotFound = () => {
    // useEffect(() => ScrollTop(0),[])

    return(
    <Box 
        mt={21} 
        height={'-webkit-fill-available'} 
        display={"flex"} 
        flexDirection={'column'}
        alignItems={'center'}
        gap={1}
        // position={'sticky'}
    >
        <ErrorIcon color={'error'} sx={{ fontSize: '5em' }}/>
        <Typography variant="h4" fontWeight={'bold'}>{strings.somethingWentWrongText}</Typography>
        <Link href="/" underline="hover">
            <Typography variant="h6">{strings.returnToHomePageText}</Typography>
        </Link>     
    </Box>
);
}
export default NotFound;
