import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { AlertColor, Box, CircularProgress, Grid, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import Captcha from 'components/Captcha/Captcha';
import { ChangeEmailFormDialog } from 'components/Dialog/ChangeEmailFormDialog';
import Notification from 'components/Notification';
import React, { FC, MouseEvent, RefObject, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'utils/constants/urls';
import axiosInstance from 'utils/redux/axios';
import { removeErrorFieldsFromValues } from 'utils/validators';
import strings from '../../../common/Translation/Translate';
import PrimaryButton from '../../../components/Button/PrimaryButton';
import Form, { FormDataModel } from '../../../components/Form';
import { ForgotPasswordForm } from './ForgotPasswordForm';

const ForgotPassword: FC<any> = ({
  handleClose,
  setDrawerState,
  setDrawerMessage,
}: {
  handleClose: any;
  setDrawerState: any;
  setDrawerMessage: any;
}) => {
  const theme = useTheme();
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  let forgotPasswordForm: RefObject<Form | null | undefined> = useRef();
  const [hasError, setHasError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [captchaRes, setCaptchaRes] = useState<string>('');
  const [captaFlag, setCaptaFlag] = useState<boolean>(false);
  const [changeEmailFormDialogOpen, setChangeEmailFormDialogOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = React.useState<{
    display: boolean;
    severity: AlertColor | null;
    message: any;
  }>({
    display: false,
    severity: null,
    message: '',
  });
  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  const handleForgotPassword = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { getFormData } = forgotPasswordForm.current as {
      getFormData: () => { formData: FormDataModel; isFormValid: boolean };
    };
    const { formData, isFormValid } = getFormData();
    const bodyData = removeErrorFieldsFromValues(formData);
    try {
      setHasError(false);
      if (isFormValid) {
        if (!captchaRes) {
          setCaptaFlag(true);
          return false;
        } else {
          setLoading(true);
          const body = {
            email: bodyData.email,
            captchaResponse: {
              captchaRes: captchaRes,
            },
          };
          const { status, data } = await axiosInstance.post(URLS.forgetPassword, body);
          if (status === 200) {
            setLoading(false);
            setEmail(bodyData.email as string);
            setChangeEmailFormDialogOpen(true);
            // handleClose();
          }
        }
      } else {
        setHasError(true);
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        setMessage({
          display: true,
          severity: 'error',
          message: error.response.data.message,
        });
      }
    }
  };

  const resendOtpFunc = async () => {
    try {
      setLoading(true);
      const body = {
        email: email,
        mailType: 'FORGOT_PASSWORD',
      };
      await axiosInstance.post(URLS.resendOtp, body);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const verifyCallback = (response: string, captchaFlag: boolean) => {
    setCaptaFlag(captchaFlag);
    setCaptchaRes(response);
  };

  return (
    <>
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      <Box display={'flex'} justifyContent={'flex-end'} px={5} py={2}>
        <ClearOutlinedIcon
          onClick={handleClose}
          sx={{
            ':hover': { cursor: 'pointer' },
            color: theme.palette.primary.main,
          }}
        />
      </Box>
      <Typography fontWeight={'bold'} ml={8} mb={1} color={'primary'} fontSize={'20px'}>
        {strings.forgotPasswordText}
      </Typography>
      <Box mx={screenSizeDownMd ? 3 : 8} bgcolor={theme.palette.secondary.light}>
        <form onSubmit={handleForgotPassword as any}>
          <Grid
            container
            mt={1}
            px={screenSizeDownMd ? 2 : 4}
            py={3}
            bgcolor={theme.palette.secondary.light}
            spacing={1}>
            <Form
              hasError={hasError}
              ref={forgotPasswordForm as RefObject<Form>}
              model={ForgotPasswordForm(strings)}
              values={{}}
              forgetPassword={() => alert('forget password')}
            />
            <Grid item>
              <Captcha verify={verifyCallback} />
              <Typography variant="subtitle2" color="error">
                {captaFlag ? strings.captchaValidation : ''}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <PrimaryButton disabled={loading} sx={{ mt: 1, py: 1 }} onClick={handleForgotPassword} type="submit">
                {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
                {strings.forgotPasswordText}
              </PrimaryButton>
            </Grid>
            <Grid item xs={12}>
              <Box display={'flex'} justifyContent="center">
                <Typography fontSize={'0.75rem'}>{strings.HaveAnAccount}&nbsp;</Typography>
                <Link
                  sx={{ cursor: 'pointer' }}
                  color={theme.palette.secondary.main}
                  onClick={() => setDrawerState(1)}
                  underline={'hover'}>
                  <Typography fontWeight={500} fontSize={'0.75rem'}>
                    {strings.LoginHeretext}
                  </Typography>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
      <ChangeEmailFormDialog
        open={changeEmailFormDialogOpen}
        handleClose={setChangeEmailFormDialogOpen}
        email={email}
        resendOtpFunc={resendOtpFunc}
        verifyOtpMailType={'FORGOT_PASSWORD'}
        caseType="forget_password"
        successMessage={strings.forgotPasswordCodeMessage}
        header={strings.resetPasswordButton}
        setDrawerState={setDrawerState}
        setDrawerMessage={setDrawerMessage}
      />
    </>
  );
};

export default ForgotPassword;
