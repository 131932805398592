
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, useTheme, TablePagination } from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { useState, useCallback, useEffect } from "react";
import ListPage from "screens/Common/ListPage";
import { URLS } from "utils/constants/urls";
import axiosInstance from "utils/redux/axios";

export const CouponRedeemptionHistory = ({ loading, setLoading }: { loading: any, setLoading: any }) => {
    const theme = useTheme()
    const [pageResponse, setPageResponse] = useState<any[]>([]);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [pageSize, setPageSize] = useState<any>(10);

    const getList = async () => {
        try {

            const { data } = await axiosInstance.get(URLS.rewardHistory);
            setPageResponse(data);

        } catch (error: any) {

        };
    }

    useEffect(() => {
        (async () => {
            await getList();
        })();
    }, []);

    return (<>
        <TableContainer component={Paper} sx={{
            borderRadius: 0,
            // boxShadow: 0
        }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{
                    '.MuiTableCell-root': {
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.secondary.contrastText,
                        border: `1px solid ${theme.palette.secondary.main}`,
                        fontSize: '14px',
                        fontWeight: 600,
                        '.MuiTableSortLabel-root:active, .MuiTableSortLabel-root:hover, .MuiTableSortLabel-root:focus': {
                            color: theme.palette.secondary.contrastText,
                        },
                    },
                }}>
                    <TableRow>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>Reward Name</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>Reward Value</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>Reward Type</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>Transaction Type</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>Created Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pageResponse.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize,).map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell>{row.reward_name}</TableCell>
                            <TableCell>{row.reward_value}</TableCell>
                            <TableCell>{row.reward_type}</TableCell>
                            <TableCell sx={{ color: row.transaction_type.toLowerCase() === 'debit' ? 'red' : 'green' }}>
                                {row.transaction_type}
                            </TableCell>
                            <TableCell>{new Date(row.created_date_time).toLocaleString()}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        {pageResponse.length > pageSize && (
            <TablePagination
                count={pageResponse.length}
                rowsPerPage={pageSize}
                page={pageIndex}
                onRowsPerPageChange={e => {
                    setPageIndex(0);
                    setPageSize(e.target.value);
                }}
                onPageChange={(event: unknown, newPage: number) => setPageIndex(newPage)}
                ActionsComponent={TablePaginationActions}
            />
        )}
    </>)

}