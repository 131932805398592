import { create } from '@mui/material/styles/createTransitions';
import { TEST_SHOP_BASE_API_URL, CLOVERBAY_LABS_BASE_API_URL } from '../../config/envConfig';

const createTestShopUrl = (endpoint: string) => `${TEST_SHOP_BASE_API_URL}${endpoint}`;
const createCloverBayLabsUrl = (endpoint: string) => `${CLOVERBAY_LABS_BASE_API_URL}${endpoint}`;
console.log(process.env.TEST_SHOP_BASE_API_URL);
console.log(process.env.CLOVERBAY_LABS_BASE_API_URL);
export const URLS = {
  // TEST SHOP URL
  login: createTestShopUrl(`/oauth/token`),
  signup: createTestShopUrl(`/api/users/registration`),
  forgetPassword: createTestShopUrl(`/api/users/forgot-password`),
  resendOtp: createTestShopUrl(`/api/users/resend-code`),
  verifyOtp: createTestShopUrl(`/api/users/verify-code`),
  resetPassword: createTestShopUrl(`/api/users/password`),
  rewardPage: createTestShopUrl(`/api/products/page`),
  productDetails: (id: any) => createTestShopUrl(`/api/products/${id}`),
  filterAutocompleteUrl: (key: string, value: string) => createTestShopUrl(`api/search-products?${key}=${value}`),
  // ---------------------------------------------------------------------------------------------------------------------
  logout: createTestShopUrl(`/authenticated/users/logout`),
  userProfile: createTestShopUrl(`/authenticated/users/profile`),
  editUserProfile: createTestShopUrl(`/authenticated/users/update-profile`),
  changePassword: createTestShopUrl(`/authenticated/users/change-password`),
  myOrders: (orderNumber?: string) =>
    createTestShopUrl(`/authenticated/my-orders/page` + (orderNumber ? `?orderNumber=${orderNumber}` : '')),
  rewardHistory: createTestShopUrl(`/authenticated/users/reward-history`),
  getTotalRewadPoints: createTestShopUrl(`/authenticated/users/reward-balance`),
  getOrderSummary: createTestShopUrl(`/authenticated/order-summary`),
  placeOrderApi: createTestShopUrl(`/authenticated/orders`),
  redeemCode: createTestShopUrl(`/authenticated/promotions/redemption`),
  loyalty_points: createTestShopUrl(`/authenticated/users/my/loyalty-details`),
  orderHistory: createTestShopUrl(`/authenticated/my/orders/page`),
  loyality_tx: createTestShopUrl(`/authenticated/users/my/loyalty-tx`),
  placeOrder: createTestShopUrl(`/authenticated/place-order`),
  transactionStatus: createTestShopUrl(`/authenticated/transaction-status`),
  address: createTestShopUrl('/authenticated/users/addresses'),
  productWithProductCode: (code: string) => createTestShopUrl(`/api/products/detail?product_code=${code}`),
  invoiceDetails: (orderNumber: any) =>
    createTestShopUrl(`/authenticated/customer-order/${orderNumber}/invoices/details`),
  returnOrder: createTestShopUrl('/authenticated/customer-order/return'),
  invoice: (invoiceNumber: string) => createTestShopUrl(`/authenticated/invoices/${invoiceNumber}`),

  // -------------------------------------------------------------------------------------------------------------------
  // CLOVERBAY LABS URL
  pdp: createCloverBayLabsUrl(`/nebula/promise/v1/promise/pdp`),
  pincodeCheck: createCloverBayLabsUrl(`/nebula/promise/v1/promise-engine/serviceability/locations`),
  promise: createCloverBayLabsUrl(`/nebula/promise/v1/promise`),
  addressPromise: (promiseId: any) => createCloverBayLabsUrl(`/nebula/promise/v1/promise/${promiseId}/address`),
  // products: (promiseId: any) => createCloverBayLabsUrl(`/nebula/promise/v1/promise/${promiseId}/products`),
  fullFilmentOptions: (promiseId: any) =>
    createCloverBayLabsUrl(`/nebula/promise/v1/promise/${promiseId}/fulfillment-options`),
  //   fullFilmentOptions: (promiseId: any) => createCloverBayLabsUrl(`/nebula/promise/v1/promise/fulfillment-options/mock`),

  selection: (promiseId: any) => createCloverBayLabsUrl(`/nebula/promise/v1/promise/${promiseId}/selection`),
  state: (promiseId: any) => createCloverBayLabsUrl(`/nebula/promise/v1/promise/${promiseId}/state`),
  getLoyaltyPoints: createCloverBayLabsUrl('/elysian/loyalty/v1/customers/rewards/aggregate/balance'),
  cancelCustomerOrder: createTestShopUrl(`/authenticated/customer-order/cancel`),
  products: (promiseId: string) => createCloverBayLabsUrl(`/nebula/promise/v1/promise/${promiseId}/products`),
  returnEnquiry: (orderNumber: string) =>
    createCloverBayLabsUrl(`/nebula/return-enquiry/v1/orders/${orderNumber}/events/return-policy-enquiry`),
  cancelEnquiry: (orderNumber: string) =>
    createCloverBayLabsUrl(`/nebula/refund-enquiry/v1/orders/${orderNumber}/cancel-enquiry`),
};
