import { URLS } from "utils/constants/urls";
import axiosInstance from "utils/redux/axios";

export const cancelEnquiry = (orderNumber: string, items: any) => {
	return items;
	const body = {
		order_lines: items.map((item: any) => {
			return {
				item_id: item.variantCode,
				quantity: {
					unit_size: 1,
					unit_of_measure: "EA",
					number_of_units: item.quantity,
					unit_fraction: 1,
				},
			};
		}),
	};
	try {
		const result: any = axiosInstance.post(URLS.cancelEnquiry(orderNumber), body);
		if (result.status === 200) {
			return items.filter((item: any) => result.data[item.variantCode].is_cancellable);
		}
	} catch (err) {}

	return [];
};

export const returnEnquiry = (orderNumber: string, items: any) => {
	return items;
	const body = {
		source: "RTS",
		order_lines: items.map((item: any) => {
			return {
				item_id: item.variantCode,
				quantity: {
					unit_size: 1,
					unit_of_measure: "EA",
					number_of_units: item.quantity,
					unit_fraction: 1,
				},
			};
		}),
		manager_override: true,
	};
	try {
		const result: any = axiosInstance.post(URLS.returnEnquiry(orderNumber), body);
		if (result.status === 200) {
			return items.filter((item: any) => result.data[item.variantCode].is_returnable);
		}
	} catch (err) {}
	return [];
};
