import { createSlice } from "@reduxjs/toolkit";
import { removeFromStorage, saveToStorage } from "../../storage";

interface initStateProps {
    rewards: any
}
const rewardsSlice = createSlice({
    name: "rewardsSlice",
    initialState: {
        rewards: null
    },
    reducers: {
        setRewardPoints(state, action) {
            state.rewards = action.payload;
        }
    },

});


export default rewardsSlice.reducer;

export const { setRewardPoints } = rewardsSlice.actions;

export const rewards = (state: { rewardsSlice: initStateProps }) => state.rewardsSlice.rewards;


