import { RefObject, useState } from "react";
import { ToolTip } from "../../components/Tooltip/ToolTip";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, CircularProgress, Grid, IconButton, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import Form from "../../components/Form";
import strings from "../../common/Translation/Translate";
import PrimaryButton from "components/Button/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { Authenticated } from "utils/redux";
import { setOpenAuthenticationDrawer } from "utils/redux/reducer/authentication-slice";
import axiosInstance from "utils/redux/axios";
import { URLS } from "utils/constants/urls";
import { setNotificationMessage } from "utils/redux/reducer/Notification-details-slice";
import PageLoader from "components/PageLoader";

type ActionColumnProps = {
  editRow: (idx: any) => void,
  deleteRow: (idx: any) => void,
  index: any
}
export const ActionColumn = ({ editRow, deleteRow, index }: ActionColumnProps) =>
  <>
    <ToolTip title="Edit">
      <IconButton sx={{ p: 0, mr: 1 }} onClick={() => editRow(index)} aria-label="edit">
        <EditIcon fontSize="small" />
      </IconButton>
    </ToolTip>
    <ToolTip title="Delete">
      <IconButton sx={{ p: 0 }} onClick={() => deleteRow(index)} aria-label="delete">
        <DeleteOutlineOutlinedIcon fontSize="small" />
      </IconButton>
    </ToolTip>
  </>;

type formComponentProps = {
  hasError: any,
  handleSave: (e: any) => void,
  formRef: any,
  pageResponse: any,
  dropdownValue?: any,
  formModel: any
}
export const FormComponent = ({
  hasError,
  formRef,
  pageResponse,
  handleSave,
  dropdownValue = {},
  formModel
}: formComponentProps) => {

  const { common_button_save } = strings as any;

  return (
    <>
      <Grid container spacing={2} sx={{ my: 2 }}>
        <Form
          hasError={hasError}
          ref={formRef as RefObject<Form>}
          model={formModel()}
          values={pageResponse}
          dropdownData={dropdownValue}
        // onChange={handleChange}
        />
      </Grid>
      <Grid container sx={{ my: 1 }}>
        <Button variant="contained" sx={{ color: "#ffffff" }}
          onClick={(e) => handleSave(e)}>
          {common_button_save}
        </Button>
      </Grid>
    </>);
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

type ReedemCouponsProps = {
  justifyContent?: string,
  loading: any,
  setLoading: any
}
export const ReedemCoupons = ({ justifyContent = "start", loading, setLoading }: ReedemCouponsProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isLogin = useSelector(Authenticated)
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [value, setValue] = useState<string>('')
  const [hasError, setHasError] = useState<boolean>(false)

  const handleReedem = async () => {
    if (isLogin) {
      if (value === '') {
        setHasError(true)
      }
      else {
        const body = { promoCode: value }
        try {
          setLoading(true)
          const { status } = await axiosInstance.post(URLS.redeemCode, body)
          if (status === 200) {
            setLoading(false)
            dispatch(setNotificationMessage({
              display: true,
              severity: 'success',
              message: 'Coupon redeemed successfully',
            }))
          }
        } catch (error: any) {
          setLoading(false)
          dispatch(setNotificationMessage({
            display: true,
            severity: 'error',
            message: error.response?.data?.error_description ? error.response.data.error_description : error.response.data.message,
          }))
        }
      }
    } else {
      dispatch(setOpenAuthenticationDrawer(true))
    }
  }

  return (
    <>
      <Grid container item spacing={2} xs={12} justifyContent={justifyContent} my={1}>
        <Grid item xs={12} sm={8} lg={4}>
          <Typography fontSize={'14px'}>
            Have a coupon? Enter coupon code here
          </Typography>
          <TextField
            error={hasError}
            helperText={hasError ? 'Please enter a coupon code' : ''}
            sx={{
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.secondary.main,
                borderRadius: 0
              },
              '.MuiInputBase-root': {
                backgroundColor: theme.palette.secondary.light,
                height: '35px'
              }
            }}
            fullWidth
            placeholder={strings.enterCodePlaceholder}
            value={value}
            onChange={(e: any) => {
              setHasError(e.target.value === '')
              setValue(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={2} mt={screenSizeDownSm ? 0 : 2.5}>
          <PrimaryButton fullWidth onClick={handleReedem} disabled={loading}>
            {loading && <CircularProgress color="inherit" sx={{ mr: 2 }} size={20} />}
            Reedem now
          </PrimaryButton>
        </Grid>
      </Grid>
    </>


  )
}
