import { AnyAction, configureStore, EnhancedStore } from "@reduxjs/toolkit";
import { ThunkMiddlewareFor } from "@reduxjs/toolkit/dist/getDefaultMiddleware";
import authenticationSlice from "../reducer/authentication-slice";
import costingSlice from "../reducer/costing-slice";
import notificationDetailsSlice from "../reducer/Notification-details-slice";
import searchedDataSlice from "../reducer/searched-data-slice";
import rewardsSlice from "../reducer/rewards-slice";
import returnOrderSlice from "../reducer/order-return-slice";
import addressSlice from "../reducer/address-slice";
import cartSlice from "../reducer/cart-slice";
import createOrderSlice from "../reducer/create-order-slice";
import orderHistorySlice from "../reducer/order-history-slice";

const store: EnhancedStore<any, AnyAction, [ThunkMiddlewareFor<any>]> = configureStore({
	reducer: {
		authenticationSlice,
		notificationDetailsSlice,
		searchedDataSlice,
		costingSlice,
		rewardsSlice,
		returnOrderSlice,
		addressSlice,
		cartSlice,
		createOrderSlice,
		orderHistorySlice,
	},
});

export default store;
