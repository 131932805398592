import { Tooltip } from "@mui/material";
import React from "react";

type TooltipProps = {
    title: any,
    children: any,
    onOpen?: any,
    onClose?: any,
    open?: any
}
export const ToolTip = ({ title, children, onOpen, onClose, open }: TooltipProps) => (
    <Tooltip
        title={title}
        onOpen={onOpen}
        onClose={onClose}
        open={open}
    >
        {children}
    </Tooltip>
)