import React, { FC, useState } from "react";
import { LocalizationProvider, MobileTimePicker,MobileTimePickerProps } from "@mui/x-date-pickers";
import { validateInput } from "../../utils/validators/Validators";

import "./styles.css";
import { Dayjs } from "dayjs";
import { FormValidators } from "../Form";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/de';
import strings from "common/Translation/Translate";
import 'dayjs/locale/pt';
import 'dayjs/locale/en';
import { useSelector } from "react-redux";
import { defaultLanguage } from "utils/redux/reducer/authentication-slice";

interface OwnProps {
  field?: string;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  typeValue?: string;
  textChange: (
    value: string,
    field: string,
    error?: { error: boolean; message: string }
  ) => void;
  validators?: FormValidators[];
  hasError?: boolean;
  fieldError?: string | boolean;
  label?: string;
  disableFuture?: boolean;
  minDate?: any;
  size?:any;
  
}

const TimeInput: FC<OwnProps & MobileTimePickerProps<any, any>> = ({
  hasError,
  validators,
  textChange,
  field,
  value,
  placeholder,
  fieldError,
  disabled,
  disableFuture,
  minDate,
  label,
  typeValue,
  size,
  ...rest
}) => {
  const selectedlangLocaleCode = useSelector(defaultLanguage).value.split('_')[0]
  const [error, setError] = useState<{
    error: boolean;
    message: string;
  } | null>(null);
  const handleChange = (value: Dayjs | null) => {
    if (value) {
      const inputError = validateInput(
        validators as FormValidators[],
        value as any
      );
      textChange(
        value as any,
        field as string,
        inputError
      );
      setError(inputError);
    }
  };

  const handleOnAccept=(value:any)=>{
    if(value){
    // onAccept(value)
    }
  }
  const customMessage = () => !error && (hasError && fieldError) ? strings.requiredField: ''
  return (
    <LocalizationProvider adapterLocale={selectedlangLocaleCode} dateAdapter={AdapterDayjs} >
    <MobileTimePicker
      {...rest}
      label={label}
      onAccept={handleOnAccept}
      value={value}
      onChange={(newValue: Dayjs | null) => handleChange(newValue)}
      disabled={disabled}
      className={`input-field ${fieldError}`}
      OpenPickerButtonProps={{ color: "primary" }}
      renderInput={(params) => (
        <TextField {...params} 
        size={size}
        helperText={error && error.error ? error.message : customMessage()}
        error={((error && error.error) || (!error && hasError && fieldError)) ? true : false}
        />
      )}
    />
    </LocalizationProvider>
  );
};

export default TimeInput;
