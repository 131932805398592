import React from "react";
import './style.css'
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from "@mui/material";

const PageLoader = () => (
  <div className="loader_wrapper">
    {/* Backdrop */}
    <div className="loader_backdrop" />

    {/* Loader */}
    <div className="loader_content">
      <div className="spinner-border text-primary" role="status" />
      <Box display="inline-flex">
        <CircularProgress color="primary" />
      </Box>
    </div>
  </div>
);

export default PageLoader;
