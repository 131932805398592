import { createSlice } from "@reduxjs/toolkit";

interface ItemType {
	brand: string;
	category: string;
	productCode: string;
	productName: string;
	price: number;
	imageUrl: string;
	id: number;
	variantCode: string;
	quantity: number;
	maxQuantity: number;
	totalPrice: number;
}

// brand: productDetails.brand,
// category: productDetails.category,
// productCode: productDetails.productCode,
// productName: productDetails.productName,
// price: variantObject.price,
// imageUrl: productDetails.imageUrl,
// id: productDetails.id,
// variantCode: variantObject.variantCode,
// quantity: quantity,
// maxQuantity: variantObject.quantity
const cartSlice = createSlice({
	name: "cart",
	initialState: {
		cartItems: [] as ItemType[],
		totalAmount: 0,
		totalQuantity: 0,
		drawerState: false,
		paymentDrawerState: false,
		buyNowProduct: [],
	},
	reducers: {
		addCartItem: (state, action) => {
			let item = state.cartItems.find((cartItem) => cartItem.variantCode === action.payload.variantCode);
			if (item) {
				// item.quantity = 1;
				// item.totalPrice = (item.price * item.quantity).toFixed(2);
				// state.totalAmount += +action.payload.price;
				// enqueueSnackbar("Item already the cart ", { variant: "info" });
			} else {
				if (!action.payload.quantity) action.payload.quantity = 1;
				action.payload.co_line_id = state.cartItems.length + 1;
				state.cartItems.push(action.payload);
				action.payload.totalPrice = action.payload.price * action.payload.quantity;
				state.totalAmount += action.payload.totalPrice;
				// enqueueSnackbar("Item added to cart", { variant: "success" });
			}
		},
		removeCartItem: (state, action) => {
			const item = state.cartItems.find((cartItem) => cartItem.variantCode === action.payload.variantCode);
			if (item) {
				state.totalAmount -= item.totalPrice;
			}
			state.cartItems = state.cartItems
				.filter((cartItem) => cartItem.variantCode !== action.payload.variantCode)
				.map((item, index) => ({ ...item, co_line_id: index + 1 }));
			// enqueueSnackbar("Item removed from cart", { variant: "success" });
		},
		clearCart: (state) => {
			state.cartItems = [];
			state.totalAmount = 0;
			// enqueueSnackbar("Cart cleared", { variant: "success" });
		},
		changeQuantity: (state, action) => {
			let item = state.cartItems.find((cartItem) => cartItem.variantCode === action.payload.variantCode);
			if (item) {
				item.quantity = action.payload.quantity;
				item.totalPrice = item.price * item.quantity;
				// state.totalAmount += +item.price;
			}
		},
		toggleDrawer: (state) => {
			state.drawerState = !state.drawerState;
		},
		togglePaymentDrawer: (state) => {
			state.paymentDrawerState = !state.paymentDrawerState;
		},
		setBuyNowProduct: (state, action) => {
			state.buyNowProduct = action.payload;
		},
		// increaseQuantity: (state, action) => {
		// 	let item = state.cartItems.find((cartItem) => cartItem.id === action.payload.id);
		// 	if (item) {
		// 		item.quantity += 1;
		// 		item.totalPrice = (item.price * item.quantity).toFixed(2);
		// 		state.totalAmount += +item.price;
		// 		// enqueueSnackbar("Quantity increased", { variant: "success" });
		// 	}
		// },
		// decreaseQuantity: (state, action) => {
		// 	let item = state.cartItems.find((cartItem) => cartItem.id === action.payload.id);
		// 	if (item && item.quantity > 1) {
		// 		item.quantity -= 1;
		// 		item.totalPrice = (item.price * item.quantity).toFixed(2);
		// 		state.totalAmount -= +item.price;
		// 		// enqueueSnackbar("Quantity decreased", { variant: "success" });
		// 	} else {
		// 		state.totalAmount -= item.price;
		// 		state.cartItems = state.cartItems.filter((cartItem) => cartItem.id !== action.payload.id);
		// 		// enqueueSnackbar("Item removed from cart", { variant: "success" });
		// 	}
		// },
		// inputQuantity: (state, action) => {
		// 	let item = state.cartItems.find((cartItem) => cartItem.id === action.payload.id);

		// 	if (item) {
		// 		state.totalAmount -= item.totalPrice;
		// 		item.quantity = action.payload.quantity;
		// 		item.totalPrice = (item.price * item.quantity).toFixed(2);
		// 		state.totalAmount += +item.totalPrice;
		// 		// enqueueSnackbar("Quantity changed", { variant: "success" });
		// 	}
		// },
	},
});

const getAllVariantCode = (items: any) => {
	return items.map((item: any) => item.variantCode);
};

const getAllProducts = (cartItems: any) => {
	const items = JSON.parse(JSON.stringify(cartItems));
	return items.map((item: any, index: number) => {
		return {
			...item,
			co_line_id: index + 1,
		};
	});
};

const getTotalAmount = (state: any) => {
	if (state.cartSlice.buyNowProduct.length) return state.cartSlice.buyNowProduct[0].price * state.cartSlice.buyNowProduct[0].quantity;
};
export const {
	addCartItem,
	removeCartItem,
	clearCart,
	changeQuantity,
	toggleDrawer,
	togglePaymentDrawer,
	setBuyNowProduct,
} = cartSlice.actions;

export const cartItems = (state: any) => state.cartSlice.cartItems;
export const variantCodesAllProductsInCart = (state: any) => getAllVariantCode(state.cartSlice.cartItems);
export const totalAmount = (state: any) => {
	return state.cartSlice.cartItems.reduce((total: number, item: any) => total + Number(item.totalPrice), 0);
};
export const cartDrawerState = (state: any) => state.cartSlice.drawerState;
export const buyNowProduct = (state: any) => state.cartSlice.buyNowProduct;
export const buyNowProductTotalAmount = (state: any) => getTotalAmount(state);

export const totalQuantity = (state: any) => state.cartSlice.cartItems.length;
export const paymentDrawerState = (state: any) => state.cartSlice.paymentDrawerState;
export default cartSlice.reducer;
