import { createSlice } from "@reduxjs/toolkit";
import { removeFromStorage, saveToStorage } from "../../storage";

interface initStateProps {
	address: any;
}
const addressSlice = createSlice({
	name: "addressSlice",
	initialState: {
		address: [],
	},
	reducers: {
		setUserAddress(state, action) {
			state.address = action.payload;
		},
	},
});

export default addressSlice.reducer;

export const { setUserAddress } = addressSlice.actions;

export const userAddress = (state: { addressSlice: initStateProps }) => state.addressSlice.address;
