import React, { RefObject, useRef } from 'react'
import Form, { FormDataModel } from 'components/Form';
import Box from '@mui/material/Box'
import { AddressForm1, AddressForm2 } from './AddressForm'
import strings from '../../../common/Translation/Translate'
import { Grid, FormControlLabel, FormGroup, Typography, AlertColor } from '@mui/material';
import PrimaryButton from '../../../components/Button/PrimaryButton';
import Checkbox from '@mui/material/Checkbox';
import axiosInstance from 'utils/redux/axios';
import { removeErrorFieldsFromValues } from 'utils/validators';
import { display } from '@mui/system';
import { URLS } from 'utils/constants/urls'
import { InputAdornment, Button } from "@mui/material";
import { endianness } from 'os';
import { DataArray } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { setUserAddress } from 'utils/redux/reducer/address-slice';
import { getRegionCode, getCountryCode } from './helperfunciton';


const Address = ({ handleMessage }: { handleMessage: ({ }: { display: boolean, severity: AlertColor | null, message: string }) => void }) => {
      const [address, setAddress] = React.useState([{ addressType: "", pinCode: "" }, { addressType: "" }]);
      const [resetDisabled, setResetDisabled] = React.useState(true);
      const [pincodeColor, setPincodeColor] = React.useState("primary")
      const dispatch = useDispatch();
      React.useEffect(() => {
            const fetchServiceability = async () => {
                  const pincode = address[0].pinCode;
                  const body = {
                        country_code: "CL",
                        postal_code: pincode,
                  };
                  try {
                        const result = await axiosInstance.post(URLS.pincodeCheck, body);

                        if (result.status == 200 && result.data.location_serviceable) {
                              setPincodeColor("success")
                        }
                        else {

                              setPincodeColor("error")
                        }
                  }
                  catch (error) {
                        handleMessage(
                              {
                                    display: true,
                                    severity: 'error',
                                    message: "Error occured"
                              }
                        )
                  }
            }
            if (address[0]?.pinCode)
                  fetchServiceability();
      }, [address])


      React.useEffect(() => {
            const fetchAddress = async () => {

                  try {
                        const result = await axiosInstance.get(URLS.address)
                        if (result.data) {
                              setAddress(result.data)
                              dispatch(setUserAddress(result.data))
                              if (result.data.length) {
                                    const data = result.data
                                    if (data[0].addressType == "BILLING") {
                                          setChecked(data[0].isBillingSameAsShipping);
                                    }
                                    else {
                                          setChecked(data[1].isBillingSameAsShipping);
                                    }
                              }
                        }
                  } catch (error: any) {

                  }
            }
            fetchAddress()

      }, [])
      const [checked, setChecked] = React.useState(false);
      const [shipHasError, setShipHasError] = React.useState(false);
      const [billHasError, setBillHasError] = React.useState(false);
      const shipToRef: React.RefObject<Form | undefined> = React.useRef();
      const billToRef: React.RefObject<Form | undefined> = React.useRef();
      const handleUpdate = async () => {
            const { formData: shipForm, isFormValid: valid1 } = getFormValues(shipToRef)
            const { formData: billForm, isFormValid: valid2 } = getFormValues(billToRef)
            if (!valid1) {
                  setShipHasError(true)
                  return
            }
            if (!valid2) {
                  setBillHasError(true)
                  return
            }
            const shipBody = removeErrorFieldsFromValues(shipForm);
            const billBody = removeErrorFieldsFromValues(billForm);
            shipBody.addressType = "SHIPPING"
            billBody.addressType = "BILLING"
            billBody.isBillingSameAsShipping = checked;

            const { status, data } = await axiosInstance.put(URLS.address, [shipBody, billBody]);
            if (status === 200) {

                  handleMessage(
                        {
                              display: true,
                              severity: 'success',
                              message: "Address had been updated Successfully"
                        }
                  )
                  dispatch(setUserAddress(data))
            }
            setResetDisabled(true)



      }
      const handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setChecked(e.target?.checked)
      }
      const getFormValues = (ref: RefObject<Form | undefined>) => {

            if (ref) {
                  const { getFormData } = ref?.current as {
                        getFormData: () => { formData: FormDataModel; isFormValid: boolean }

                  }
                  const { formData, isFormValid } = getFormData();
                  return { formData, isFormValid };
            }
            else {
                  return { formData: {}, isFormValid: {} }
            }

      }

      const handlePincode = async () => {
            const { formData, isFormValid } = getFormValues(shipToRef);
            let data: any = formData;
            const pincode = data?.pinCode || "";
            const body = {
                  country_code: "CL",
                  postal_code: pincode,
            };
            try {

                  const result = await axiosInstance.post(URLS.pincodeCheck, body);

                  if (result.status == 200 && result.data.location_serviceable) {
                        handleMessage({
                              display: true,
                              severity: 'success',
                              message: "Pincode is serviceable"
                        })
                        setPincodeColor("success")
                  }
                  else {
                        handleMessage({
                              display: true,
                              severity: 'error',
                              message: "Pincode is not serviceable"
                        })
                        setPincodeColor("error")
                  }


            }
            catch (error) {
                  handleMessage(
                        {
                              display: true,
                              severity: 'error',
                              message: "Error occured while checking pincode"
                        }
                  )
            }
      };

      const handleReset = () => {
            billToRef.current?.resetForm();
            shipToRef.current?.resetForm();
            setAddress(JSON.parse(JSON.stringify(address)));
      };

      const onChange = (field: string, value: string | boolean, formData: any, deleted?: any) => {
            setResetDisabled(false)
            if (checked) {
                  setChecked(false)
            }
            if (field === 'country') {
                  const countryCode = getCountryCode(value as any)
                  formData.countryCode = countryCode;
                  formData['countryCodeError'] = false;

            }
            if (field === 'region') {
                  const regionCode = getRegionCode(value as any)
                  formData.regionCode = regionCode;
                  formData[`regionCodeError`] = false;

            }

      }
      return (

            <Box sx={{
                  display: "grid",
                  gridTemplateColumns: {
                        xs: "1fr",
                        sm: "1fr 1fr ",
                        gap: "40px"
                  }
            }}>
                  <Box sx={{
                        display: "grid",
                        gridAutoRows: "90px",
                        gridTemplateColumns: "1fr"

                  }}>
                        <Typography sx={{ fontSize: "17px", fontWeight: "bold", margin: "20px" }} color="primary">Ship To</Typography>

                        <Form
                              hasError={shipHasError}
                              ref={shipToRef as React.RefObject<Form>}
                              onChange={onChange}
                              model={AddressForm1({
                                    InputProps: {
                                          endAdornment: (
                                                <InputAdornment position="end">
                                                      <Button
                                                            onClick={handlePincode}
                                                      >
                                                            Check
                                                      </Button>
                                                </InputAdornment>
                                          )

                                    },
                                    pincodeColor: pincodeColor,
                              })}
                              values={address[0]?.addressType === 'SHIPPING' ? address[0] : (address[1] || {}) as any} />
                  </Box>
                  <Box sx={{
                        display: "grid",
                        gridAutoRows: "90px",
                        gridTemplateRows: "50px 40px auto",
                        gridTemplateColumns: "1fr"

                  }}>
                        <Typography sx={{ fontSize: "17px", fontWeight: "bold", margin: "20px" }} color="primary">Bill To</Typography>
                        <Box>


                              <FormGroup >
                                    <FormControlLabel control={<Checkbox onChange={handleCheckChange} checked={checked} />} label='Use shipping address as billing address' />
                              </FormGroup>
                        </Box>


                        <Form
                              onChange={onChange}
                              hasError={billHasError}
                              ref={billToRef as React.RefObject<Form>}
                              model={AddressForm2({ disabled: checked })}
                              values={checked ? JSON.parse(JSON.stringify(getFormValues(shipToRef as RefObject<Form>).formData)) : address[0]?.addressType === 'BILLING' ? address[0] : (address[1] || {}) as any} />
                  </Box>
                  <Box sx={{
                        display: "grid", gap: "10px", gridTemplateColumns: {
                              xs: "1fr",
                              sm: "1fr 1fr"
                        },
                        paddingBottom: "10px"
                  }}>
                        <PrimaryButton onClick={handleUpdate} >UPDATE</PrimaryButton>
                        <PrimaryButton onClick={handleReset} disabled={resetDisabled}>RESET</PrimaryButton>
                  </Box>

            </Box>

      )
}

export default Address;
