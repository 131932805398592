import { createSlice } from '@reduxjs/toolkit';
import { removeFromStorage, saveToStorage } from '../../storage';

interface initStateProps {
  promiseResponse: any;
  promisePayloadData: any;
  orderSummaryResponse: any;
  revisionId: any;
}
const createOrderSlice = createSlice({
  name: 'createOrderSlice',
  initialState: {
    promiseResponse: null,
    promisePayloadData: null,
    orderSummaryResponse: null,
    revisionId: null,
  },
  reducers: {
    setPromiseResponse(state, action) {
      state.promiseResponse = action.payload;
    },
    setPromisePayloadData(state, action) {
      state.promisePayloadData = action.payload;
    },
    setOrderSummaryResponse(state, action) {
      state.orderSummaryResponse = action.payload;
    },
    setRevisionId(state, action) {
      state.revisionId = action.payload;
    },
  },
});

export default createOrderSlice.reducer;

export const { setPromiseResponse, setPromisePayloadData, setOrderSummaryResponse, setRevisionId } =
  createOrderSlice.actions;

export const createOrderPromiseResponse = (state: { createOrderSlice: initStateProps }) =>
  state.createOrderSlice.promiseResponse;
export const createOrderPromisePayloadData = (state: { createOrderSlice: initStateProps }) =>
  state.createOrderSlice.promisePayloadData;
export const orderSummaryResponse = (state: { createOrderSlice: initStateProps }) =>
  state.createOrderSlice.orderSummaryResponse;
export const revisionId = (state: { createOrderSlice: initStateProps }) => state.createOrderSlice.revisionId;
