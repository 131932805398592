import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import PrimaryButton from 'components/Button/PrimaryButton';
import strings from 'common/Translation/Translate';
import { useTheme } from '@mui/system';
import { Grid, CircularProgress, AlertColor, useMediaQuery } from '@mui/material';
import Form, { FormDataModel } from 'components/Form';
import { LoginForm } from 'screens/Authentication/Login/LoginForm';
import { ChangePasswordModalForm } from './ChangePasswordModalForm';
import { URLS } from 'utils/constants/urls';
import { setAuthentication, setUser } from 'utils/redux';
import axiosInstance from 'utils/redux/axios';
import { setNotificationMessage } from 'utils/redux/reducer/Notification-details-slice';
import { removeErrorFieldsFromValues } from 'utils/validators';
import Notification from 'components/Notification';
import { config } from 'process';
import { useDispatch } from 'react-redux';
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { Scrollbar } from 'react-scrollbars-custom';

type ModalProps = {
  open: boolean;
  setOpen: any;
};

export const ChangePasswordModal: React.FC<ModalProps> = ({
  open,
  setOpen
}) => {
  const theme = useTheme();
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  
  const [message, setMessage] = React.useState<{
    display: boolean;
    severity: AlertColor | null;
    message: any;
  }>({
    display: false,
    severity: null,
    message: "",
  });
  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  let formRef: React.RefObject<Form | null | undefined> = React.useRef();

  const handleResetPassword = async (e: any) => {
    e.preventDefault();
    const { getFormData } = formRef.current as {
      getFormData: () => { formData: FormDataModel; isFormValid: boolean };
    };
    const { formData, isFormValid } = getFormData();
    const bodyData = removeErrorFieldsFromValues(formData);
    try {
      setHasError(false);
      if (isFormValid) {
        if (bodyData.newPassword !== bodyData.confirmPassword) {
          setMessage({
            display: true,
            severity: "error",
            message: strings.forgotPasswordLinkmatchPasswordError
          })
          return;
        }
        setLoading(true);
        const { status } = await axiosInstance.put(URLS.changePassword, bodyData);
        if (status === 200) {
          setLoading(false);
          onClose();
          setMessage({
            display: true,
            severity: "success",
            message: strings.resetPasswordSuccessText,
          })
        }
      } else {
        setHasError(true);
      }
    } catch (error: any) {
      setLoading(false);
      setMessage({
        display: true,
        severity: "error",
        message: error.response.data.message,
      })
    }
  };
  const onClose = () => {
    setOpen(false);
    setHasError(false);
    formRef?.current?.resetForm();
  }
  return (
    <>
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: screenSizeDownMd ? screenSizeDownSm ? "90vw" : '70vw' : '40vw',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 3,
          maxHeight: '90vh',
        }}>
          <Box display={'flex'} alignItems="center" justifyContent={'space-between'}>
            <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight={600} color={'primary'}>
              {strings.changePasswordText}
            </Typography>
            <ClearOutlinedIcon
              onClick={onClose}
              sx={{
                ":hover": { cursor: "pointer" },
                color: theme.palette.primary.main,
              }}
            />
          </Box>
          <Scrollbar style={{ width: '100%', height: '70vh' }}>
            <Grid
              container
              mt={1}
              spacing={1}
              px={1}
            >
              <Form
                hasError={hasError}
                ref={formRef as React.RefObject<Form>}
                model={ChangePasswordModalForm(strings)}
                values={{}}
              />
              <Grid item xs={12} md={6}>
                <PrimaryButton
                  disabled={loading}
                  sx={{ mt: 1, py: 1 }}
                  onClick={(e) => handleResetPassword(e)}
                >
                  {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
                  {strings.changePasswordLinkButton}
                </PrimaryButton>
              </Grid>
            </Grid>
          </Scrollbar>
        </Box>
      </Modal>
    </>
  );
}