import { FC } from 'react';
import Recaptcha from 'react-recaptcha';
// import { useSelector } from 'react-redux';
import { identifiers } from '../../utils/constants/identifiers';
// import { defaultLanguage } from '../../utils/redux/reducer/authentication-slice';

let recaptchaInstance: { reset: () => void };
interface CaptchaProps {
  verify: (response: string, captchaFlag: boolean) => void;
  testId?: string
}
const Captcha: FC<CaptchaProps> = ({ verify, testId }) => {
  // const language = useSelector(defaultLanguage);
  const verifyCallback = (response: any) => {
    verify(response, false);
  };
  const expiredCallback = () => {
    recaptchaInstance.reset();
    verify('', true);
  };
  return (
    <div className="captcha">
      <Recaptcha
        data-testid="captcha"
        hl={'en_IN'}
        data-test={testId}
        sitekey={identifiers.REACT_APP_CAPTCHA}
        render="explicit"
        size="normal"
        verifyCallback={verifyCallback}
        expiredCallback={expiredCallback}
        onloadCallback={() => {}}
      />
    </div>
  );
};

export default Captcha;
