export const routes: { [key: string]: string } = {
  root: '/',
  getRewardsPage: '/rewards',
  user_profile: '/user-profile',
  terms: '/terms',
  privacyPolicy: '/privacy-policy',
  about: '/about-us',
  contact: '/contact-us',
  productDetails: '/product-details',
  cancellation: '/cancellation-and-refund-policy',
  returnOrder: '/user-profile/return-order',
};
