import {
    Card,
    Typography,
    Grid,
    CardMedia,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Skeleton,
    useTheme,
    useMediaQuery,
    Input,
    AlertColor,
    TextField,
    Button,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import strings from "common/Translation/Translate";
import PrimaryButton from "components/Button/PrimaryButton";
import { Tabsection } from "./TabSection";
import axiosInstance from "utils/redux/axios";
import React, { RefObject, useEffect, useLayoutEffect, useState } from "react";
import homedelivery from "./images/homedelivery.png";
import pickup from "./images/newpickup.png";
import Form, { FormDataModel } from "components/Form";
import localdelivery from "./images/localdelivery.png";
import { AddBox } from "@mui/icons-material";
import Notification from "components/Notification";
import { URLS } from "utils/constants/urls";
import { removeErrorFieldsFromValues, Validators } from "utils/validators";
import { useSelector, useDispatch } from "react-redux";
import { Authenticated, User } from "utils/redux";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import PageLoader from "components/PageLoader";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import DropDownInput from "components/DropDownInput";
import { addCartItem, cartDrawerState, cartItems, changeQuantity, toggleDrawer, variantCodesAllProductsInCart } from "utils/redux/reducer/cart-slice";
import { setUserAddress, userAddress } from "utils/redux/reducer/address-slice";
import { handleBuyNow } from "./buynowhelpers";

type DetailsPageProps = {
    productDetails: any;
    quantity: any;
    setQuantity: any;
};
type VariantObjectType = {
    id: string;
    variantCode: string;
    quantity: string;
    name: string;
    price: number;
};

export const DetailsPage = ({ productDetails, quantity, setQuantity }: DetailsPageProps) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isLogin = useSelector(Authenticated);
    const screenSizeDownSm = useMediaQuery(theme.breakpoints.down("sm"));
    const [pincode, setPincode] = React.useState("");
    const [error, setError] = React.useState();
    let pincodeRef: React.RefObject<Form | null | undefined> = React.useRef();
    const [loader, setLoader] = React.useState<boolean>(false);
    const [variantObject, setVariantObject] = useState<VariantObjectType>({} as VariantObjectType);
    const cartLength = (useSelector(cartItems) || []).length;
    const alreadyInCart = (useSelector(variantCodesAllProductsInCart) || []).includes(variantObject.variantCode)
    const user = useSelector(User)
    const addressData = useSelector(userAddress)
    const [message, setMessage] = React.useState<{
        display: boolean;
        severity: AlertColor | null;
        message: any;
    }>({
        display: false,
        severity: null,
        message: "",
    });
    const [check, setCheck] = React.useState<{ fullfillable: any; promise_options: [] }>({
        fullfillable: "",
        promise_options: [],
    });

    const closeNotification = (value: boolean) => {
        setMessage({ ...message, display: value });
    };
    const [serviceable, setServiceable] = React.useState(false);
    const [quantityError, setQuantityError] = React.useState(false);
    const [info, setInfo] = React.useState<{
        error: any;
        type: string;
        message: string;
    }>({
        error: undefined,
        type: "",
        message: "",
    });
    const dispatch = useDispatch();


    React.useEffect(() => {
        const fetchAddress = async () => {
            setLoader(true);
            try {
                const result = await axiosInstance.get(URLS.address);
                if (result?.data.length) {
                    const data = result.data;
                    dispatch(setUserAddress(result.data))

                    let pincode = "";
                    if (data[0].addressType === "SHIPPING" && data[0].pinCode) {
                        pincode = data[0].pinCode;
                    } else if (data[1].addressType === "SHIPPING" && data[1].pinCode) {
                        pincode = data[1].pinCode;
                    }
                    if (pincode) {
                        setInfo({
                            error: false,
                            type: "",
                            message: "",
                        });
                    } else {
                        setInfo({
                            error: true,
                            type: "address",
                            message: "Update your address to see delivery options",
                        });
                    }
                    setPincode(pincode);
                } else {
                    setInfo({
                        error: true,
                        type: "address",
                        message: "Update your address to see delivery options",
                    });
                }
            } catch (error) {
                setInfo({
                    error: true,
                    type: "500",
                    message:
                        "We're sorry, but we encountered a system error. We recommend trying again later as we work to resolve the issue. If you need further assistance, please don't hesitate to contact our support team at manjunath@cloverybaytechnologies.com. Thank you for your patience and understanding. We apologize for any inconvenience caused.",
                });
            }
            setLoader(false);
        };
        if (isLogin) fetchAddress();
        else {
            setInfo({
                error: true,
                type: "login",
                message: "Please login to place order",
            });
            setCheck({
                fullfillable: false,
                promise_options: [],
            });
        }
    }, [isLogin]);

    React.useEffect(() => {
        const fetchServiceability = async () => {
            const body = {
                country_code: "CL",
                postal_code: pincode,
            };
            try {
                const result = await axiosInstance.post(URLS.pincodeCheck, body);

                if (result.status == 200 && result.data.location_serviceable) {
                    setServiceable(true);
                } else {
                    setInfo({
                        error: true,
                        type: "address",
                        message: "Your area is not serviceable. Please update your shipping address",
                    });
                }
            } catch (error) {
                setInfo({
                    error: true,
                    type: "500",
                    message:
                        "We're sorry, but we encountered a system error. We recommend trying again later as we work to resolve the issue. If you need further assistance, please don't hesitate to contact our support team at manjunath@cloverybaytechnologies.com. Thank you for your patience and understanding. We apologize for any inconvenience caused.",
                });
            }
            setLoader(false);
        };
        if (pincode) {
            setLoader(true);
            fetchServiceability();
        }
    }, [pincode]);

    useEffect(() => {
        const checkPincode = async () => {
            if (!pincode) return;
            const body = {
                requested_by: uuidv4(),
                channel: "ONLINE",
                currency_code: "CL",
                ship_to_address: {
                    address_id: "A1",
                    phone_numbers: [
                        {
                            type: "PERSONAL",
                            country_code: "IN",
                            number: "+91 00000 00000",
                        },
                    ],
                    country_code: "CL",
                    postal_code: pincode,
                },
                product: {
                    sku_id: variantObject.variantCode,
                    quantity: {
                        number_of_units: quantity,
                        unit_size: "1",
                        unit_size_fraction: "1",
                        unit_of_measure: "EA",
                    },
                },
            };
            try {
                setInfo(
                    {
                        error: undefined,
                        type: "",
                        message: "",
                    }
                )
                setCheck({
                    fullfillable: undefined,
                    promise_options: [],
                });

                const result = await axiosInstance.post(URLS.pdp, body);
                if (result.status === 200 && result.data && result.data?.fulfillable_state === true) {
                    result.data.promise_groups[0].promise_options.map((item: any) => {
                        if (item.delivery_method === "HOME_DELIVERY") {
                            item.delivery_method = "HOME DELIVERY";
                            item.image = homedelivery;
                            return item;
                        } else if (item.delivery_method === "PICK_UP") {
                            item.delivery_method = "PICK UP";
                            item.image = pickup;
                            return item;
                        } else if (item.delivery_method === "LOCAL_DELIVERY") {
                            item.delivery_method = "HOME DELIVERY";
                            item.image = localdelivery;
                            return item;
                        }
                    });
                    dispatch(changeQuantity({
                        variantCode: variantObject.variantCode,
                        quantity: quantity,
                    }));
                    setCheck({
                        fullfillable: true,
                        promise_options: result.data.promise_groups[0].promise_options,
                    });
                    const quant = result?.data?.promise_groups?.[0].lines?.[0].available_to_promise?.number_of_units ?? 0;
                    if (quant < 50 && quant > 0) {
                        setInfo({
                            error: false,
                            type: "hurry",
                            message: "Hurry! Limited Stock Remaining!",
                        });
                    }
                } else {
                    if (result.data.promise_groups[0].promise_options.length == 0) {
                        setInfo({
                            error: true,
                            type: "pincode",
                            message: "We're sorry, this product is not available for delivery to your area. ",
                        });
                    } else {
                        if (result.data.promise_groups[0]?.promise_options.length) {
                            const quant = result?.data?.promise_groups?.[0].lines?.[0].available_to_promise?.number_of_units ?? 0;
                            if (quant === 0) {
                                setInfo({
                                    error: true,
                                    type: quantity,
                                    message: " Currently Out of Stock!",
                                });
                            } else if (quantity > quant) {
                                setInfo({
                                    error: true,
                                    type: quantity,
                                    message: `Quantity exceeds available stock (Available stock:${quant}). Please adjust your selection!`,
                                });
                            }
                        }
                    }
                }
            } catch (errors) {
                setInfo({
                    error: true,
                    type: "500",
                    message:
                        "We're sorry, but we encountered a system error. We recommend trying again later as we work to resolve the issue. If you need further assistance, please don't hesitate to contact our support team at manjunath@cloverybaytechnologies.com. Thank you for your patience and understanding. We apologize for any inconvenience caused.",
                });
            }
            setLoader(false);
        };
        if (serviceable && !quantityError && pincode && productDetails?.variants) {
            setLoader(true);
            checkPincode();
        }
    }, [pincode, productDetails, serviceable, quantityError, quantity, variantObject]);

    const handlePincode = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCheck({ fullfillable: "", promise_options: [] });
        if (e.target.value.length > 7) {
            e.target.value = e.target.value.slice(0, 7);
        }
        if (/[a-zA-Z]/.test(e.target.value)) return;
        setPincode(e.target.value);
    };

    useEffect(() => {
        if (productDetails.variants) {
            setVariantObject(productDetails.variants[0]);
        }
    }, [productDetails]);

    const handleQuantityChange = (sign: string) => {
        if (sign == "+" && quantity + 1 > variantObject.quantity) {
            setQuantityError(true);
            return;
        }
        if (sign == "-" && quantity > 1) {
            setQuantity(quantity - 1);
        } else if (sign == "+" && quantity < variantObject.quantity) {
            setQuantity(quantity + 1);
        }
        setQuantityError(false);
    };
    const handleNavigate = () => {
        navigate("/user-profile");
    };
    const handleVariantChange = (value: string) => {
        const obj = productDetails.variants.find((item: any) => item.variantCode === value);
        setVariantObject(obj);
        setQuantity(1);
    };
    return (
        <>
            {loader && <PageLoader />}
            {message.display && (
                <Notification
                    isOpen={message.display}
                    message={message.message}
                    severity={message.severity as AlertColor}
                    closeNotification={closeNotification}
                />
            )}
            {Object.keys(productDetails).length > 0 ? (
                <>
                    <Card elevation={10} sx={{ px: screenSizeDownSm ? 2 : 8, py: 2, m: screenSizeDownSm ? 2 : 4 }}>
                        <Typography variant="h5" fontWeight={"bold"}>
                            {productDetails.productName}
                        </Typography>
                        <Grid container p={3} rowSpacing={2} columnSpacing={10}>
                            <Grid item xs={12} md={6}>
                                <Box border={`1px solid ${theme.palette.secondary.dark}`} py={2}>
                                    <CardMedia
                                        component="img"
                                        sx={{
                                            width: "100%",
                                            objectFit: "contain",
                                            height: "180px",
                                        }}
                                        image={productDetails.imageUrl}
                                        alt={strings.noImageAvailableText}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2" my={1} sx={{ fontSize: "16px" }}>
                                                <b>Price: </b>
                                                {`${"$"}${variantObject.price}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{ display: "flex", gap: 1 }}>
                                                {productDetails && productDetails?.variants.length > 1 && (
                                                    <DropDownInput
                                                        sx={{ fontWeight: "bold", fontSize: "16px" }}
                                                        options={productDetails.variants.map((item: any) => ({
                                                            label: item.name,
                                                            value: item.variantCode,
                                                        }))}
                                                        textChange={handleVariantChange}
                                                        inputValue={`${variantObject.variantCode}`}
                                                    />
                                                )}
                                                {productDetails && productDetails?.variants.length == 1 && (
                                                    <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
                                                        {variantObject.name}{" "}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{ display: "flex", gap: 1 }}>
                                                <RemoveCircleOutlineOutlinedIcon onClick={() => handleQuantityChange("-")} fontSize="large">
                                                    -
                                                </RemoveCircleOutlineOutlinedIcon>
                                                <Typography sx={{ fontSize: "18px", fontWeight: "bold", paddingTop: "2px" }}>
                                                    {quantity}
                                                </Typography>
                                                <AddCircleOutlineOutlinedIcon fontSize="large" onClick={() => handleQuantityChange("+")}>
                                                    +
                                                </AddCircleOutlineOutlinedIcon>
                                            </Box>
                                            {quantityError && (
                                                <Typography sx={{ color: "red", marginTop: "10px " }}>Maximum quantity reached.</Typography>
                                            )}
                                        </Grid>
                                        <Box display={'grid'} sx={{
                                            gridTemplateColumns: {
                                                xs: "1fr",
                                                sm: "1fr 1fr"
                                            },
                                            width: "100%",
                                            marginLeft: "26px",
                                            gap: "20px",
                                            marginTop: "10px"
                                        }}>
                                            <Box >
                                                {!alreadyInCart ? <PrimaryButton
                                                    variant="contained"
                                                    sx={{ backgroundColor: theme.palette.primary.main }}
                                                    onClick={() => {
                                                        let qty = {
                                                            sku_id: variantObject.variantCode,
                                                            quantity: {
                                                                number_of_units: quantity,
                                                                unit_size: "1",
                                                                unit_size_fraction: "1",
                                                                unit_of_measure: "EA",
                                                            },
                                                        };
                                                        dispatch(addCartItem({
                                                            brand: productDetails.brand,
                                                            category: productDetails.category,
                                                            productCode: productDetails.productCode,
                                                            productName: productDetails.productName,
                                                            price: variantObject.price,
                                                            imageUrl: productDetails.imageUrl,
                                                            id: productDetails.id,
                                                            variantCode: variantObject.variantCode,
                                                            variantId: variantObject.id,
                                                            quantity: quantity,
                                                            maxQuantity: variantObject.quantity,
                                                            variantName: variantObject.name,
                                                            co_line_id: uuidv4(),
                                                        }));
                                                        // placeOrderFunc(variantObject.id, quantity, pincode, qty);
                                                    }}
                                                    disabled={check.fullfillable ? false : true}
                                                >
                                                    Add to Cart
                                                </PrimaryButton> :
                                                    <PrimaryButton sx={{ backgroundColor: theme.palette.primary.main }}
                                                        onClick={() => dispatch(toggleDrawer())}

                                                    >
                                                        Go To Cart
                                                    </PrimaryButton>
                                                }
                                            </Box>
                                            <Box >
                                                <PrimaryButton sx={{ backgroundColor: theme.palette.primary.main }} disabled={check.fullfillable ? false : true} onClick={
                                                    () => {
                                                        handleBuyNow({
                                                            dispatch,
                                                            user,
                                                            addressData,
                                                            item: [{
                                                                brand: productDetails.brand,
                                                                category: productDetails.category,
                                                                productCode: productDetails.productCode,
                                                                productName: productDetails.productName,
                                                                price: variantObject.price,
                                                                imageUrl: productDetails.imageUrl,
                                                                id: productDetails.id,
                                                                variantCode: variantObject.variantCode,
                                                                variantId: variantObject.id,
                                                                quantity: quantity,
                                                                maxQuantity: variantObject.quantity,
                                                                variantName: variantObject.name,
                                                                co_line_id: 1,
                                                            }],
                                                            setLoader,
                                                        })
                                                    }
                                                }>Buy Now</PrimaryButton>
                                            </Box>

                                        </Box>
                                        <Grid item xs={12}>
                                            <Box sx={{ display: "grid", gridTemplateColumns: "max(250px)", gap: 2, justifyContent: "end" }}>
                                                {pincode && (
                                                    <TextField
                                                        onChange={handlePincode}
                                                        value={pincode}
                                                        size="small"
                                                        InputProps={{ readOnly: true }}
                                                        label="Postal Code"
                                                    />
                                                )}
                                            </Box>
                                            {info.error && (
                                                <Typography sx={{ fontSize: "16px", marginTop: "10px", color: "red" }}>
                                                    {info.message}{" "}
                                                    <span>
                                                        {info.type === "address" && (
                                                            <Button sx={{ color: "green" }} onClick={handleNavigate}>
                                                                Update Address
                                                            </Button>
                                                        )}
                                                    </span>
                                                </Typography>
                                            )}
                                            {(!info.error) && info.type == "hurry" && (
                                                <Typography sx={{ fontSize: "14px", marginTop: "10px" }}>{info.message}</Typography>
                                            )}
                                            {check.fullfillable === true &&
                                                check.promise_options.map((item: any) => <PromiseOptions options={item} />)}
                                        </Grid>
                                    </Grid>
                                </>
                            </Grid>
                        </Grid>
                    </Card>
                    <Box sx={{ px: screenSizeDownSm ? 2 : 8, py: 2, m: screenSizeDownSm ? 2 : 4 }}>
                        <Tabsection productDetails={productDetails} />
                    </Box>
                </>
            ) : (
                <Stack spacing={1} m={4}>
                    <Skeleton variant="rectangular" width={"100%"} height={500} />
                </Stack>
            )}
        </>
    );
};

const PromiseOptions = ({ options }: { options: any }) => {
    const getDate = (date: any = "") => {
        let dateObject = new Date(date);
        return dateObject.toISOString().split("T")[0];
    };

    return (
        <Box
            sx={{
                backgroundColor: "#f0f0f0",
                borderRadius: "10px",
                padding: "10px",
                margin: "10px",
                display: "flex",
                gap: 2,
                maxHeight: "100px",
            }}
        >
            {" "}
            <img height="30px" width="30px" src={options.image} className="promise_image" alt="image" />
            <Box>
                <Typography sx={{ fontWeight: "bold" }}> {(options.delivery_method || "")}</Typography>
                <Typography>{(options.delivery_speed || "").replaceAll("_", " ")}</Typography>
                {options.delivery_method !== "PICK UP" ? (
                    <Typography>
                        Delivery Charge:
                        {options?.delivery_cost?.amount
                            ? `$${options.delivery_cost.amount / (options.delivery_cost.fraction || 1)}`
                            : 'Free'}
                    </Typography>
                ) :
                   <Typography > Pickup From : {options.ship_node_name} store</Typography>
                }


                {options.delivery_method !== "PICK UP" ? <Typography>
                    Estimated Delivery Date : {new Date(options.promise_delivery_date).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })
                    }
                </Typography> : <Typography>
                    Estimated Pickup Date : {new Date(options.promise_delivery_date).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })
                    }
                </Typography>


                }

            </Box>
        </Box>
    );
};
