import { FC } from 'react';
import Drawer from '@mui/material/Drawer';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import DrawerTopElement from '../../assets/images/DrawerTopElement';
import DrawerBottomElement from '../../assets/images/DrawerBottomElement';
import HeaderBackground from 'assets/images/HeaderBackground';

type DrawerProps = {
  open: boolean;
  handleClose: () => void;
  children: any;
  width?: number | string;
  dropBack?: boolean;
  drawerState?: any
};
const DrawerMenu: FC<DrawerProps> = ({ open, handleClose, children, dropBack = true, width = 500, drawerState }) => {
  const theme = useTheme();
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Drawer
      PaperProps={{
        sx: {
          width: width,
        },
      }}
      sx={{ zIndex: "10" }}
      anchor="right"
      open={open}
      onClose={dropBack ? handleClose : () => { }}>
      <Box height={'100vh'} sx={{ backgroundColor: drawerState && drawerState === 3 ? theme.palette.secondary.light : 'white' }}>
        <Box position={'relative'}>
          {/* <span style={{
            overflow: 'hidden',
            position: 'absolute',
          }} > */}
          <HeaderBackground height={'85px'} width={'100%'} />
          {/* </span> */}
          <span style={{
            position: 'absolute',
            top: '-8px',
            left: '-8px',
          }} >
            <DrawerTopElement />
          </span>
        </Box>
        <Box mt={2}>


          {children}

          <span style={{
            height: '120px',
            position: 'fixed',
            bottom: '-30px',
            right: '-20px',
            borderTopLeftRadius: '10rem', overflow: 'hidden'
          }} >
            <DrawerBottomElement />
          </span>
        </Box>
      </Box>
    </Drawer>
  );
};

export default DrawerMenu;
