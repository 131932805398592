import { FC, useState } from "react";
import { validateInput } from "../../utils/validators/Validators";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import {
  FormHelperText,
  Stack,
  FormControlProps,
  Button,
} from "@mui/material";
import { FormValidators } from "../Form";
import strings from "common/Translation/Translate";


interface OwnProps {
  field?: string;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  typeValue?: string;
  validators?: FormValidators[];
  hasError?: boolean;
  fieldError?: string | boolean;
  label?: string;
  defaultValue?: any,
  labelId?: string,
  options?: [],
}

const RadioInput: FC<OwnProps & FormControlProps<any, any>> = ({
  hasError,
  validators,
  onChange,
  field,
  inputValue,
  options,
  fieldError,
  inputProps,
  className,
  label,
  style,
  defaultValue,
  size,
  disabled,
  labelId,
  ...rest
}) => {
  const [error, setError] = useState<{ error: String, message: String }>();
  // const [selectedValue, setSelectedValue] = useState(inputValue as any || '');
  // useEffect(() => {
  //   setSelectedValue(selectedValue);
  // }, [selectedValue]);
  const handleChange = (item: any) => {
    const inputError = validateInput(validators, item.value);
    setError(inputError);
    onChange(item.value, field, inputError);
  };
  const customMessage = () => !error && hasError && fieldError ? strings.requiredField: ""
  return (
    <div>
      <Stack direction="row" spacing={2}>
        {options?.length > 0 && options?.map((item: any, index: number) => (
          <Button key={item.value} {...rest} color={item.value === inputValue ? 'primary' : 'inherit'} onClick={() => handleChange(item)} variant="text" startIcon={item.value === inputValue ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}>
            {item.label}
          </Button>
        ))}
      </Stack>
      <FormHelperText>
        {error && error.error  ? error.message : customMessage()}
      </FormHelperText>
    </div>
  );
};
export default RadioInput;
