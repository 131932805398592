import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { Card, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, TextareaAutosize, Typography, useMediaQuery } from "@mui/material";
import { Box, display, useTheme } from "@mui/system";
import strings from "common/Translation/Translate";
import PrimaryButton from "components/Button/PrimaryButton";
import DrawerMenu from "components/DrawerMenu";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { URLS } from "utils/constants/urls";
import { Authenticated } from "utils/redux";
import axiosInstance from "utils/redux/axios";
import { getDrawerWidth } from "utils/validators/HelperFunctions";
import Replacement from './Replacement';
import PageLoader from "components/PageLoader";
import { userAddress } from "utils/redux/reducer/address-slice";
import { User } from "utils/redux/reducer/authentication-slice";
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';
import Refund from './Refund'
const ReasonToReturn = [
      {
            id: 1,
            reason: 'Wrong Item Recived',
            possibility: ['Refund']
      },
      {
            id: 2,
            reason: `Item does'nt match as described`,
            possibility: ['Refund']
      },
      {
            id: 3,
            reason: `Defective Or Damage Item`,
            possibility: ['Refund', 'Replacement']
      },
      {
            id: 4,
            reason: `Size/Fit Issue`,
            possibility: ['Refund', 'Replacement']
      },
      {
            id: 5,
            reason: `Changed Mind/Not Needed`,
            possibility: ['Refund']
      },
      {
            id: 6,
            reason: `Unsatisfactory Quality`,
            possibility: ['Refund', 'Replacement']
      },
      {
            id: 7,
            reason: `Late Delivery`,
            possibility: ['Refund']
      },
      {
            id: 8,
            reason: `Duplicate Order`,
            possibility: ['Refund']
      },
      {
            id: 9,
            reason: `Recived Wrong Quantity`,
            possibility: ['Refund']
      },
      {
            id: 10,
            reason: `Gift Return`,
            possibility: ['Refund']
      },
      {
            id: 11,
            reason: `Price discrepancy`,
            possibility: ['Refund']
      },
      {
            id: 12,
            reason: `Ordered the wrong item`,
            possibility: ['Refund']
      },
      {
            id: 13,
            reason: `Product doe'nt work as expected`,
            possibility: ['Refund']
      },
      {
            id: 14,
            reason: `Change in Circumstance`,
            possibility: ['Refund']
      },
      {
            id: 15,
            reason: `Product Expired`,
            possibility: ['Refund']
      },
      {
            id: 16,
            reason: `Missing Accessories`,
            possibility: ['Refund', 'Replacement']
      },
      {
            id: 17,
            reason: `Recived wrong color`,
            possibility: ['Refund']
      },
      {
            id: 18,
            reason: `Not as Advertised`,
            possibility: ['Refund']
      },
      {
            id: 19,
            reason: `Not Satisfactory`,
            possibility: ['Refund']
      },
      {
            id: 20,
            reason: `Others`,
            possibility: ['Refund']
      }
]


// const data = {
//       orderNumber: obj.orderNumber,
//       promiseId: obj.promiseId,
//       posNo: obj?.customerOrder?.posNo,
//       storeId: obj.customerOrder.storeId,
//       orderLineId: obj?.orderLineId,
//       products: [
//           {
//               productCode: obj.productCode,
//               variantCode: obj.variantCode,
//               quantity: obj.quantity,
//               price: obj.price,
//               orderedQuantity: obj.orderedQuantity,
//               productName: obj.productName,
//               variantName: obj.variantName,
//           }
//       ]
//   }
const ReturnOrder = ({
      openDrawer,
      onDrawerClose,
      data,

}: {
      openDrawer: boolean;
      onDrawerClose: () => void;
      data: any
}) => {
      const theme = useTheme();
      const isLogin = useSelector(Authenticated);
      const dispatch = useDispatch();
      const navigate = useNavigate();
      const [ResonData, setReasonData] = React.useState(ReasonToReturn as []);
      const screenSizeDownXl = useMediaQuery(theme.breakpoints.down("xl"));
      const screenSizeDownLg = useMediaQuery(theme.breakpoints.down("lg"));
      const screenSizeDownMd = useMediaQuery(theme.breakpoints.down("md"));
      const screenSizeDownSm = useMediaQuery(theme.breakpoints.down("sm"));
      const screenSizeUpLg = useMediaQuery(theme.breakpoints.up("lg"));
      const screenSizeUpMd = useMediaQuery(theme.breakpoints.up("md"));
      const screenSizeUpSm = useMediaQuery(theme.breakpoints.up("sm"));
      const [selectedReason, setSelectedReason] = React.useState('');
      const [userNeeds, setUserNeeds] = React.useState('');
      const [showStatus, setShowStatus] = React.useState([] as any);
      const [selectdRefund, setSelectedRefund] = React.useState(0);
      const [fulfillmentOption, setFulfilmentOptions] = React.useState('collect_from_pickup')
      const [pickUpModal, setPickUpModal] = React.useState(false)
      const [collectModal, setCollectModal] = React.useState(false)
      const [returnProducts, setReturnProducts] = React.useState<any>([]);
      const [returnOrderAmount, setReturnOrderAmount] = React.useState(0)
      const [otherReason, setOtherReason] = React.useState('');
      const [additionalComment, setAdditionalComment] = React.useState('');
      const drawerRef = useRef(null);
      const [returnValue, setReturnValue] = React.useState('drop');
      const [returnOrder, setReturnOrder] = React.useState(false)
      const [loader, setLoader] = React.useState<boolean>(false);
      const userAdd = useSelector(userAddress)
      const userData = useSelector(User);
      const [hashAvail, setHashAvail] = React.useState('')
      const [fulfillmentOptionResp, setFulfillmentOptionsResp] = React.useState<any>({})
      const [displayButtons, setDisplayButtons] = React.useState(false as boolean)
      const [promiseResp, setPromiseResp] = React.useState(null as any)
      const [dataItemList, setDataItemList] = React.useState(null as any)
      const [commitStatus, setCommitStatus] = React.useState('OPEN' as string)
      const [promisePayLoadData, setPromisePayLoadData] = React.useState(null as any)
      const [address, setAddress] = React.useState([] as any);
      const [selectedReturnReason, setSelectedReturnReason] = React.useState<any>(0);
      console.log(returnProducts)


      const fetchProductDataForImage = async (productCode: any) => {
            const result = await axiosInstance.get(URLS.productWithProductCode(productCode));
            if (result.status === 200) {
                  return { productCode, imageUrl: result.data.imageUrl };
            } else {
                  throw new Error(`Failed to fetch data for product code: ${productCode}`);
            }
      };


      useEffect(() => {
            const getProductImage = async () => {
                  const productDataPromises = data.products.map((item: any) => fetchProductDataForImage(item.productCode));
                  // Wait for all the promises to resolve
                  const results = await Promise.all(productDataPromises);

                  const updatedProducts = data.products.map((previousVersion: any) => {
                        const imageResult = results.find((apiData: any) => previousVersion.productCode === apiData.productCode);
                        if (imageResult) {
                              return { ...previousVersion, imageUrl: imageResult.imageUrl };
                        } else {
                              return previousVersion
                        }
                  });
                  setReturnProducts(updatedProducts)


                  const amount = data.products.reduce((acc: any, b: any) => acc + (Number(b.quantity) * Number(b.amount)), 0)
                  setReturnOrderAmount(amount * (-1))
            }
            if (data.products)
                  getProductImage();

      }, [data])

      useEffect(() => {
            if (Object.keys(userAdd).length > 0 && openDrawer && returnProducts.length)
                  callingPromise();
      }, [userAdd, openDrawer, returnProducts])
      const getHash = (pinCode: any, itemList: any, addressData: any) => {
            if (hashAvail === '') {
                  let shipToPostCode = pinCode
                  let countryCode = "CL"
                  let productString = JSON.stringify(itemList)
                  let convertHeaderData = shipToPostCode + countryCode + productString
                  let hashCrypted = CryptoJS.MD5(convertHeaderData).toString();
                  setHashAvail(hashCrypted)
                  return hashCrypted
            } else {
                  return hashAvail
            }
      }

      const callingPromise = async () => {
            setLoader(true)

            const hashData = getHash(userAdd[0].pinCode, returnProducts, userAdd)
            let bodyData = {
                  cart_id: data.orderNumber,
                  is_exchange: "true",
                  order_type: "RTN",
                  parent_promise_id: data.promiseId,
                  channel: 'ONLINE',
                  transaction_start_datetime: new Date().toISOString(),
                  source_trigger: 'CART',
                  currency_code: 'CLP',
                  customer_profile_info: {
                        "profile_id": userData?.email,
                        "profile_type": "CONSUMER"
                  },
                  store_id: "ejje-dotcom-001",
                  pos_id: '1',
                  products: returnProducts.map((item: any) => ({
                        co_line_id: uuidv4(),
                        "quantity": {
                              "number_of_units": item?.quantity,
                              "unit_size": "1",
                              "unit_size_fraction": "1",
                              "unit_of_measure": "EA"
                        },
                        sku_id: item.variantCode,
                        return_parent_co_line_id: item.orderLineId
                  })),
                  pickup_address: {
                        "address_id": userAdd[0]?.addressType == "SHIPPING" ? userAdd[0]?.addressId : userAdd[1]?.addressId,
                        "phone_numbers": [
                              {
                                    "type": "PERSONAL",
                                    "country_code": "CL",
                                    "number": "1212121212"
                              }
                        ],
                        "country_code": "CL",
                        "postal_code": userAdd[0]?.addressType == "SHIPPING" ? userAdd[0]?.pinCode : userAdd[1]?.pinCode,
                  },
            };



            try {
                  const config = {
                        headers: {
                              'revision': hashData,
                        },
                  };
                  const { status, data } = await axiosInstance.post(URLS.promise, bodyData, config as any)
                  if (status === 201) {
                        setPromiseResp(data)


                  }
            } catch (err: any) {
                  setLoader(false)

            }
            setLoader(false)
      }

      useEffect(() => {
            if (drawerRef.current) {
                  const scrollableElement: any = drawerRef.current;
                  if (scrollableElement instanceof HTMLElement) {
                        scrollableElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
                  }
            }
      }, [selectedReturnReason, returnOrder, commitStatus, userNeeds, selectdRefund, fulfillmentOptionResp])

      const handleChangeReason = (event: any, dataNum: any) => {
            let numData = Number(dataNum)
            setSelectedReturnReason(numData);
            let selectionData = ResonData[numData - 1]
            setShowStatus(selectionData)
            setSelectedRefund(0)

      }

      const handleuserNeedChange = (event: any) => {
            setUserNeeds(event?.target?.value)

      }
      const onReturnOrderClose = () => {
            onDrawerClose();
            setSelectedReturnReason(0);
            setUserNeeds("")
      }

      return (
            <>    {loader && <PageLoader />}
                  <DrawerMenu
                        open={openDrawer}
                        handleClose={onReturnOrderClose}
                        width={getDrawerWidth(screenSizeDownSm, screenSizeDownMd, screenSizeUpLg)}
                  >
                        <Box ref={drawerRef}>
                              <Box display={"flex"} alignItems="center" justifyContent={"space-between"} px={3} py={2} >
                                    <Box width="100%">
                                          <Typography id="modal-modal-title" variant="h5" fontWeight={600} color={"primary"}>
                                                Return Order
                                          </Typography>
                                    </Box>
                                    <ClearOutlinedIcon
                                          onClick={onReturnOrderClose}
                                          sx={{
                                                ":hover": { cursor: "pointer" },
                                                color: theme.palette.primary.main,
                                          }}
                                    />
                              </Box>
                              <Typography style={{ fontSize: '17px', margin: "0px 20px 20px 24px", backgroundColor: 'rgba(255, 0, 0, 0.3)', height: "50px", lineHeight: "50px", verticalAlign: 'center', paddingLeft: "10px" }}><b>Note : </b>Development In Progress</Typography>


                              <Divider
                                    sx={{
                                          mx: 3,
                                          borderStyle: "dashed",
                                          borderColor: theme.palette.secondary.main,
                                    }}
                              />

                              <Grid container columnSpacing={4} px={3} py={1.5}>
                                    <Grid item xs={12}>
                                          <Grid container rowSpacing={4} gridTemplateColumns={"1fr"} justifyItems={"center"} py={1.5} >
                                                <Card sx={{ width: '100%' }}>
                                                      <Typography fontWeight={600} sx={{ margin: "10px", paddingLeft: "10px" }}>Original Order Details</Typography>
                                                      <Divider
                                                            sx={{
                                                                  mb: 1,
                                                                  borderStyle: "solid",
                                                                  borderColor: theme.palette.secondary.main,
                                                            }}
                                                      />
                                                      <Grid item xs={12} sm={12} md={12} sx={{ paddingLeft: "20px", margin: "10px" }}>
                                                            <Box sx={{ display: "flex" }}>

                                                                  <Typography>Parent Order Number :</Typography>
                                                                  <Typography>{data.orderNumber}</Typography>
                                                            </Box>
                                                      </Grid>
                                                </Card>
                                          </Grid>

                                          <Card sx={{ py: 2, mt: 2 }}>
                                                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={"100%"} >
                                                      <Box display={"flex"} alignItems={"center"}>
                                                            <Typography fontWeight={600} sx={{ margin: "10px", paddingLeft: "10px" }}>Return Item &nbsp;</Typography>
                                                      </Box>
                                                </Box>
                                                <Divider
                                                      sx={{
                                                            mb: 1,
                                                            borderStyle: "solid",
                                                            borderColor: theme.palette.secondary.main,
                                                      }}
                                                />
                                                <Box sx={{ p: 2 }}>
                                                      {returnProducts?.map((i: any, index: number) => (
                                                            <div key={index}>
                                                                  <Box display={"flex"} gap={1}>
                                                                        <img src={i.imageUrl} height={"40px"} alt="image" />
                                                                        <Typography fontWeight={600}>
                                                                              {i.productName} - {i.productCode}
                                                                        </Typography>
                                                                  </Box>
                                                                  <Box display={"flex"} justifyContent="space-between" mx={1} mt={1}>
                                                                        <Typography>
                                                                              {i.variantName} * {i.quantity}
                                                                        </Typography>
                                                                        <Typography>-{i.amount}</Typography>
                                                                  </Box>
                                                            </div>
                                                      ))}
                                                      <Divider
                                                            sx={{
                                                                  my: 1,
                                                                  borderStyle: "dashed",
                                                                  borderColor: theme.palette.secondary.main,
                                                            }}
                                                      />
                                                      <Box display={"flex"} justifyContent="space-between" mx={1}>
                                                            <Typography color={"primary"} fontWeight={600} flex={5} mr={1}>
                                                                  {strings.totalText}
                                                            </Typography>
                                                            <Typography color={"primary"} fontWeight={600} flex={4} textAlign={"end"}>
                                                                  {(returnOrderAmount || 0).toFixed(2)}
                                                            </Typography>
                                                      </Box>
                                                </Box>
                                          </Card>

                                          <Card sx={{ py: 2, mt: 2 }}>
                                                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
                                                      <Box display={"flex"} alignItems={"center"}>
                                                            <Typography fontWeight={600} sx={{ margin: "10px", paddingLeft: "10px" }}> Select a Reason &nbsp;</Typography>
                                                      </Box>
                                                </Box>
                                                <Divider
                                                      sx={{
                                                            mb: 1,
                                                            borderStyle: "solid",
                                                            borderColor: theme.palette.secondary.main,
                                                      }}
                                                />
                                                <Box sx={{ maxHeight: '250px', overflow: 'auto', p: 2, mb: 2 }}>
                                                      <FormControl>
                                                            <RadioGroup
                                                                  name="controlled-reason"
                                                                  value={selectedReturnReason}
                                                                  onChange={handleChangeReason}
                                                            >
                                                                  {ResonData.map((data: any) => {
                                                                        return (
                                                                              <div>
                                                                                    <FormControlLabel value={data.id} control={<Radio />} label={data.reason} />
                                                                              </div>
                                                                        )
                                                                  })}
                                                            </RadioGroup>
                                                      </FormControl>
                                                      <Box height="30px">
                                                            {
                                                                  selectedReturnReason === 20 ? <Box>
                                                                        <TextField id="outlined-basic" placeholder="Enter Reason" variant="outlined" size="small" fullWidth onChange={(e) => setOtherReason(e.target.value)} value={otherReason} />
                                                                  </Box> : ""
                                                            }
                                                      </Box>
                                                </Box>
                                                {selectedReturnReason !== 0 && <Box>
                                                      <Divider
                                                            sx={{
                                                                  mb: 1,
                                                                  borderStyle: "solid",
                                                                  // borderColor: theme.palette.secondary.main,
                                                            }}
                                                      />
                                                      <TextareaAutosize
                                                            maxLength={100}
                                                            name="description"
                                                            placeholder="Additional Comment (maximum 100 characters)"
                                                            value={additionalComment}
                                                            onChange={(e: any) => {
                                                                  setAdditionalComment(e.target.value);
                                                                  // setError(false);
                                                            }}
                                                            style={{
                                                                  minHeight: "100px",
                                                                  width: "100%",
                                                                  resize: "none",
                                                                  padding: "5px",
                                                                  fontFamily: "Roboto",
                                                                  fontSize: "14px",
                                                                  lineHeight: "21px",
                                                                  // borderColor: error ? "red" : "",
                                                            }}
                                                      />
                                                </Box>}
                                          </Card>

                                          {selectedReturnReason !== 0 && <Card sx={{
                                                py: 2, mt: 2
                                          }}>
                                                {showStatus && <Box style={{ marginTop: '5px' }}>
                                                      <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={"100%"} >
                                                            <Box display={"flex"} alignItems={"center"}>
                                                                  <Typography fontWeight={600} sx={{ margin: "10px", paddingLeft: "10px" }}> Return Method&nbsp;</Typography>

                                                            </Box>

                                                      </Box>
                                                      <Divider
                                                            sx={{
                                                                  mb: 1,
                                                                  borderStyle: "solid",
                                                                  borderColor: theme.palette.secondary.main,
                                                            }}
                                                      />
                                                      <FormControl sx={{ p: 2 }}>
                                                            <RadioGroup
                                                                  name="controlled-needs"
                                                                  value={userNeeds}
                                                                  onChange={handleuserNeedChange}
                                                                  sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}
                                                            >
                                                                  {showStatus?.possibility?.map((item: any) => {
                                                                        return (
                                                                              <Box>

                                                                                    <FormControlLabel value={item} control={<Radio />} label={item} />


                                                                              </Box>
                                                                        )
                                                                  })}
                                                            </RadioGroup>
                                                      </FormControl >


                                                </Box>}
                                          </Card>}



                                          {<Box>
                                                {selectedReturnReason !== 0 && <Box sx={{ width: '100%' }}>
                                                      {
                                                            userNeeds === 'Refund' ?
                                                                  <Refund
                                                                        key={userNeeds as unknown as React.Key}
                                                                        orderNumber={data?.orderNumber}
                                                                        returnProducts={returnProducts}
                                                                        returnOrderAmount={returnOrderAmount}
                                                                        promiseResp={promiseResp}
                                                                        selectedReturnReason={selectedReturnReason}
                                                                        additionalComment={additionalComment}

                                                                  /> : <Box>
                                                                        {userNeeds === 'Replacement' && <Replacement returnProducts={returnProducts}
                                                                              promiseResp={promiseResp}
                                                                              setDataItemList={setDataItemList}
                                                                              dataItemList={dataItemList}
                                                                              key={(userNeeds as unknown) as React.Key} returnOrderAmount={returnOrderAmount} />}
                                                                  </Box>

                                                      }
                                                </Box>}
                                          </Box>}
                                    </Grid>
                              </Grid>
                        </Box>
                  </DrawerMenu>
            </>
      );
};

export default ReturnOrder;
