import { Button, Card, CardMedia, Chip, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Typography, useMediaQuery } from '@mui/material';
import { Box, Stack, useTheme } from '@mui/system';
import InputField from 'components/InputField';
import PageLoader from 'components/PageLoader';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { URLS } from 'utils/constants/urls';
import axiosInstance from 'utils/redux/axios';
import { setNotificationMessage } from 'utils/redux/reducer/Notification-details-slice';
import './rewardsPage.scss'
import strings from 'common/Translation/Translate';
import PrimaryButton from 'components/Button/PrimaryButton';
import Scrollbar from 'react-scrollbars-custom';
import ReadMore from 'components/ReadMore';
import Paragraph from 'components/Typography/Typography';
import { Authenticated } from 'utils/redux';
import { setOpenAuthenticationDrawer } from 'utils/redux/reducer/authentication-slice';
import { useNavigate } from 'react-router-dom';
import { routes } from 'utils/constants/routes';
import Fab from '@mui/material/Fab';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const couponBox = {
    //height: '200px',
    borderRadius: '20px 0px',
    padding: '15px',
    display: 'flex',
    gap: '10px',
    background: '#80808012',
    '&:hover': {
        boxShadow: 'inset 3px -3px 6px rgba(0, 0, 0, 0.1607843137)',
        background: '#af94942e',
    },
};

const descriptionFont = {
    fontFamily: 'initial',
    fontSize: 'x-large',
};

type CouponCardProps = {
    cv: any
}

const CouponCard = ({ cv }: CouponCardProps) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLogin = useSelector(Authenticated)
    const screenSizeDownXl = useMediaQuery(theme.breakpoints.down('xl'));
    const screenSizeDownLg = useMediaQuery(theme.breakpoints.down('lg'));
    const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const screenSizeUpLg = useMediaQuery(theme.breakpoints.up('lg'));
    const screenSizeUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const screenSizeUpSm = useMediaQuery(theme.breakpoints.up('sm'));




    const charLength = () => {
        if (screenSizeDownXl && screenSizeUpLg) return 200;
        else if (screenSizeDownLg && screenSizeUpMd) return 70;
        else if (screenSizeDownMd && screenSizeUpSm) return 50;
        else return 600
    }
    const charLengthDownSm = useMediaQuery(theme.breakpoints.down(400)) ? 50 : 70

    const ImageSection = () => {
        const [visibility, setVisibility] = useState<boolean>(false)
        return (
            <Box padding={2} position={'relative'}
                onMouseEnter={() => setVisibility(true)}
                onMouseLeave={() => setVisibility(false)}
            >
                <CardMedia
                    component="img"
                    sx={{
                        width: '100%',
                        objectFit: "cover",
                        height: '250px',
                        opacity: visibility ? 0.5 : 1
                    }}
                    image={cv.image}
                    alt={strings.noImageAvailableText}
                />
                {visibility && <Fab variant="extended" color='primary' aria-label="add" sx={{ position: 'absolute', top: '45%', left: '35%' }}>
                    <RemoveRedEyeIcon sx={{ mr: 1 }} />
                    View details
                </Fab>}
            </Box>
        )
    }


    return (
        <>
            <Card elevation={10} sx={{ cursor: 'pointer' }} onClick={() => navigate(`${routes.productDetails}/${cv.id}`)}>
                <ImageSection />
                <Box px={2} pb={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography fontWeight={600} p={1}>{cv.name}</Typography>
                    {/* {cv.category &&
                        <Stack direction="row" spacing={1}>
                            <Chip label={cv.category} color='primary' />
                        </Stack>
                    } */}
                </Box>
            </Card>
        </>
    );
};

export default CouponCard;
