import React from "react";
import './style.css'
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from "@mui/material";
const PageLoader = ({ text }: { text?: string }) => (
      <div className="loader_wrapper">
            <div className="spinner-border text-primary" role="status" />
            <Box position="relative" display="inline-flex">
                  <Box
                        sx={{
                              padding: "80px 10px 0px 10px",
                              textAlign: "center",
                              color: "#FFFFFF",
                        }}
                  >
                        {text}
                  </Box>
            </Box>
      </div>
);

export default PageLoader;
