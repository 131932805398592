import { v4 as uuidv4 } from "uuid";
import { setPromisePayloadData, setOrderSummaryResponse } from "utils/redux/reducer/create-order-slice";
import { setPromiseResponse } from "utils/redux/reducer/create-order-slice";
import axiosInstance from "utils/redux/axios";
import { URLS } from "utils/constants/urls";
import { setOpenAuthenticationDrawer } from "utils/redux/reducer/authentication-slice";
import { setNotificationMessage } from "utils/redux/reducer/Notification-details-slice";
import CryptoJS from "crypto-js";
import { buyNowProduct, setBuyNowProduct, togglePaymentDrawer } from "utils/redux/reducer/cart-slice";

const getProducts = (itemList: any) => {
	let body: any = {};
	body.products = itemList.map((item: any) => ({
		country_of_origin: "CHL",
		gtin: "string",
		is_courierable: true,
		is_dangerous: true,
		is_gift: true,
		is_returnable: true,
		preference: {
			bag_required: true,
			picker_notes: "string",
		},
		co_line_id: item.co_line_id, //random
		// "product_class": "Pet food",
		// "product_id": "11420",
		// "product_name": "Belcando water buffalo 400 GR",
		// "product_type": "Pet food",
		qty_same_as_parent: true,
		quantity: item.quantity,
		requires_shipping: true,
		sku_id: item.variantCode,
	}));
	return body;
};
const callingPromise = async (pincode: any, addressData: any, item: any, dispatch: any, user: any) => {
	const emailId = user ? user.email : null;
	let shipToPostCode = pincode;
	let countryCode = "CL";
	let productString = JSON.stringify(getProducts(item));
	let convertHeaderData = shipToPostCode + countryCode + productString;
	const hash = CryptoJS.MD5(convertHeaderData).toString();

	let bodyData = {
		cart_id: uuidv4(),
		order_type: "FRW",
		channel: "ONLINE",
		transaction_start_datetime: new Date().toISOString(),
		source_trigger: "CART",
		currency_code: "CLP",
		customer_profile_info: {
			profile_id: emailId,
			profile_type: "CONSUMER",
		},
		store_id: "ejje-dotcom-001",
		pos_id: "1",
		ship_to_address: {
			address_id: addressData[0].addressType == "SHIPPING" ? addressData[0].addressId : addressData[1].addressId,
			phone_numbers: [
				{
					type: "PERSONAL",
					country_code: "CL",
					number: "1212121212",
				},
			],
			country_code: "CL",
			postal_code: pincode,
		},
	};

	dispatch(setPromisePayloadData(bodyData));

	try {
		const config = {
			headers: {
				revision: hash,
			},
		};
		const { status, data } = await axiosInstance.post(URLS.promise, bodyData, config as any);
		if (status === 201) {
			dispatch(setPromiseResponse(data));
			return data.promise_id;
		}
	} catch (err) {}
};

const updateProducts = async (pincode: string, item: any, promiseId: any, dispatch: any) => {
	let shipToPostCode = pincode;
	let countryCode = "CL";
	let productString = JSON.stringify(getProducts(item));
	let convertHeaderData = shipToPostCode + countryCode + productString;
	const hash = CryptoJS.MD5(convertHeaderData).toString();

	const products = item.map((item: any) => ({
		article_id: item.productCode,
		country_of_origin: "Chile",
		gtin: "string",
		is_courierable: true,
		is_dangerous: true,
		is_gift: true,
		is_returnable: true,
		preference: {
			bag_required: true,
			picker_notes: "string",
		},
		co_line_id: item.co_line_id,
		category: item.category,
		product_id: item.id,
		product_name: item.productName,
		product_type: item.category,
		qty_same_as_parent: false,
		quantity: {
			number_of_units: item.quantity,
			unit_of_measure: "EA",
			unit_size: 1,
			unit_size_fraction: 1,
		},
		requires_shipping: true,
		sku_id: item.variantCode,
	}));
	try {
		const config = {
			headers: {
				revision: hash,
			},
		};
		const body = {
			products,
		};

		const { status, data } = await axiosInstance.put(URLS.products(promiseId), body, config as any);
		if (status === 201) {
			dispatch(setOrderSummaryResponse(data));
		}
	} catch (err) {}
};

const placeOrderFunc = async (item: any, dispatch: any) => {
	const body = {
		billingAddressId: 2222,
		billingAddressName: "string",
		countryCode: "CL",
		currencyCode: "CL",
		orders: item.map((item: any) => ({
			orderLineId: item.co_line_id,
			quantity: item.quantity,
			variantId: item.variantId,
		})),
		payment: [
			{
				amount: 0,
				currency_code: "CL",
				payment_datetime: "string",
				payment_gateway: "string",
				payment_ref_id: "string",
				payment_type: "CASH",
				transaction_id: "string",
			},
		],
		posNo: "string",
		promiseId: "string",
		promiseVersion: "string",
		redeemFromLoyaltyPoints: true,
		shippingAddressId: 3333333,
		shippingAddressName: "string",
		storeId: "string",
		supplyTypeCode: "string",
	};
	try {
		const { status, data } = await axiosInstance.post(URLS.getOrderSummary, body);
		if (status === 200) {
			// setOpenDrawer(true);
			// setOrderDetails(body);
			// setOrderSummary(data);
			// fetchAddress(pincode, itemList)
			// setLoader(false);
			dispatch(setOrderSummaryResponse(data));
		}
	} catch (error:any) {
		let errorMessage = "An unknown error occurred";

		if (error?.response && error?.response.data) {
			errorMessage = error.response.data.error_description || error.response.data.message || errorMessage;
		}
		dispatch(
			setNotificationMessage({
				display: true,
				severity: "error",
				message: errorMessage,
			})
		);
	}
};
export const handleBuyNow = async (props: any) => {
	const { dispatch, user, item, addressData, setLoader } = props;
	const pincode = addressData[0]?.addressType == "SHIPPING" ? addressData[0]?.pinCode : addressData[1]?.pinCode;
	try {
		setLoader(true);
		const promiseId = await callingPromise(pincode, addressData, item, dispatch, user);
		if (promiseId) {
			await updateProducts(pincode, item, promiseId, dispatch);
			await placeOrderFunc(item, dispatch);
			dispatch(setBuyNowProduct(item));
			dispatch(togglePaymentDrawer());
		}
	} catch (err) {
		console.log(err);
	}
	setLoader(false);
};
