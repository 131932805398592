import { FC, MouseEvent, RefObject, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AlertColor,
  Box,
  CircularProgress,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Form, { FormDataModel } from "../../../components/Form";
import { LoginForm } from "./LoginForm";
import PrimaryButton from "../../../components/Button/PrimaryButton";
import { removeErrorFieldsFromValues } from "../../../utils/validators";
import { URLS } from "../../../utils/constants/urls";
import { useDispatch } from "react-redux";
import { setAuthentication, setUser } from "../../../utils/redux";
import axiosInstance from "../../../utils/redux/axios";
import strings from "../../../common/Translation/Translate";
import { setNotificationMessage } from "../../../utils/redux/reducer/Notification-details-slice";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import Captcha from "../../../components/Captcha/Captcha";
import { ChangeEmailFormDialog } from "components/Dialog/ChangeEmailFormDialog";

const Login: FC<any> = ({
  handleClose,
  setDrawerState,
}: {
  handleClose: any;
  setDrawerState: any;
}) => {
  const theme = useTheme();
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  let loginForm: RefObject<Form | null | undefined> = useRef();
  const [hasError, setHasError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [captchaRes, setCaptchaRes] = useState<string>("");
  const [captaFlag, setCaptaFlag] = useState<boolean>(false);
  const [changeEmailFormDialogOpen, setChangeEmailFormDialogOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: any;
  }>({
    display: false,
    severity: null,
    message: "",
  });
  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  const verifyCallback = (response: string, captchaFlag: boolean) => {
    setCaptaFlag(captchaFlag);
    setCaptchaRes(response);
  };
  const resendOtpFunc = async () => {
    try {
      setLoading(true);
      const body = {
        "email": email,
        "mailType": "REGISTRATION"
      }
      await axiosInstance.post(URLS.resendOtp, body);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  }
  const handleLogin = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { getFormData } = loginForm.current as {
      getFormData: () => { formData: FormDataModel; isFormValid: boolean };
    };
    const { formData, isFormValid } = getFormData();
    const bodyData = removeErrorFieldsFromValues(formData);
    const body = new URLSearchParams();
    body.append("username", bodyData.email as string);
    body.append("password", bodyData.password as string);
    body.append("grant_type", "password");
    try {
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        auth: {
          username: process.env.REACT_APP_CLIENT,
          password: process.env.REACT_APP_SECRET,
        },
      };
      setHasError(false);
      if (isFormValid) {
        if (!captchaRes) {
          setCaptaFlag(true);
          return false;
        }
        setEmail(bodyData.email as string);
        setLoading(true);
        const res = await axiosInstance.post(
          URLS.login,
          body,
          config as any
        );
        if (res && res.status === 200) {
          setLoading(false);
          dispatch(setAuthentication(res.data));
          dispatch(setUser(res.data))
          handleClose();
        }
        else {
          setLoading(false);
          dispatch(
            setNotificationMessage({
              display: true,
              severity: "error",
              message: 'Something went wrong. Please recheck Email and Password',
            })
          );
        }
      } else {
        setHasError(true);
      }
    } catch (error: any) {
      setLoading(false);

      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: error.response.data.error_description,
        })
      );
    }
  };

  return (
    <>
      <Box display={"flex"} justifyContent={"flex-end"} px={5} py={2}>
        <ClearOutlinedIcon
          onClick={handleClose}
          sx={{
            ":hover": { cursor: "pointer" },
            color: theme.palette.primary.main,
          }}
        />
      </Box>
      <Typography
        fontWeight={"bold"}
        ml={8}
        mb={1}
        color={"primary"}
        fontSize={'20px'}
      >
        {strings.loginText}
      </Typography>
      <Box mx={screenSizeDownMd ? 3 : 8} bgcolor={theme.palette.secondary.light}>
        <Grid
          container
          mt={1}
          px={screenSizeDownMd ? 2 : 4}
          py={3}
          bgcolor={theme.palette.secondary.light}
          spacing={1}
        >
          <Form
            hasError={hasError}
            ref={loginForm as RefObject<Form>}
            model={LoginForm(strings)}
            values={{}}
            forgetPassword={() => setDrawerState(2)}
          />
          <Grid item>
            <Captcha verify={verifyCallback} />
            <Typography variant="subtitle2" color="error">
              {captaFlag ? strings.captchaValidation : ""}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <PrimaryButton
              disabled={loading}
              sx={{ mt: 1, py: 1 }}
              onClick={(e) => handleLogin(e)}
            >
              {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
              {strings.loginText}
            </PrimaryButton>
          </Grid>
          <Grid item xs={12}>
            <Box display={"flex"} justifyContent="center">
              <Typography fontSize={'0.75rem'}>{strings.dontHaveAccountText}&nbsp;</Typography>
              <Link
                sx={{ cursor: "pointer" }}
                color={theme.palette.secondary.main}
                onClick={() => setDrawerState(3)}
                underline={"hover"}
              >
                <Typography fontWeight={500} fontSize={'0.75rem'}>{strings.signUp}</Typography>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Login;
