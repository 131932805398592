import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import PrimaryButton from "components/Button/PrimaryButton";
import strings from "common/Translation/Translate";
import { useTheme } from "@mui/system";
import { Grid, CircularProgress, AlertColor, useMediaQuery, TextareaAutosize } from "@mui/material";
import Form, { FormDataModel } from "components/Form";
import { LoginForm } from "screens/Authentication/Login/LoginForm";
import { URLS } from "utils/constants/urls";
import { setAuthentication, setUser } from "utils/redux";
import axiosInstance from "utils/redux/axios";
import { setNotificationMessage } from "utils/redux/reducer/Notification-details-slice";
import { removeErrorFieldsFromValues } from "utils/validators";
import Notification from "components/Notification";
import { config } from "process";
import { useDispatch } from "react-redux";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { Scrollbar } from "react-scrollbars-custom";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import PageLoader from "components/PageLoader";
import { v4 as uuidv4 } from "uuid";

type ModalProps = {
      open: boolean;
      setOpen: any;
      tableData: any;
};

const style = {
      fontWeight: "bold",
      fontSize: "16px",
};

export const CancelModal: React.FC<ModalProps> = ({ open, setOpen, tableData }) => {
      const theme = useTheme();
      const dispatch = useDispatch();
      const screenSizeDownMd = useMediaQuery(theme.breakpoints.down("md"));
      const screenSizeDownSm = useMediaQuery(theme.breakpoints.down("sm"));
      const [hasError, setHasError] = React.useState<boolean>(false);
      const [loading, setLoading] = React.useState<boolean>(false);
      const [cancelReason, setCancelReason] = React.useState("");
      const [error, setError] = React.useState(false);
      const [cancelBodyLines, setCancelBodyLines] = React.useState<any>([]);

      React.useEffect(() => {
            if (Object.keys(tableData).length > 0)
                  setCancelBodyLines(tableData.products.map((item: any) => ({
                        quantity: item.quantity,
                        reason: '',
                        variantCode: item.variantCode
                  })))
      }, [tableData]);

      const [message, setMessage] = React.useState<{
            display: boolean;
            severity: AlertColor | null;
            message: any;
      }>({
            display: false,
            severity: null,
            message: "",
      });

      const closeNotification = (value: boolean) => {
            setMessage({ ...message, display: value });
      };

      const onClose = () => {
            setOpen(false);
      };

      const handleQuantityChange = (sign: string, variantCode: string) => {
            // Find the item by variantCode
            const itemIndex = cancelBodyLines.findIndex((item: any) => item.variantCode === variantCode);

            if (itemIndex !== -1) {
                  const item = { ...cancelBodyLines[itemIndex] };
                  const product = tableData.products.find((product: any) => product.variantCode === variantCode);

                  if (product) {
                        // Update quantity based on the sign
                        if (sign === "-" && item.quantity > 1) {
                              item.quantity -= 1;
                        } else if (sign === "+" && item.quantity < product.quantity) {
                              item.quantity += 1;
                        }

                        // Create a new array with the updated item
                        const newCancelBodyLines = [...cancelBodyLines];
                        newCancelBodyLines[itemIndex] = item;

                        // Update the state
                        setCancelBodyLines(newCancelBodyLines);
                  }
            }
      };


      const handleOnConfirmOrderCancellation = async () => {

            const notEmpty = cancelBodyLines.every((item: any) => item.reason);
            if (!notEmpty) return

            setLoading(true);
            const bodyData = {
                  cancelledAt: new Date().toISOString(),
                  cancelledBy: "string",
                  lines: cancelBodyLines,
                  orderNumber: tableData?.orderNumber,
                  posNo: tableData?.posNo,
                  storeNo: tableData.storeId,
            };
            const config = {
                  headers: {
                        "x-transaction-id": uuidv4(),
                        "x-transaction-date": new Date().toISOString(),
                  },
            };
            try {
                  const result = await axiosInstance.post(URLS.cancelCustomerOrder, bodyData, config);
                  if (result.status == 200) {
                        setMessage({
                              display: true,
                              severity: "success",
                              message: "Order has been cancelled successfully",
                        });
                  }
                  setOpen(false);
            } catch (err) {
                  setMessage({
                        display: true,
                        severity: "error",
                        message: "Something went wrong",
                  });
            }
            setLoading(false);
      };
      const handleReasonChange = (reason: string, variantCode: string) => {
            // Find the item by variantCode
            const itemIndex = cancelBodyLines.findIndex((item: any) => item.variantCode === variantCode);
            if (itemIndex !== -1) {
                  const item = { ...cancelBodyLines[itemIndex] };
                  item.reason = reason;
                  // Create a new array with the updated item
                  const newCancelBodyLines = [...cancelBodyLines];
                  newCancelBodyLines[itemIndex] = item;
                  // Update the state
                  setCancelBodyLines(newCancelBodyLines);
            }
      }

      const getQuantityWithVariantCode = (variantCode: string) => {
            const quantity = cancelBodyLines.find((item: any) => item.variantCode == variantCode);
            return quantity?.quantity;
      }

      return (
            <>
                  {loading && <PageLoader />}
                  {message.display && (
                        <Notification
                              isOpen={message.display}
                              message={message.message}
                              severity={message.severity as AlertColor}
                              closeNotification={closeNotification}
                        />
                  )}
                  <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box
                              sx={{
                                    position: "absolute" as "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: screenSizeDownMd ? (screenSizeDownSm ? "90vw" : "70vw") : "40vw",
                                    maxWidth: "600px",
                                    bgcolor: "background.paper",
                                    border: "2px solid #000",
                                    boxShadow: 24,
                                    p: 3,
                                    maxHeight: "700px",

                              }}
                        >
                              <Box display={"flex"} alignItems="center" justifyContent={"space-between"}>
                                    <Typography
                                          id="modal-modal-title"
                                          variant="h6"
                                          component="h2"
                                          fontWeight={600}
                                          color={"primary"}
                                          sx={{ alignItems: "center" }}
                                    >
                                          Cancel Order
                                    </Typography>
                                    <ClearOutlinedIcon
                                          onClick={onClose}
                                          sx={{
                                                ":hover": { cursor: "pointer" },
                                                color: theme.palette.primary.main,
                                          }}
                                    />
                              </Box>
                              <Box sx={{ maxHeight: "400px", overflow: "auto" }}>
                                    <Typography>
                                          <span style={style}>Order Number : </span>
                                          <span>{tableData.orderNumber}</span>
                                    </Typography>
                                    {tableData && tableData?.products?.map((item: any) => <><Box sx={{ display: "grid", gridAutoRows: "40px", marginTop: "20px", }}>

                                          <Typography>
                                                <span style={style}>Variant Code : </span>
                                                <span>{item.variantCode}</span>
                                          </Typography>
                                          <Typography>
                                                <span style={style}>Order Quantity : </span>
                                                <span>{item.orderedQuantity}</span>
                                          </Typography>
                                          <Box sx={{ display: "flex", gap: "20px" }}>
                                                <Typography sx={{ ...style, paddingTop: "5px" }}>Set Quantity To Cancel : </Typography>
                                                <Box sx={{ display: "flex", gap: "10px" }}>
                                                      <RemoveCircleOutlineOutlinedIcon onClick={() => handleQuantityChange("-", item.variantCode)} fontSize="large">
                                                            -
                                                      </RemoveCircleOutlineOutlinedIcon>
                                                      <Typography sx={{ fontSize: "18px", fontWeight: "bold", paddingTop: "2px" }}>{getQuantityWithVariantCode(item.variantCode)}</Typography>
                                                      <AddCircleOutlineOutlinedIcon fontSize="large" onClick={() => handleQuantityChange("+", item.variantCode)}>
                                                            +
                                                      </AddCircleOutlineOutlinedIcon>
                                                </Box>
                                          </Box>
                                    </Box>
                                          <Box height="120px">
                                                <TextareaAutosize
                                                      maxLength={100}
                                                      name="description"
                                                      placeholder="Enter Reason (maximum 100 characters)"
                                                      onChange={(e: any) => {
                                                            handleReasonChange(e.target.value, item.variantCode);

                                                      }}
                                                      style={{
                                                            minHeight: "120px",
                                                            width: "100%",
                                                            resize: "none",
                                                            padding: "5px",
                                                            fontFamily: "Roboto",
                                                            fontSize: "14px",
                                                            lineHeight: "21px",
                                                            borderColor: error ? "red" : "",
                                                      }}
                                                />

                                          </Box>
                                    </>)}

                              </Box>
                              <Grid container mt={1} spacing={1} px={1}>
                                    <Grid item xs={12} md={6}>
                                          <PrimaryButton onClick={handleOnConfirmOrderCancellation}>
                                                {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
                                                Confirm Cancellation
                                          </PrimaryButton>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                          <PrimaryButton onClick={onClose}>Discard</PrimaryButton>
                                    </Grid>
                              </Grid>
                        </Box>
                  </Modal>
            </>
      );
};
