import React from 'react'
import { FC, MouseEvent, RefObject, useRef, useState } from "react";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { Card, Divider, FormControl, FormControlLabel, Grid, Modal, Radio, RadioGroup, Switch, Typography, useMediaQuery } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import strings from "common/Translation/Translate";
import PrimaryButton from "components/Button/PrimaryButton";
import DrawerMenu from "components/DrawerMenu";
import PageLoader from "components/PageLoader";
import { ToolTip } from "components/Tooltip/ToolTip";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { URLS } from "utils/constants/urls";
import { Authenticated, cartItems, createOrderPromisePayloadData, createOrderPromiseResponse, orderSummaryResponse, paymentDrawerState, totalAmount, userAddress } from "utils/redux";
import axiosInstance from "utils/redux/axios";
import { setNotificationMessage } from "utils/redux/reducer/Notification-details-slice";
import { setOpenAuthenticationDrawer } from "utils/redux/reducer/authentication-slice";
import { buyNowProduct, buyNowProductTotalAmount, clearCart, setBuyNowProduct, togglePaymentDrawer } from 'utils/redux/reducer/cart-slice'
import { getDrawerWidth } from "utils/validators/HelperFunctions";
import { DetailsPage } from "./DetailsPage";
import Form, { FormDataModel } from 'components/Form';
import { PaymentForm } from './PaymentForm';
import { removeErrorFieldsFromValues } from "../../utils/validators";
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';
import { setOrderSummaryResponse, setPromisePayloadData, setPromiseResponse } from 'utils/redux/reducer/create-order-slice';
import { time } from 'console';
interface PaymentObject {
      paymentType: any,
      amount: number,
      paymentRefId: string,
      currencyCode: string,
      paymentGateway: string,
      paymentDateTime: string,
      transactionId: string,
      paymentTransaction?: string
      paymentStatus: string,
}

const Payment = ({
      tooltipIsOpen,
      setTooltipIsOpen,
}: {

      tooltipIsOpen: boolean;
      setTooltipIsOpen: (value: boolean) => void

}) => {
      const theme = useTheme();
      const isLogin = useSelector(Authenticated);
      const dispatch = useDispatch();
      const navigate = useNavigate();
      const screenSizeDownXl = useMediaQuery(theme.breakpoints.down("xl"));
      const screenSizeDownLg = useMediaQuery(theme.breakpoints.down("lg"));
      const screenSizeDownMd = useMediaQuery(theme.breakpoints.down("md"));
      const screenSizeDownSm = useMediaQuery(theme.breakpoints.down("sm"));
      const screenSizeUpLg = useMediaQuery(theme.breakpoints.up("lg"));
      const screenSizeUpMd = useMediaQuery(theme.breakpoints.up("md"));
      const screenSizeUpSm = useMediaQuery(theme.breakpoints.up("sm"));
      const [hasError, setHasError] = useState(false as boolean);
      const [payments, setPayments] = useState<PaymentObject[]>([]);
      const [useLoyaltyPoints, setUseLoyaltyPoints] = React.useState(false);
      const [leftOutAmount, setLeftOutAmount] = useState(0);
      const paymentFormRef: RefObject<Form | null | undefined> = useRef(null);
      const [formData, setFormData] = useState({});
      const [choosedFulfillmentOption, setChoosedFulfillmentOption] = useState({});
      const [fulfillmentOptionResp, setFulfillmentOptionResp] = useState<any>();
      const [displayButtons, setDisplayButtons] = React.useState(false as boolean)
      const [commitStatus, setCommitStatus] = React.useState('OPEN' as string)
      const [loader, setLoader] = useState<boolean>(false);
      const promiseResp = useSelector(createOrderPromiseResponse)
      const orderSummary = useSelector(orderSummaryResponse)
      const userAdd = useSelector(userAddress)
      const buyNowItems = useSelector(buyNowProduct)
      const Items = useSelector(cartItems)
      const cartProducts = buyNowItems.length ? buyNowItems : Items
      const payableAmount = (useSelector(buyNowProductTotalAmount) || useSelector(totalAmount))
      const openDrawer = useSelector(paymentDrawerState)
      const [deliveryCost, setDeliveryCost] = React.useState<any[]>([])
      function scrollToBottom() {
            const gridElement = document.querySelector('.payment-grid');

            if (gridElement) {
                  gridElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
      }

      React.useEffect(() => {
            (async () => {
                  if (openDrawer && promiseResp && userAdd && cartProducts.length) {
                        await getFeasibleFullFillmentOption(promiseResp, userAdd[0]?.addressType == "SHIPPING" ? userAdd[0]?.pinCode : userAdd[1]?.pinCode, cartProducts)
                  }
            })()
      }, [promiseResp, userAdd, cartProducts, openDrawer])

      React.useEffect(() => {
            return () => {
                  dispatch(setBuyNowProduct([]));
            };
      }, [dispatch]);
      React.useEffect(() => {
            if (orderSummary || payableAmount) {
                  let productAmount = orderSummary?.totalAmount || payableAmount
                  let deliveryAmount = addNewDeliveryCost(deliveryCost)
                  let loyaltyAmount = useLoyaltyPoints ? orderSummary?.redeemableAmount : 0
                  setLeftOutAmount((productAmount + deliveryAmount - loyaltyAmount) || 0)
            }
      }, [orderSummary, deliveryCost])

      // const deductPreviousDeliveryCost = (delivery: any) => {
      //       const cost = delivery.reduce((acc: any, curr: any) => acc + (curr?.delivery_cost?.amount / curr?.delivery_cost?.fraction || 0), 0)
      //       setLeftOutAmount((prev) => prev - cost)
      // }
      const addNewDeliveryCost = (delivery: any) => {
            const cost = delivery.reduce((acc: any, curr: any) => acc + (curr?.delivery_cost?.amount / curr?.delivery_cost?.fraction || 0), 0)
            return cost
      }

      const getProducts = (itemList: any) => {
            let body: any = {}
            body.products = itemList.map((item: any) => (
                  {
                        "country_of_origin": "CHL",
                        "gtin": "string",
                        "is_courierable": true,
                        "is_dangerous": true,
                        "is_gift": true,
                        "is_returnable": true,
                        "preference": {
                              "bag_required": true,
                              "picker_notes": "string"
                        },
                        "co_line_id": item.co_line_id,//random
                        // "product_class": "Pet food",
                        // "product_id": "11420",
                        // "product_name": "Belcando water buffalo 400 GR",
                        // "product_type": "Pet food",
                        "qty_same_as_parent": true,
                        "quantity": item.quantity,
                        "requires_shipping": true,
                        "sku_id": item.variantCode
                  }
            ))
            return body
      }
      function deductDeliveryChargeFromSecondOrderSummaryResponse(data: any) {
            let totalAmount = data.totalAmount;
            data.items.forEach((item: any) => {
                  if (item.charges && Array.isArray(item.charges)) {
                        item.charges.forEach((charge: any) => {
                              if (charge.amount && charge.isBillable) {
                                    totalAmount -= charge.amount;
                              }
                        });
                  }
            });

            data.totalAmount = totalAmount;
            return data;
      }
      const getFeasibleFullFillmentOption = async (promiseResp: any, pincode: any, products: any[]) => {
            let shipToPostCode = pincode
            let countryCode = "CL"
            let productString = JSON.stringify(getProducts(products))
            let convertHeaderData = shipToPostCode + countryCode + productString
            const hash = CryptoJS.MD5(convertHeaderData).toString();
            const bodyData = {}
            try {
                  const config = {
                        headers: {
                              'revision': hash,
                        },
                  };
                  const { status, data } = await axiosInstance.post(URLS.fullFilmentOptions(promiseResp?.promise_id), bodyData, config as any)
                  if (status === 200) {
                        const { deliveryCostArray, defaultOptions } = data.promise_groups.reduce(
                              (acc: any, item: any) => {
                                    if (item.promise_options.length) {
                                          const productDetails = item.lines.map((line: any) => ({
                                                variantCode: line.sku_id,
                                                variantId: ""
                                          }));

                                          acc.deliveryCostArray.push({
                                                groupId: item.promise_group_id,
                                                deliveryMethod: item.promise_options[0].delivery_method,
                                                products: productDetails,
                                                delivery_cost: item?.promise_options[0].delivery_cost || null,


                                          });

                                          acc.defaultOptions[item.promise_group_id] = item.promise_options[0].option_id;
                                    }
                                    return acc;
                              },
                              { deliveryCostArray: [], defaultOptions: {} }
                        );

                        setDeliveryCost((prev: any) => {
                              // deductPreviousDeliveryCost(prev || []);
                              // addNewDeliveryCost(deliveryCostArray || []);

                              return deliveryCostArray;
                        });

                        setChoosedFulfillmentOption(defaultOptions);
                        setFulfillmentOptionResp(data);

                  }

            } catch (err: any) {

            }
      }



      React.useEffect(() => {
            setUseLoyaltyPoints(false)
      }, [])

      const onDrawerClose = () => {
            dispatch(togglePaymentDrawer())
      }
      const handleError = (message: any, type: string) => {
            return dispatch(
                  setNotificationMessage({
                        display: true,
                        severity: message === "success" ? "success" : "error",
                        message: type === "success" ? message : message?.response?.data.message,
                  })
            );
      };


      const handleOnselection = async (bodyData: any) => {
            let shipToPostCode = userAdd[0]?.addressType == "SHIPPING" ? userAdd[0]?.pinCode : userAdd[1]?.pinCode
            let countryCode = "CL"
            let productString = JSON.stringify(getProducts(cartProducts))
            let convertHeaderData = shipToPostCode + countryCode + productString
            const hash = CryptoJS.MD5(convertHeaderData).toString();
            setLoader(true);
            try {
                  const config = {
                        headers: {
                              'revision': hash,
                        },
                  };
                  const { status, data } = await axiosInstance.post(URLS.selection(promiseResp.promise_id), bodyData, config as any)
                  if (status === 200) {
                  }
            } catch (err: any) {
                  setLoader(false);
                  handleError(err.message, "error");
            }
            setLoader(false);
      }
      const handleFulfillmentOptionsSelection = async (groupId: string, e: any, promiseGroup: any) => {
            setChoosedFulfillmentOption({ ...choosedFulfillmentOption, [groupId]: e.target.value })
            const selectedOptionId = e.target.value; // Get the selected option_id from the event

            const selectedOption = promiseGroup.promise_options.find(
                  (option: any) => option.option_id === selectedOptionId
            );

            const mappedObject = selectedOption ? {
                  groupId: promiseGroup.promise_group_id,
                  deliveryMethod: selectedOption.delivery_method,
                  products: promiseGroup.lines.map((line: any) => ({
                        variantCode: line.sku_id,
                        variantId: ""
                  })),
                  delivery_cost: selectedOption.delivery_cost || null
            } : null;


            if (selectedOption) {
                  setDeliveryCost((prev: any[]) => {
                        // deductPreviousDeliveryCost(prev || []);

                        const updatedPrev = prev.filter((item: any) => item.groupId !== groupId);
                        const updatedDeliveryCost = [...updatedPrev, mappedObject];
                        // addNewDeliveryCost(updatedDeliveryCost || []);

                        return updatedDeliveryCost;
                  });

            }


      }

      const handleOnSave = () => {
            if (!(Object.keys(choosedFulfillmentOption).length === fulfillmentOptionResp.promise_groups.length)) {
                  dispatch(setNotificationMessage({
                        display: true,
                        severity: "error",
                        message: "Choose all fulfillment Options",
                  }));
                  return
            }
            const arr = Object.entries(choosedFulfillmentOption)

            const bodyData = {
                  promise_groups: arr.map((subarr: any) => ({
                        group_id: subarr[0],
                        selected_option_id: subarr[1]
                  }))
            };
            setDisplayButtons(true)
            handleOnselection(bodyData)
      }

      const handleOnCommit = (statusData: string) => {
            const bodyData = {
                  status: statusData
            }
            handleOnStatusUpdate(bodyData)
      }

      const handleOnStatusUpdate = async (bodyData: any) => {
            setLoader(true);
            try {
                  const { status, data } = await axiosInstance.post(URLS.state(promiseResp?.promise_id), bodyData)
                  if (status === 200) {
                        setCommitStatus(bodyData.status)
                        // dispatch(setNotificationMessage({
                        //       display: true,
                        //       severity: "success",
                        //       message: `${bodyData.status} success!`,
                        // }));
                        if (bodyData.status === 'OPEN') {
                              setDisplayButtons(false)
                              getFeasibleFullFillmentOption(promiseResp, userAdd[0]?.addressType == "SHIPPING" ? userAdd[0]?.pinCode : userAdd[1]?.pinCode, cartProducts)



                        }
                        if (bodyData.status !== 'OPEN') {
                              callingOrderSummaryForGettingCharges();
                              scrollToBottom();
                        }

                  }
            } catch (err: any) {
                  handleError(err.message, "error");
            }
            setLoader(false);
      }

      const getOrderDetails = () => {
            let orders = cartProducts.map((item: any) => {
                  return {

                        orderLineId: item.co_line_id,
                        cartLineId: item.co_line_id,
                        quantity: item.quantity,
                        variantId: item.variantId,
                        charges: getCharges(item.variantId)

                  }
            })
            return orders
      }
      const getCharges = (variantId: any) => {
            return orderSummary?.items?.find((item: any) => item.variantId == variantId)?.charges || []

      }

      const placeOrder = async (paymentList: PaymentObject[]) => {
            setLoader(true);
            let body = {
                  "countryCode": "CL",
                  "currencyCode": "CLP",
                  startTransactionDateTime: promiseResp?.transaction_start_datetime,
                  cartId: promiseResp.cart_id,
                  "orders": getOrderDetails(),
                  "payment": paymentList,
                  "posNo": "1",
                  "promiseId": promiseResp?.promise_id,
                  "promiseVersion": null,
                  "redeemFromLoyaltyPoints": useLoyaltyPoints,
                  "storeId": "ejje-dotcom-001",
                  "supplyTypeCode": "B2C",
                  "transactionId": uuidv4()
            }
            try {
                  const result = await axiosInstance.post(URLS.placeOrder, body);
                  if (result.status === 200) {
                        onDrawerClose();
                        dispatch(clearCart());
                        dispatch(setPromiseResponse({}))
                        dispatch(setPromisePayloadData({}))
                        dispatch(setOrderSummaryResponse({}))
                        dispatch(setNotificationMessage({
                              display: true,
                              severity: "success",
                              message: `Order with order number ${result.data.orderNumber} has been placed successfully.`,
                        }));

                  }
            } catch (err: any) {
                  onDrawerClose();
                  dispatch(setNotificationMessage({
                        display: true,
                        severity: "error",
                        message: err.message || "unknown error",
                  }));
            }
            setLoader(false);



      }
      const callingOrderSummaryForGettingCharges = async () => {
            setLoader(true)
            const orders = cartProducts.map((product: any) => ({
                  cartLineId: product.co_line_id.toString(),
                  orderLineId: product.co_line_id.toString(),
                  quantity: product.quantity,
                  variantCode: product.variantCode,
                  variantId: product.variantId
            }));

            const getVariantIdForProduct = (products: any) => {
                  return products.map((product: any) => {
                        const matchingOrder = orders.find((order: any) => order.variantCode === product.variantCode);
                        return {
                              ...product,
                              variantId: matchingOrder ? matchingOrder.variantId : null
                        };
                  });
            }

            const selectedOptions = deliveryCost.map((item: any) => ({
                  "deliveryMethod": item.deliveryMethod,
                  "products": getVariantIdForProduct(item.products),
                  "deliveryCharges": item.delivery_cost
            }))


            const body = {
                  cartId: promiseResp?.cart_id,
                  "countryCode": "CL",
                  "currencyCode": "CLP",
                  "orders": orders,
                  selectedOption: selectedOptions,
                  "posNo": "1",
                  "promiseId": promiseResp?.promise_id,
                  "startTransactionDateTime": new Date().toISOString(),
                  "storeId": 'ejje-dotcom-001',
                  "supplyTypeCode": "B2C"
            }
            try {
                  const result = await axiosInstance.post(URLS.getOrderSummary, body);
                  const data = deductDeliveryChargeFromSecondOrderSummaryResponse(result.data)
                  dispatch(setOrderSummaryResponse(data))
                  if (useLoyaltyPoints) {

                        const obj: PaymentObject = {
                              paymentType: "LOYALTY_CARD",
                              amount: data?.redeemableAmount || 0,
                              paymentRefId: uuidv4(),
                              currencyCode: "CLP",
                              paymentGateway: "nebula-sandbox",
                              paymentDateTime: new Date().toISOString(),
                              transactionId: uuidv4(),
                              "paymentStatus": "RECVD",
                              "paymentTransaction": "CAPTURE",
                        };
                        const newPayments = payments.filter((payment) => payment.paymentType !== "LOYALTY_CARD");
                        setPayments([...newPayments, obj]);

                  }

            } catch (err: any) {
                  onDrawerClose();
                  dispatch(setNotificationMessage({
                        display: true,
                        severity: "error",
                        message: err.message || "unknown error",
                  }));
            }
            setLoader(false);


      }

      const handlePayment = async (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            const { getFormData } = paymentFormRef.current as {
                  getFormData: () => { formData: FormDataModel; isFormValid: boolean };
            };
            const { formData, isFormValid } = getFormData();
            if (!isFormValid) setHasError(true);

            if (isFormValid && leftOutAmount > 0) {
                  const obj: PaymentObject = {
                        paymentType: formData.payment_method,
                        amount: Number(formData.amount),
                        paymentRefId: formData.reference_id.toString(),
                        currencyCode: formData.currency.toString(),
                        paymentGateway: "nebula-sandbox",
                        paymentDateTime: new Date().toISOString(),
                        transactionId: uuidv4(),
                        "paymentStatus": "RECVD",
                        "paymentTransaction": "CAPTURE",
                  };
                  setLeftOutAmount(Number(leftOutAmount) - Number(formData.amount));
                  setPayments([...payments, obj]);
                  placeOrder([...payments, obj]);
                  paymentFormRef.current?.resetForm();

            }
      };

      const handleSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (orderSummary.redeemableAmount == 0) return

            const { getFormData } = paymentFormRef.current as {
                  getFormData: () => { formData: FormDataModel; isFormValid: boolean };
            };
            const { formData, isFormValid } = getFormData();
            setFormData(formData);

            if (e.target.checked) {
                  const obj: PaymentObject = {
                        paymentType: "LOYALTY_CARD",
                        amount: orderSummary.redeemableAmount,
                        paymentRefId: uuidv4(),
                        currencyCode: "CLP",
                        paymentGateway: "nebula-sandbox",
                        paymentDateTime: new Date().toISOString(),
                        transactionId: uuidv4(),
                        "paymentStatus": "RECVD",
                        "paymentTransaction": "CAPTURE",
                  };
                  setPayments([...payments, obj]);
                  setLeftOutAmount(Number(leftOutAmount) - Number(orderSummary?.redeemableAmount));
            } else {
                  const newPayments = payments.filter((payment) => payment.paymentType !== "LOYALTY_CARD");
                  setPayments(newPayments);
                  setLeftOutAmount(Number(leftOutAmount) + Number(orderSummary?.redeemableAmount));
            }
            setUseLoyaltyPoints(e.target.checked)
      }

      return (
            <>
                  {loader && <PageLoader />}
                  <DrawerMenu
                        open={openDrawer}
                        handleClose={onDrawerClose}
                        width={getDrawerWidth(screenSizeDownSm, screenSizeDownMd, screenSizeUpLg)}
                  >
                        <Box display={"flex"} alignItems="center" justifyContent={"space-between"} px={3} py={2}>
                              <Typography id="modal-modal-title" variant="h5" fontWeight={600} color={"primary"}>
                                    Order details
                              </Typography>
                              <ClearOutlinedIcon
                                    onClick={onDrawerClose}
                                    sx={{
                                          ":hover": { cursor: "pointer" },
                                          color: theme.palette.primary.main,
                                    }}
                              />
                        </Box>
                        <Divider
                              sx={{
                                    mx: 3,
                                    borderStyle: "dashed",
                                    borderColor: theme.palette.secondary.main,
                              }}
                        />
                        <Grid container columnSpacing={4} rowSpacing={1} px={3} py={1.5} className='payment-grid'>
                              <Grid item xs={12}>

                                    <Grid container gridTemplateColumns={"1fr"} justifyItems={"center"} py={1.5} >
                                          <Card sx={{ width: '100%' }}>
                                                <Typography fontWeight={600} sx={{ margin: "10px", paddingLeft: "10px" }}>Shipping And Billing Details</Typography>
                                                <Divider
                                                      sx={{
                                                            mb: 1,
                                                            borderStyle: "solid",
                                                            borderColor: theme.palette.secondary.main,
                                                      }}
                                                />
                                                <Grid item xs={12} sm={12} md={12} sx={{ paddingLeft: "20px", margin: "10px" }}>
                                                      <Typography fontWeight={600} mb={1} >Shipping Address:</Typography>
                                                      {
                                                            <Typography>  {userAdd && Object.values((userAdd.length > 0 && userAdd[0]?.addressType == "SHIPPING" ? userAdd[0] : userAdd[1]) || {}).slice(1, -2).filter((item) => item).join(",")}</Typography>

                                                      }
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} sx={{ paddingLeft: "20px", margin: "10px" }}>
                                                      <Typography fontWeight={600} mb={1}>Billing Address:</Typography>
                                                      {
                                                            <Typography>  {userAdd && Object.values((userAdd.length > 0 && userAdd[0]?.addressType == "BILLING" ? userAdd[0] : userAdd[1]) || {}).slice(1, -2).filter((item) => item).join(",")}</Typography>

                                                      }
                                                </Grid>
                                          </Card>
                                    </Grid>

                                    {/* <Card sx={{ py: 2 }}>
                                          <Typography fontWeight={600} sx={{ margin: "10px", paddingLeft: "10px" }}>Order Details</Typography>
                                          <Divider
                                                sx={{
                                                      mb: 1,
                                                      borderStyle: "solid",
                                                      borderColor: theme.palette.secondary.main,
                                                }}
                                          />
                                          <Box display={"flex"} alignItems={"center"} width={"100%"} sx={{ pl: 3 }}>
                                                <Box width={'30%'}>
                                                      <Box display={"flex"}>
                                                            <Typography fontWeight={600}>Store Id</Typography>
                                                      </Box>
                                                      <Box display={"flex"}>
                                                            <Typography fontWeight={600}>Pos Id</Typography>
                                                      </Box>
                                                      <Box display={"flex"}>
                                                            <Typography fontWeight={600}>Supply Type Code</Typography>
                                                      </Box>
                                                      <Box display={"flex"}>
                                                            <Typography fontWeight={600}>Transaction Date</Typography>
                                                      </Box>
                                                </Box>
                                                <Box>
                                                      <Box display={"flex"}>
                                                            <Typography >{promisePayLoadData?.store_id}</Typography>
                                                      </Box>
                                                      <Box display={"flex"}>
                                                            <Typography >{promisePayLoadData?.pos_id}</Typography>
                                                      </Box>
                                                      <Box display={"flex"}>
                                                            <Typography >{'B2C'}</Typography>
                                                      </Box>
                                                      <Box display={"flex"}>
                                                            <Typography >{new Date(promisePayLoadData?.transaction_start_datetime).toLocaleString()}</Typography>
                                                      </Box>
                                                </Box>
                                          </Box>
                                    </Card> */}
                                    <Card sx={{ py: 2, mt: 2 }}>
                                          <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={"100%"} sx={{ pl: 2 }}>
                                                <Box display={"flex"} alignItems={"center"}>
                                                      <Typography fontWeight={600}>Redeemable Amount&nbsp;</Typography>
                                                      <ToolTip
                                                            open={tooltipIsOpen}
                                                            onOpen={() => setTooltipIsOpen(true)}
                                                            onClose={() => setTooltipIsOpen(false)}
                                                            title={(orderSummary?.maxRedeemableText || "")}
                                                      >
                                                            <InfoOutlinedIcon fontSize="small" onClick={() => setTooltipIsOpen(!tooltipIsOpen)} />
                                                      </ToolTip>
                                                </Box>
                                                <Box display={"flex"} alignItems={"center"}>
                                                      <Typography>{(orderSummary?.redeemableAmount || 0)} loyalty cash</Typography>
                                                      <Switch
                                                            checked={useLoyaltyPoints}
                                                            onChange={handleSwitch}
                                                            inputProps={{ "aria-label": "controlled" }}
                                                      />
                                                </Box>
                                          </Box>
                                          <Divider
                                                sx={{
                                                      mb: 1,
                                                      borderStyle: "solid",
                                                      borderColor: theme.palette.secondary.main,
                                                }}
                                          />
                                          <Box sx={{ p: 2 }}>
                                                {orderSummary?.items?.map((i: any, index: number) => (
                                                      <div key={index} style={{ marginTop: '10px' }}>
                                                            <Box display={"flex"} gap={1}>
                                                                  <img src={i.productImageUrl} height={"40px"} alt="image" />
                                                                  <Typography fontWeight={600}>
                                                                        {i.productName} - {i.variantCode}
                                                                  </Typography>
                                                            </Box>
                                                            <Box display={"flex"} justifyContent="space-between" mx={1} mt={1}>
                                                                  {/* <Grid item xs={0.8}><CircleIcon sx={{ fontSize: '8px' }} /></Grid> */}
                                                                  <Typography>
                                                                        {i.variantName} * {i.quantity}
                                                                  </Typography>
                                                                  <Typography>{i.amount}</Typography>
                                                            </Box>
                                                      </div>
                                                ))}
                                                {
                                                      payments.map((item: PaymentObject) => <Box display={"flex"} justifyContent="space-between" mx={1} sx={{ mt: 1 }}>
                                                            <Typography fontWeight={600} flex={5} mr={1}>
                                                                  {item.paymentType == "LOYALTY_CARD"}
                                                            </Typography>
                                                            <Typography fontWeight={600} flex={4} textAlign={"end"}>
                                                                  -{item.amount}
                                                            </Typography>
                                                      </Box>

                                                      )
                                                }
                                                <Box display={"flex"} justifyContent="space-between" mx={1} sx={{ marginTop: '5px' }}>
                                                      <Typography fontWeight={600} flex={5} mr={1}>
                                                            {'Delivery Charge'}
                                                      </Typography>
                                                      <Typography fontWeight={600} flex={4} textAlign={"end"}>
                                                            {(deliveryCost?.reduce((acc: any, curr: any) => acc + (curr?.delivery_cost?.amount / curr?.delivery_cost?.fraction || 0), 0) || 0).toFixed(2)}
                                                      </Typography>
                                                </Box>
                                                <Divider
                                                      sx={{
                                                            my: 1,
                                                            borderStyle: "dashed",
                                                            borderColor: theme.palette.secondary.main,
                                                      }}
                                                />

                                                <Box display={"flex"} justifyContent="space-between" mx={1}>
                                                      <Typography color={"primary"} fontWeight={600} flex={5} mr={1}>
                                                            {strings.totalText}
                                                      </Typography>
                                                      <Typography color={"primary"} fontWeight={600} flex={4} textAlign={"end"}>
                                                            {(leftOutAmount || 0).toFixed(2)}
                                                      </Typography>
                                                </Box>
                                          </Box>
                                    </Card>
                                    <Card sx={{ p: 2, mt: 2 }}>
                                          <Typography fontWeight={600} sx={{ margin: "10px 0px 10px 0px ", paddingLeft: "10px" }}>Choose Fulfillment Option</Typography>
                                          <Divider
                                                sx={{
                                                      mb: 1,
                                                      borderStyle: "solid",
                                                      borderColor: theme.palette.secondary.main,
                                                }}
                                          />
                                          {(fulfillmentOptionResp?.promise_groups || []).map((items: any, index: number) => {
                                                let productName = items?.lines.map((name: any) => name.sku_id).join(', ');
                                                let selectedValue =
                                                      items?.promise_options.filter((cv: any) => cv.option_Flag && cv.option_id).length === 0
                                                            ? ''
                                                            : items.promise_options.filter((cv: any) => cv.option_Flag && cv.option_id)[0]?.option_id;
                                                // const anyFalse = items?.promise_options.some((option: any) =>
                                                //       option.skus.some((sku: any) => sku.fulfillable_state === false)
                                                // );
                                                const defaultOptionId = items?.promise_options[0]?.option_id;

                                                return (
                                                      <div
                                                            style={{
                                                                  height: '100%',
                                                                  width: '100%',
                                                                  background: '#9b9b9b29',
                                                                  padding: '20px',
                                                                  borderRadius: '20px',
                                                                  marginBottom: '15px'
                                                            }}                                                >
                                                            <div >
                                                                  <Typography color={"primary"} fontWeight={600} flex={5} mr={1}>
                                                                        {'Items : '}{productName}
                                                                  </Typography>
                                                                  <div
                                                                        style={{
                                                                              height: '100%',
                                                                              width: '100%',
                                                                              background: '#9b9b9b29',
                                                                              padding: '20px',
                                                                              borderRadius: '20px',
                                                                              marginBottom: '15px',
                                                                              maxHeight: '330px',
                                                                              overflow: 'auto'
                                                                        }}
                                                                  >
                                                                        {items?.promise_options.length === 0 ? ('No Fulfillment options Available') : <FormControl>
                                                                              <RadioGroup
                                                                                    name="controlled-reason"
                                                                                    value={choosedFulfillmentOption[items.promise_group_id as keyof typeof choosedFulfillmentOption]}
                                                                                    onChange={(e: any) => { handleFulfillmentOptionsSelection(items.promise_group_id, e, items) }}
                                                                                    sx={{ width: '100%' }}

                                                                              >
                                                                                    {(items?.promise_options || []).map((data: any) => {
                                                                                          let amt = data?.delivery_cost?.amount / data?.delivery_cost?.fraction
                                                                                          return (
                                                                                                <div>
                                                                                                      <FormControlLabel value={data?.option_id} control={<Radio disabled={displayButtons} />} label={<Box width={"100%"} sx={{ p: 2, display: 'flex', gap: '20px' }}>
                                                                                                            <Box>
                                                                                                                  <Box display={"flex"}>
                                                                                                                        <Typography sx={{ fontWeight: "bold" }}>Delivery Method</Typography>
                                                                                                                  </Box>
                                                                                                                  {data?.delivery_speed && <Box display={"flex"}>
                                                                                                                        <Typography sx={{ fontWeight: "bold" }}>Delivery Speed</Typography>
                                                                                                                  </Box>}
                                                                                                                  <Box display={"flex"}>
                                                                                                                        <Typography fontWeight={600}>Delivery Charge</Typography>
                                                                                                                  </Box>
                                                                                                                  <Box display={"flex"}>
                                                                                                                        <Typography fontWeight={600}>
                                                                                                                              {data?.delivery_method === "PICK_UP" ? "Estimated Pickup Date" : "Estimated Delivery Date"}
                                                                                                                        </Typography>
                                                                                                                  </Box>
                                                                                                                  {
                                                                                                                        data?.delivery_method === "PICK_UP" && <Box display={"flex"}>
                                                                                                                              <Typography fontWeight={600}>Pickup From </Typography>
                                                                                                                        </Box>
                                                                                                                  }
                                                                                                            </Box>
                                                                                                            <Box>
                                                                                                                  <Box display={"flex"}>
                                                                                                                        <Typography sx={{ fontWeight: "bold" }}>{data.delivery_method === "LOCAL_DELIVERY" ? "HOME DELIVERY" : (data?.delivery_method || "").replaceAll("_", " ")}</Typography>
                                                                                                                  </Box>
                                                                                                                  <Box display={"flex"}>
                                                                                                                        <Typography sx={{ fontWeight: "bold" }}>{data.delivery_speed || ''}</Typography>
                                                                                                                  </Box>
                                                                                                                  <Box display={"flex"}>
                                                                                                                        <Typography>{!data?.delivery_cost ? 'Free' : `$ ${amt}`}</Typography>
                                                                                                                  </Box>
                                                                                                                  <Box display={"flex"}>
                                                                                                                        <Typography >{new Date(data?.promise_delivery_date).toLocaleString('en-US', {
                                                                                                                              year: 'numeric',
                                                                                                                              month: 'long',
                                                                                                                              day: 'numeric'
                                                                                                                        })}</Typography>
                                                                                                                  </Box>
                                                                                                                  {
                                                                                                                        data.delivery_method === "PICK_UP" && <Box display={"flex"}>
                                                                                                                              <Typography>{data?.ship_node_name} store</Typography>
                                                                                                                        </Box>
                                                                                                                  }
                                                                                                            </Box>
                                                                                                      </Box>} />
                                                                                                </div>
                                                                                          )
                                                                                    })}
                                                                              </RadioGroup>
                                                                        </FormControl>}
                                                                  </div>

                                                            </div>
                                                      </div>
                                                )
                                          })}
                                          <Box sx={{ display: 'flex', flexDirection: 'row-reverse', gap: '15px' }}>
                                                {!displayButtons && <PrimaryButton onClick={handleOnSave}>
                                                      <Typography fontSize={"14px"}>{loader && <PageLoader />}Select Delivery Option</Typography>
                                                </PrimaryButton>}
                                                {displayButtons && commitStatus === 'OPEN' && <PrimaryButton onClick={() => {
                                                      setDisplayButtons(false)
                                                }}>
                                                      <Typography fontSize={"14px"}>Choose Different  Delivery Option </Typography>
                                                </PrimaryButton>}
                                                {displayButtons && <Box sx={{ display: 'flex', gap: '10px' }}>
                                                      {commitStatus === 'OPEN' && <PrimaryButton onClick={() => { handleOnCommit('COMMITTED') }}>
                                                            <Typography fontSize={"14px"}>{loader && <PageLoader />}Proceed To Payment </Typography>
                                                      </PrimaryButton>}
                                                      {commitStatus === 'COMMITTED' && <PrimaryButton onClick={() => { handleOnCommit('OPEN') }}>
                                                            <Typography fontSize={"14px"}>{loader && <PageLoader />}Change Delivery Option</Typography>
                                                      </PrimaryButton>}
                                                </Box>}
                                          </Box>
                                    </Card>
                              </Grid>

                              <Grid item xs={12} sm={12}>
                                    <Card sx={{ p: 2, mt: 2 }}>
                                          <Box sx={{ width: "100%", }}>

                                                <Form ref={paymentFormRef as RefObject<Form>} hasError={hasError} model={PaymentForm({ leftOutAmount, InputProps: { readOnly: true }, disabled: !(commitStatus === 'COMMITTED') ? "none" : "" })} values={{ amount: Number(leftOutAmount || 0).toFixed(2) }} />
                                          </Box>
                                    </Card>
                              </Grid>

                              <Grid item xs={12}>
                                    <Box
                                          sx={{
                                                [theme.breakpoints.up("md")]: {
                                                      display: "flex",
                                                },
                                          }}
                                          pb={0}
                                    >
                                          <PrimaryButton onClick={(e: any) => handlePayment(e)} disabled={!(commitStatus === 'COMMITTED')}>
                                                <Box display={"flex"} alignItems={"center"} gap={1}>
                                                      <Typography fontSize={"14px"}>Pay & Checkout</Typography>
                                                      <KeyboardDoubleArrowLeftIcon fontSize="small" />
                                                </Box>
                                          </PrimaryButton>
                                    </Box>
                              </Grid>

                        </Grid>
                  </DrawerMenu>
            </>
      );
};

export default Payment;
