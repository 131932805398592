
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme, Card, Divider, Grid, RadioGroup, Radio, FormControlLabel, Select } from "@mui/material";
import React, { useState, useEffect, useRef } from 'react'
import axiosInstance from "utils/redux/axios";
import { URLS } from "utils/constants/urls";
import DropDownInput from "components/DropDownInput";
import { dataFlag } from "utils/constants/data";
import Payment from './ReplacementPayment'
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';
import { useDispatch, useSelector } from "react-redux";
import { userAddress } from "utils/redux/reducer/address-slice";
import PageLoader from "components/PageLoader";
import { setNotificationMessage } from "utils/redux/reducer/Notification-details-slice";
interface Product {
      id: number | null;
      brand: string;
      category: string;
      productCode: string;
      productName: string;
      description: string;
      imageUrl: string;
      variants: any[]; // or a more specific type if you know the structure of variants
}


interface ReturnProductType {
      orderLineId: string;
      productCode: string;
      variantCode: string;
      quantity: number;
      amount: number;
      orderedQuantity: number;
      productName: string;
      variantName: string;
      imageUrl: string;

}

const Replacement = ({ returnProducts, promiseResp, setDataItemList, dataItemList, returnOrderAmount }: { returnProducts: any, returnOrderAmount: number, promiseResp: any, setDataItemList: any, dataItemList: any }) => {
      const theme = useTheme();
      const [allProducts, setAllProducts] = useState([]);
      const [selectedProductId, setSelectedProductId] = useState("");
      const [selectedProduct, setSelectedProduct] = useState<Product>({
            id: null,
            brand: "",
            category: "",
            productCode: "",
            productName: "",
            description: "",
            imageUrl: "",
            variants: [] as any
      });
      const [promisePayLoadData, setPromisePayLoadData] = useState(null as any);
      const [address, setAddress] = useState();
      const userDetails = localStorage.getItem("userDetails");
      const [loader, setLoader] = useState<boolean>(false);
      const [openDrawer, setOpenDrawer] = useState<boolean>(false);
      const [orderDetails, setOrderDetails] = useState<any>([]);
      const [orderSummary, setOrderSummary] = useState<any>({});
      const [totalAmount, setTotalAmount] = useState<any>(null);
      const [fulfillmentOptionResp, setFulfilmentOptionResp] = useState(null as any)
      const [loaderText, setLoaderText] = React.useState(false)
      const [leftOutAmount, setLeftOutAmount] = useState(0);
      const [postalCode, setPostalCode] = useState("");
      const [variantObject, setVariantObject] = useState<any>({});
      const [quantity, setQuantity] = useState<any>(1);
      const [pincodeError, setPincodeError] = useState(false);
      const [revisionId, setRevisionId] = useState(1)
      const userAdd = useSelector(userAddress)
      const [isCartProductChangeable, setIsCartProductChangeable] = useState(true);
      const dispatch = useDispatch();

      // --------------------------------------------------------------------------
      useEffect(() => {
            if (returnProducts.length) {

                  setSelectedProductId(returnProducts[0].productCode)
            }
      }, [returnProducts])
      const getAllProducts = async () => {
            const body = {
                  filter: [],
                  sort: [],
                  page: 1,
                  size: 100,
            };
            const { status, data } = await axiosInstance.post(URLS.rewardPage, body);
            const { content, ...rest } = data;
            if (status === 200) {
                  setAllProducts(data.content.map((item: any) => ({ value: item.productCode, label: item.name })))
            }


      }

      const getSelectedProduct = async () => {
            const result = await axiosInstance.get(URLS.productWithProductCode(selectedProductId))
            if (result.status === 200) {
                  setSelectedProduct(result.data)
                  setVariantObject(result.data.variants[0])
                  setQuantity(1);


            }
      }
      const updateProducts = async () => {
            const body = {
                  "products": [
                        {
                              "article_id": returnProducts[0].productCode,
                              "category": "string",
                              "co_line_id": uuidv4(),
                              "parent_co_line_id": "string",
                              "return_parent_co_line_id": "string",
                              "sku_id": returnProducts[0].variantCode,
                              "country_of_origin": "which country ?",
                              "gtin": "string",
                              "is_courierable": true,
                              "is_dangerous": true,
                              "is_gift": true,
                              "is_returnable": true,
                              "preference": {
                                    "bag_required": true,
                                    "picker_notes": "string"
                              },
                              "product_name": returnProducts[0].productName,
                              "product_type": "string",
                              "qty_same_as_parent": true,
                              "quantity": {
                                    "number_of_units": returnProducts[0].quantity,
                                    "unit_of_measure": "string",
                                    "unit_size": 1,
                                    "unit_size_fraction": 1
                              },
                              "replacement_lines": [
                                    {
                                          "co_line_id": uuidv4(),
                                          "parent_co_line_id": "string",
                                          "return_parent_co_line_id": "string",
                                          "sku_id": variantObject.variantCode,
                                          "article_id": selectedProduct.productCode,
                                          "category": selectedProduct.category,
                                          "country_of_origin": "which country ??",
                                          "gtin": "string",
                                          "is_courierable": true,
                                          "is_dangerous": true,
                                          "is_gift": true,
                                          "is_returnable": true,
                                          "preference": {
                                                "bag_required": true,
                                                "picker_notes": "string"
                                          },
                                          "product_name": selectedProduct.productName,
                                          "product_type": "string",
                                          "promise_line_id": "string",
                                          "qty_same_as_parent": false,
                                          "quantity": {
                                                "number_of_units": quantity,
                                                "unit_of_measure": "string",
                                                "unit_size": 1,
                                                "unit_size_fraction": 1
                                          },
                                          "requires_shipping": true
                                    }
                              ],
                              "requires_shipping": true
                        }
                  ]
            }
            const result = await axiosInstance.put(URLS.products(promiseResp.promise_id), body, {
                  headers: {
                        'revision': revisionId
                  }
            })

      }
      useEffect(() => {
            if (userAdd) {
                  setPostalCode(getPostalCode(userAdd));
            }
      }, [userAdd])
      useEffect(() => {
            getAllProducts();
      }, [])

      useEffect(() => {
            if (selectedProductId)
                  getSelectedProduct();
      }, [selectedProductId])

      const getPostalCode = (data: any) => {
            let code = ""
            if (data) {
                  if (data.length > 0) {
                        code = data[0].addressType == "SHIPPPING" ? data[0].pinCode : data[1].pinCode
                  }
            }
            return code;
      }

      const selectedProductChange = (value: string) => {

            setSelectedProductId(value)
      }

      useEffect(() => {
            if (variantObject.price)
                  setLeftOutAmount(variantObject.price - Math.abs(returnOrderAmount))

      }, [variantObject])
      useEffect(() => {
            if (variantObject?.id) {

                  (async () => {
                        // setRevisionId((p) => p + 1)
                        await updateProducts();

                        await placeOrderFunc(variantObject.id, 1, postalCode, {
                              sku_id: variantObject?.variantCode,
                              quantity: {
                                    number_of_units: quantity,
                                    unit_size: '1',
                                    unit_size_fraction: '1',
                                    unit_of_measure: 'EA'
                              }
                        })
                  })();

            }


      }, [variantObject, quantity])
      const selectedVariantChange = (id: string) => {
            const obj: any = selectedProduct.variants.find((item: any) => item.id === id)
            setVariantObject(obj as any)
            setQuantity(1)
      }

      const placeOrderFunc = async (code: number, unknownQty: number, pincode: string, itemList: any) => {
            setLoader(true)
            const body = {
                  "billingAddressId": 2222,
                  "billingAddressName": "string",
                  "countryCode": "CL",
                  "currencyCode": "CL",
                  "orders": [
                        {
                              "orderLineId": uuidv4(),
                              "quantity": quantity,
                              "variantId": code
                        }
                  ],
                  "payment": [
                        {
                              "amount": 0,
                              "currency_code": "CL",
                              "payment_datetime": "string",
                              "payment_gateway": "string",
                              "payment_ref_id": "string",
                              "payment_type": "CASH",
                              "transaction_id": "string"
                        }
                  ],
                  "posNo": "string",
                  "promiseId": "string",
                  "promiseVersion": "string",
                  "redeemFromLoyaltyPoints": true,
                  "shippingAddressId": 3333333,
                  "shippingAddressName": "string",
                  "storeId": "string",
                  "supplyTypeCode": "string"
            }
            try {


                  const { status, data } = await axiosInstance.post(URLS.getOrderSummary, body);
                  if (status === 200) {
                        setOrderDetails(body);
                        setOrderSummary(data);
                        // fetchAddress(pincode, itemList)
                        getFeasiableFullFillmentOption(promiseResp, dataItemList?.pinCode, returnProducts)

                  }
            } catch (error: any) {
                  setLoader(false)
                  let errorMessage = 'An unknown error occurred';
                  if (error?.response && error?.response.data) {
                        errorMessage = error.response.data.error_description || error.response.data.message || errorMessage;
                  }

            }


      };

      // const handleOnClickFeasibleFulfillment = async (pincode: string, itemList: any, addressData: any) => {
      //       const emailId = userDetails ? JSON.parse(userDetails).email : null
      //       let shipToPostCode = pincode
      //       let countryCode = "CL"
      //       let productString = JSON.stringify(itemList)
      //       let convertHeaderData = shipToPostCode + countryCode + productString
      //       const hash = CryptoJS.MD5(convertHeaderData).toString();

      //       let bodyData = {
      //             cart_id: uuidv4(),
      //             order_type: 'FRW',
      //             channel: 'ONLINE',
      //             transaction_start_datetime: new Date().toISOString(),
      //             source_trigger: 'CART',
      //             currency_code: 'CLP',
      //             customer_profile_info: {
      //                   "profile_id": emailId,
      //                   "profile_type": "CONSUMER"
      //             },
      //             store_id: "ejje-dotcom-001",
      //             pos_id: '1',
      //             "products": [
      //                   {
      //                         "co_line_id": uuidv4(),
      //                         "quantity": itemList.quantity,
      //                         "sku_id": itemList.sku_id
      //                   }
      //             ],
      //             "ship_to_address": {
      //                   "address_id": addressData[0].addressType == "SHIPPING" ? addressData[0].addressId : addressData[1].addressId,
      //                   "phone_numbers": [
      //                         {
      //                               "type": "PERSONAL",
      //                               "country_code": "CL",
      //                               "number": "1212121212"
      //                         }
      //                   ],
      //                   "country_code": "CL",
      //                   "postal_code": pincode
      //             },
      //       };
      //       setPromisePayLoadData(bodyData)

      //       try {
      //             const config = {
      //                   headers: {
      //                         'revision': hash,
      //                   },
      //             };
      //             const { status, data } = await axiosInstance.post(URLS.promise, bodyData, config as any)
      //             if (status === 201) {
      //                   let listData = {
      //                         pinCode: pincode,
      //                         items: itemList,
      //                         promiseId: data?.promise_id,
      //                         revision: hash
      //                   }
      //                   setPromiseResp(data)
      //                   setDataItemList(listData)
      //                   updateDeliveryLocation(data, pincode, itemList)
      //             }
      //       } catch (err: any) {
      //             setPincodeError(true);
      //             setLoader(false)

      //       }
      // }

      // const updateDeliveryLocation = async (promiseResp: any, pincode: any, itemList: any) => {
      //       let shipToPostCode = pincode
      //       let countryCode = "CL"
      //       let productString = JSON.stringify(itemList)
      //       let convertHeaderData = shipToPostCode + countryCode + productString
      //       const hash = CryptoJS.MD5(convertHeaderData).toString();

      //       const bodyData = {
      //             address_id: 'string',
      //             country: "CL",
      //             country_code: "CL",
      //             postal_code: pincode,
      //             phone_numbers: [
      //                   {
      //                         availability: 'string',
      //                         country_code: '+91',
      //                         number: '11111111111',
      //                         type: 'PERSONAL'
      //                   }
      //             ]
      //       };

      //       try {
      //             const config = {
      //                   headers: {
      //                         'revision': hash,
      //                   },
      //             };

      //             const { status, data } = await axiosInstance.put(URLS.addressPromise(promiseResp?.promise_id), bodyData, config as any)
      //             if (status === 200) {
      //                   // replaceAllProducts(data, pincode, itemList)
      //                   getFeasiableFullFillmentOption(data, pincode, itemList)
      //             }
      //       } catch (err: any) {
      //             setLoader(false);
      //       }
      // }
      const getProducts = (itemList: any) => {
            let bdyData = {
                  "products": [
                        {
                              // "category_id": "11420",
                              "country_of_origin": "CHL",
                              "gtin": "string",
                              "is_courierable": true,
                              "is_dangerous": true,
                              "is_gift": true,
                              "is_returnable": true,
                              "preference": {
                                    "bag_required": true,
                                    "picker_notes": "string"
                              },
                              "co_line_id": uuidv4(),//random
                              // "product_class": "Pet food",
                              // "product_id": "11420",
                              // "product_name": "Belcando water buffalo 400 GR",
                              // "product_type": "Pet food",
                              "qty_same_as_parent": true,
                              "quantity": itemList.quantity,
                              "requires_shipping": true,
                              "sku_id": itemList.sku_id
                        }
                  ]
            }
            return bdyData;
      }

      const getFeasiableFullFillmentOption = async (promiseResp: any, pincode: any, itemList: any) => {
            let shipToPostCode = pincode
            let countryCode = "CL"
            let productString = JSON.stringify(itemList)
            let convertHeaderData = shipToPostCode + countryCode + productString
            const hash = CryptoJS.MD5(convertHeaderData).toString();
            const bodyData = getProducts(itemList);
            try {
                  const config = {
                        headers: {
                              'revision': revisionId,
                        },
                  };
                  const { status, data } = await axiosInstance.post(URLS.fullFilmentOptions(promiseResp?.promise_id), bodyData, config as any)
                  if (status === 200) {
                        setFulfilmentOptionResp(data)
                        setLoader(false);

                  }
            } catch (err: any) {
                  setLoader(false)
            }
      }

      const handleOnselection = async (bodyData: any) => {
            let shipToPostCode = dataItemList?.items
            let countryCode = "CL"
            let productString = JSON.stringify(dataItemList?.items)
            let convertHeaderData = shipToPostCode + countryCode + productString
            const hash = CryptoJS.MD5(convertHeaderData).toString();
            setLoader(true);
            try {
                  const config = {
                        headers: {
                              'revision': revisionId,
                        },
                  };
                  const { status, data } = await axiosInstance.post(URLS.selection(promiseResp.promise_id), bodyData, config as any)
                  if (status === 200) {
                        // handleError('Selection Success !', 'success')

                  }
            } catch (err: any) {
                  setLoader(false);

            }
            setLoader(false);
      }
      const handleQuantityChange = (value: any) => {
            setQuantity(value)
            setLeftOutAmount((variantObject.price * value) - Math.abs(returnOrderAmount))

      }
      const onMouseEnterInsideBox = () => {
            if (!isCartProductChangeable) {
                  dispatch(setNotificationMessage({
                        display: true,
                        severity: "information",
                        message: "Uncommit To Change",
                  }));
            }
      }
      return (
            <Box >
                  {loader && <PageLoader />}
                  <Box sx={{ pointerEvents: isCartProductChangeable ? "all" : "none" }} onMouseEnter={onMouseEnterInsideBox}>
                        <Box sx={{ display: "grid", gridTemplateColumns: "1fr", }}>
                              <Typography fontWeight={600} sx={{ margin: "10px", paddingLeft: "10px" }}>
                                    Replace Item With
                              </Typography>
                              <Box sx={{ m: "20px" }}>
                                    <DropDownInput
                                          textChange={selectedProductChange}
                                          inputValue={`${selectedProduct.productCode}`}
                                          size="small"
                                          options={allProducts}
                                          label="Product"

                                    />
                                    <DropDownInput
                                          textChange={selectedVariantChange}
                                          inputValue={`${variantObject.id}`}
                                          size="small"
                                          options={selectedProduct.variants.map((item: any) => ({ label: item.name, value: item.id }))}
                                          label="Variant"


                                    />
                              </Box>
                        </Box>
                        <Divider
                              sx={{
                                    mb: 1,
                                    borderStyle: "solid",
                                    borderColor: theme.palette.secondary.main,
                              }}
                        />
                        <Box sx={{ p: 2 }}>
                              {selectedProduct && [
                                    selectedProduct
                              ]?.map((i: any, index: number) => (
                                    <div key={index}>
                                          <Box display={"flex"} gap={1}>
                                                <img src={i.imageUrl} height={"40px"} alt="image" />
                                                <Typography fontWeight={600}>
                                                      {i.productName} - {i.productCode}
                                                </Typography>
                                          </Box>
                                          <Box display={"flex"} justifyContent="space-between" alignItems={"center"} mx={1} mt={1}>
                                                {/* <Grid item xs={0.8}><CircleIcon sx={{ fontSize: '8px' }} /></Grid> */}
                                                <Typography >
                                                      {variantObject.name} *{quantity}
                                                </Typography>
                                                <Box sx={{ width: "70px" }}>

                                                      <DropDownInput
                                                            textChange={handleQuantityChange}
                                                            inputValue={`${quantity}`}
                                                            size="small"
                                                            options={Array.from({ length: variantObject.quantity }, (_, i) => i + 1).map((item: any) => ({ label: item, value: item }))}
                                                            label="Quantity"


                                                      />
                                                </Box>
                                          </Box>
                                    </div>
                              ))}

                              <Divider
                                    sx={{
                                          my: 1,
                                          borderStyle: "dashed",
                                          borderColor: theme.palette.secondary.main,
                                    }}
                              />
                              <Box display={"flex"} justifyContent="space-between" mx={1}>
                                    <Typography color={"primary"} fontWeight={600} flex={5} mr={1}>
                                          {"Total"}
                                    </Typography>
                                    <Typography color={"primary"} fontWeight={600} flex={4} textAlign={"end"}>
                                          {(variantObject.price * quantity || 0).toFixed(2)}
                                    </Typography>
                              </Box>
                        </Box>
                  </Box>

                  {leftOutAmount < 0 && <Typography sx={{ paddingLeft: "20px" }}>Refund Amount :{Math.abs(leftOutAmount).toFixed(2)}</Typography>}
                  {pincodeError && <Typography sx={{ paddingLeft: "20px", color: "red" }}>Product not deliverable to this <span style={{ fontWeight: "bold" }}> {postalCode}</span> postal code </Typography>}
                  {!pincodeError && promiseResp && fulfillmentOptionResp &&
                        <Payment
                              setIsCartProductChangeable={setIsCartProductChangeable}
                              key={(`${quantity}+${selectedProductId}+${variantObject.id}` as unknown) as React.Key}
                              orderDetails={{
                                    "orders": [
                                          {
                                                "orderLineId": uuidv4(),
                                                "quantity": 1,
                                                "variantId": selectedProduct?.id
                                          }
                                    ],
                              }}
                              variantObject={variantObject}
                              quantity={quantity}
                              leftOutAmount={leftOutAmount >= 0 ? leftOutAmount : 0}
                              orderSummary={{ item: [selectedProduct] }}
                              promisePayLoadData={promisePayLoadData}
                              address={address}
                              fullfilmentOptions={fulfillmentOptionResp}
                              handleOnselection={handleOnselection}
                              promiseResp={promiseResp}
                              dataItemList={dataItemList}
                              getFeasiableFullFillmentOption={getFeasiableFullFillmentOption}
                              addressData={address}
                        />}
            </Box>
      );
};

export default Replacement;
