import { createSlice } from "@reduxjs/toolkit";
import { removeFromStorage, saveToStorage } from "../../storage";

interface SearchedDataState {
  searchedData: any;
  searchFormData: any;
  venueList: any;
  selectedOrg: any,
  eventTypeList: any,
  timeSlotList: any,
}
const searchedDataSlice = createSlice({
  name: "searchedDataSlice",
  initialState: {
    searchedData: [],
    searchFormData: {},
    venueList: {},
    selectedOrg: null,
  },
  reducers: {
    setSearchedData(state, action) {
      state.searchedData = action.payload;
      saveToStorage('searchedData', JSON.stringify(action.payload), true);
    },
    setSearchFormData(state, action) {
      state.searchFormData = action.payload;
      saveToStorage('searchFormData', JSON.stringify(action.payload), true);
    },
    setVenueList(state, action) {
      state.venueList = action.payload;
      saveToStorage('venueList', JSON.stringify(action.payload), true);
    },
    setSelectedOrg(state, action) {
      state.selectedOrg = action.payload;
      saveToStorage('selectedOrg', JSON.stringify(action.payload), true);
    },
    setSearchDataSliceInitState(state, action) {
      state.searchedData = [];
      removeFromStorage('searchedData', true);
      state.searchFormData = {};
      removeFromStorage('searchFormData', true);
      state.venueList = {};
      removeFromStorage('venueList', true);
      state.selectedOrg = null;
      removeFromStorage('selectedOrg', true);
    }
  },

});

export default searchedDataSlice.reducer;

export const { setSearchedData, setSearchFormData, setVenueList, setSelectedOrg, setSearchDataSliceInitState } = searchedDataSlice.actions;

export const searchedData = (state: { searchedDataSlice: SearchedDataState }) =>
  state.searchedDataSlice.searchedData;
export const searchFormData = (state: { searchedDataSlice: SearchedDataState }) =>
  state.searchedDataSlice.searchFormData;
export const venueList = (state: { searchedDataSlice: SearchedDataState }) =>
    state.searchedDataSlice.venueList;    
export const selectedOrg = (state: { searchedDataSlice: SearchedDataState }) =>
state.searchedDataSlice.selectedOrg;  


