import { Button, Divider, FormControl, FormControlLabel, Modal, Paper, Radio, RadioGroup, TextareaAutosize, Typography } from "@mui/material";
import { Box } from "@mui/system";
import strings from "common/Translation/Translate";
import React, { FC } from "react";
import { images } from "utils/constants/images";

interface IReturnOrderProps { }

const ReasonToReturn = [
    {
        id: 1,
        reason: 'Wrong Item Recived',
        possibility: ['Refund']
    },
    {
        id: 2,
        reason: `Item does'nt match as described`,
        possibility: ['Refund']
    },
    {
        id: 3,
        reason: `Defective Or Damage Item`,
        possibility: ['Refund', 'Replacement']
    },
    {
        id: 4,
        reason: `Size/Fit Issue`,
        possibility: ['Refund', 'Replacement']
    },
    {
        id: 5,
        reason: `Changed Mind/Not Needed`,
        possibility: ['Refund']
    },
    {
        id: 6,
        reason: `Unsatisfactory Quality`,
        possibility: ['Refund', 'Replacement']
    },
    {
        id: 7,
        reason: `Late Delivery`,
        possibility: ['Refund']
    },
    {
        id: 8,
        reason: `Duplicate Order`,
        possibility: ['Refund']
    },
    {
        id: 9,
        reason: `Recived Wrong Quantity`,
        possibility: ['Refund']
    },
    {
        id: 10,
        reason: `Gift Return`,
        possibility: ['Refund']
    },
    {
        id: 11,
        reason: `Price discrepancy`,
        possibility: ['Refund']
    },
    {
        id: 12,
        reason: `Ordered the wrong item`,
        possibility: ['Refund']
    },
    {
        id: 13,
        reason: `Product doe'nt work as expected`,
        possibility: ['Refund']
    },
    {
        id: 14,
        reason: `Change in Circumstance`,
        possibility: ['Refund']
    },
    {
        id: 15,
        reason: `Product Expired`,
        possibility: ['Refund']
    },
    {
        id: 16,
        reason: `Missing Accessories`,
        possibility: ['Refund', 'Replacement']
    },
    {
        id: 17,
        reason: `Recived wrong color`,
        possibility: ['Refund']
    },
    {
        id: 18,
        reason: `Not as Advertised`,
        possibility: ['Refund']
    },
    {
        id: 19,
        reason: `Not Satisfactory`,
        possibility: ['Refund']
    },
    {
        id: 20,
        reason: `Others`,
        possibility: ['Refund']
    }
]

const ReturnOrder: FC<IReturnOrderProps> = () => {
    const [ResonData, setReasonData] = React.useState(ReasonToReturn as []);
    const [selectedReason, setSelectedReason] = React.useState(0);
    const [userNeeds, setUserNeeds] = React.useState('Refund');
    const [showStatus, setShowStatus] = React.useState([] as any);
    const [selectdRefund, setSelectedRefund] = React.useState(0);
    const [fulfillmentOption, setFulfilmentOptions] = React.useState('collect_from_pickup')
    const [pickUpModal, setPickUpModal] = React.useState(false)
    const [collectModal, setCollectModal] = React.useState(false)

    const handleChangeReason = (event: any, data: any) => {
        let numData = Number(data)
        setSelectedReason(numData);
        let selectionData = ResonData[numData - 1]
        setShowStatus(selectionData)
    }

    const handleuserNeedChange = (event: any) => {
        setUserNeeds(event?.target?.value)
    }

    const handleOnRefundType = (event: any) => {
        setSelectedRefund(event?.target?.value)
    }

    const handleOnFulfilment = (event: any) => {
        setFulfilmentOptions(event?.target?.value)
    }

    const handleClickOnContinue = (e: any) => {
        if (userNeeds !== 'Refund' && fulfillmentOption === 'collect_from_pickup') {
            setPickUpModal(true)
        } else if (userNeeds === 'Replacement' && fulfillmentOption === 'pick_up') {
            setCollectModal(true)
        }
    }

    const handleOnpickUpModalClose = () => {
        setPickUpModal(prveProp => !(prveProp))
    }

    const handleOnCollectiveModalClose = () => {
        setCollectModal(prveProp => !(prveProp))
    }


    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '25px 0px'
        }}>
            <Box sx={{
                width: '70%',
                padding: '10px'
            }}>
                <Typography sx={{ fontSize: '20px', fontWeight: 600 }}> {strings.returnOrder} :</Typography>
            </Box>

            <Paper sx={{
                width: '70%',
                padding: '10px',
                display: { xs: 'flex' },
                flexDirection: { xs: 'column' },
                alignItems: { xs: 'center', md: 'flex-start' }
            }}>
                <Typography sx={{ fontSize: '15px' }}> {'Order Id: #08192'} </Typography>
                <Box sx={{
                    display: { xs: 'flex' },
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { xs: 'center', md: 'center' },
                    gap: { xs: '0px', md: '35px' }
                }}>
                    <Box>
                        <img src={images.pack} alt='pack' />
                    </Box>
                    <Box>
                        <Typography sx={{
                            font: 'normal normal 700 14px/20px Arial, sans-serif'
                        }}> DEEP Deluxe Iron CI Casting Puri Maker</Typography>
                        <Typography sx={{
                            font: 'normal normal 400 12px/16px Arial, sans-serif'
                        }}>$799.00</Typography>
                    </Box>
                </Box>
                <hr style={{ width: '100%' }} />
                <Box sx={{
                    width: '100%', display: { xs: 'flex' },
                    flexDirection: { xs: 'column', md: 'row' },
                    // alignItems: { xs: 'center', md: 'center' },
                    gap: { xs: '0px', md: '35px' }
                }}>
                    <Paper sx={{
                        padding: '10px',
                        width: { xs: '100%', md: '40%' },
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: 'space-between',
                    }}>
                        <Typography sx={{ fontSize: '15px', minWidth: '135px', fontWeight: 600 }}> {'Select the Reason :'} </Typography>
                        <Box sx={{ maxHeight: '250px', overflow: 'auto' }}>
                            <FormControl>
                                <RadioGroup
                                    name="controlled-reason"
                                    value={selectedReason}
                                    onChange={handleChangeReason}
                                >
                                    {ResonData.map((data: any) => {
                                        return (
                                            <div>
                                                <FormControlLabel value={data.id} control={<Radio />} label={data.reason} />
                                            </div>
                                        )
                                    })}
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Paper>
                    {selectedReason !== 0 && <Paper sx={{
                        padding: '10px',
                        display: 'flex',
                        width: { xs: '100%', md: '55%' },
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: 'space-between',
                        marginTop: { xs: '10px', md: '0px' }
                    }}>
                        {showStatus && <Box style={{ marginTop: '5px' }}>
                            <Typography sx={{ fontSize: '20px', fontWeight: 600 }}> {'Return Method'} :</Typography>
                            <FormControl>
                                <RadioGroup
                                    name="controlled-needs"
                                    value={userNeeds}
                                    onChange={handleuserNeedChange}
                                    sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}
                                >
                                    {showStatus?.possibility?.map((item: any) => {
                                        return (
                                            <Box>
                                                <FormControlLabel value={item} control={<Radio />} label={item} />
                                            </Box>
                                        )
                                    })}
                                </RadioGroup>
                            </FormControl>
                            {selectedReason !== 0 && <Box sx={{ padding: '5px', width: '100%' }}>
                                {userNeeds === 'Refund' ? <Box>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 600 }}> {'Refund Amount : $100'}</Typography>
                                    <FormControl>
                                        <RadioGroup
                                            name="controlled-refund"
                                            value={selectdRefund}
                                            onChange={handleOnRefundType}
                                        // sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}
                                        >
                                            <FormControlLabel value="credit_card" control={<Radio />} label="Refund To Credit/Bank Card" />
                                            <FormControlLabel value="loyality_recharge" control={<Radio />} label="Refund To Loyality charges" />
                                        </RadioGroup>
                                    </FormControl>
                                </Box> : <Box>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 600 }}> {'Exchange Options'}</Typography>
                                    <FormControl>
                                        <RadioGroup
                                            name="controlled-fullfilment"
                                            value={fulfillmentOption}
                                            onChange={handleOnFulfilment}
                                            sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}
                                        >
                                            <FormControlLabel value="collect_from_pickup" control={<Radio />} label="Drop-off To Collection Point" />
                                            <FormControlLabel value="pick_up" control={<Radio />} label="Carrier Pickup" />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>}
                            </Box>}
                            {userNeeds === 'Replacement' && <Box>
                                {fulfillmentOption === 'collect_from_pickup' ? <Box>
                                    <Typography sx={{
                                        font: 'normal normal 600 18px/16px Arial, sans-serif',
                                        padding: '5px'
                                    }}>{`Collect At : `}</Typography>
                                    <Typography sx={{
                                        font: 'normal normal 500 14px/16px Arial, sans-serif',
                                        padding: '5px'
                                    }}>{`Return Date : ${new Date().toISOString().split('T')[0]}`}</Typography>
                                    <Typography sx={{
                                        font: 'normal normal 500 14px/16px Arial, sans-serif',
                                        padding: '5px'
                                    }}>{`Time slot : 9 am to 6 pm`}</Typography>
                                    <Typography sx={{
                                        font: 'normal normal 500 14px/16px Arial, sans-serif',
                                        padding: '5px'
                                    }}>{` Collect Adress`}</Typography>
                                </Box> : <Box>
                                    <Typography sx={{
                                        font: 'normal normal 600 18px/16px Arial, sans-serif',
                                        padding: '5px'
                                    }}>{`Pick up At :`}</Typography>
                                    <Typography sx={{
                                        font: 'normal normal 500 14px/16px Arial, sans-serif',
                                        padding: '5px'
                                    }}>{`Pick up Date : ${new Date().toISOString().split('T')[0]}`}</Typography>
                                    <Typography sx={{
                                        font: 'normal normal 500 14px/16px Arial, sans-serif',
                                        padding: '5px'
                                    }}>{`Pick up Time slot : 9 am to 6 pm`}</Typography>
                                    <Typography sx={{
                                        font: 'normal normal 500 14px/16px Arial, sans-serif',
                                        padding: '5px'
                                    }}>{`Pick up Address`}</Typography>
                                </Box>}
                            </Box>}
                            {/* <Box>{selectedReason !== 0 && <Paper sx={{ padding: '5px', width: '100%' }}>Test</Paper>}</Box> */}
                            <Box></Box>
                        </Box>}
                    </Paper>}
                </Box>
                {selectedReason === 20 && <Box sx={{ width: '100%', height: '100px', padding: '15px' }}>
                    <TextareaAutosize name="description" placeholder="Enter Reason" style={{ width: '100%', height: '100%', padding: '5px' }} />
                </Box>}
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row-reverse' }}>
                    <Button variant="contained" sx={{ color: "#ffffff", marginTop: '10px' }} onClick={(e) => handleClickOnContinue(e)}>
                        {/* onClick={(e) => handleSave(e)} */}
                        {'Continue'}
                    </Button>
                </Box>
                <Modal
                    open={pickUpModal}
                    onClose={handleOnpickUpModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute' as const,
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper',
                            borderRadius: '20px',
                            boxShadow: 24,
                            p: 3,
                            width: '40vw',
                            // minHeight: '50vh',
                            ':focus': { border: 0 },
                        }}>
                        <Box sx={{
                            textAlign: 'center'
                        }}><Typography sx={{
                            font: 'normal normal 600 18px/22px Arial, sans-serif',
                            padding: '5px 0px 5px 0px'
                        }}>Your Pick up is arranged for {new Date().toISOString().split('T')[0]} between 9 Am to 10 Pm, The phonu number provided to carrier for the pick-up is 999999999 </Typography></Box>
                        <Box sx={{ padding: '25px 0px' }}>
                            <Box sx={{ display: 'flex' }}>
                                <Typography sx={{
                                    font: 'normal normal 500 15px/16px Arial, sans-serif',
                                    padding: '5px 0px 5px 0px',
                                    minWidth: '170px'
                                }}>{`Pick up Address `}</Typography>
                                <Typography>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</Typography>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Typography sx={{
                                    font: 'normal normal 500 15px/16px Arial, sans-serif',
                                    padding: '5px 0px 5px 0px',
                                    minWidth: '170px'
                                }}>{`Pick up Date`}</Typography>
                                <Typography>{new Date().toISOString().split('T')[0]}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Typography sx={{
                                    font: 'normal normal 500 15px/16px Arial, sans-serif',
                                    padding: '5px 0px 5px 0px',
                                    minWidth: '170px'
                                }}>{`Pick up Time Slot`}</Typography>
                                <Typography>9 Am to 10 Pm</Typography>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Typography sx={{
                                    font: 'normal normal 500 15px/16px Arial, sans-serif',
                                    padding: '5px 0px 5px 0px',
                                    minWidth: '170px'
                                }}>{`Carrier Tracking Id`}</Typography>
                                <Typography>#08192</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row-reverse', gap: '5px' }}>
                            <Button variant="contained" sx={{ color: "#ffffff", marginTop: '10px' }} onClick={handleOnpickUpModalClose}>
                                {'Confirm'}
                            </Button>
                            <Button variant="contained" sx={{ color: "#ffffff", marginTop: '10px' }} onClick={handleOnpickUpModalClose}>
                                {'Cancel'}
                            </Button>
                        </Box>
                    </Box>
                </Modal>
                <Modal
                    open={collectModal}
                    onClose={handleOnpickUpModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute' as const,
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper',
                            borderRadius: '20px',
                            boxShadow: 24,
                            p: 3,
                            width: '40vw',
                            // minHeight: '50vh',
                            ':focus': { border: 0 },
                        }}>
                        <Box sx={{
                            textAlign: 'center'
                        }}><Typography sx={{
                            font: 'normal normal 600 18px/22px Arial, sans-serif',
                            padding: '5px 0px 5px 0px'
                        }}>Your return to collection point is arranged for {new Date().toISOString().split('T')[0]} between 9 Am to 10 Pm</Typography></Box>
                        <Box sx={{ padding: '25px 0px' }}>
                            <Box sx={{ display: 'flex' }}>
                                <Typography sx={{
                                    font: 'normal normal 500 15px/16px Arial, sans-serif',
                                    padding: '5px 0px 5px 0px',
                                    minWidth: '170px'
                                }}>{`Collection Point Address `}</Typography>
                                <Typography>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</Typography>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Typography sx={{
                                    font: 'normal normal 500 15px/16px Arial, sans-serif',
                                    padding: '5px 0px 5px 0px',
                                    minWidth: '170px'
                                }}>{`Return Date`}</Typography>
                                <Typography>{new Date().toISOString().split('T')[0]}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Typography sx={{
                                    font: 'normal normal 500 15px/16px Arial, sans-serif',
                                    padding: '5px 0px 5px 0px',
                                    minWidth: '170px'
                                }}>{`ReturnTime Slot`}</Typography>
                                <Typography>9 Am to 10 Pm</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row-reverse', gap: '5px' }}>
                            <Button variant="contained" sx={{ color: "#ffffff", marginTop: '10px' }} onClick={handleOnCollectiveModalClose}>
                                {'Confirm'}
                            </Button>
                            <Button variant="contained" sx={{ color: "#ffffff", marginTop: '10px' }} onClick={handleOnCollectiveModalClose}>
                                {'Cancel'}
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </Paper>
        </Box>
    )
}

export default ReturnOrder