import { FC } from 'react';
import { Navigate, Route, Routes as Switch } from 'react-router-dom';
import { AlertColor, Box, Grid, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Header from './AuthenticatedLayout';
import { routes } from '../utils/constants/routes';
import Notification from '../components/Notification';
import {
  NotificationDisplay,
  NotificationMessage,
  NotificationSeverity,
  setNotificationMessage,
} from '../utils/redux/reducer/Notification-details-slice';
import NotFound from '../screens/NotFound';
import RewardsPage from 'screens/RewardsPage';
import { Authenticated } from 'utils/redux';
import UserProfile from 'screens/UserProfile';
import { Terms } from './AuthenticatedLayout/Footer/Terms';
import { Privacy } from './AuthenticatedLayout/Footer/Privacy';
import { About } from './AuthenticatedLayout/Footer/About';
import { Contact } from './AuthenticatedLayout/Footer/Contact';
import ProductDetails from 'screens/ProductDetails';
import { Cancellation } from './AuthenticatedLayout/Footer/Cancellation';
import ReturnOrder from 'screens/ReturnOrder';
import Cart from 'screens/Cart';

// const Root: FC = () => {
//   let location = useLocation();
//   const isLogin = useSelector(Authenticated);

//   const { from } = location.state || { from: { pathname: routes.bookings } };
//   return isLogin ? <Navigate to={from} /> : <Navigate to={routes.sign_up} />;
// };
const AuthenticatedScreens: FC<{ Component: FC }> = ({ Component }) => {
  const isLogin = useSelector(Authenticated);

  return isLogin ? <Component /> : <Navigate to={routes.root} />;
}
const Routes: FC = () => {
  const dispatch = useDispatch();
  const notificationDisplay = useSelector(NotificationDisplay);
  const notificationSeverity = useSelector(NotificationSeverity);
  const notificationMessage = useSelector(NotificationMessage);

  return (
    <Grid item container flexDirection="column">
      <Grid className="pageWrapper" item sx={{ width: '100%', height: '100vh' }}>
        <Paper elevation={0} square={false} className="paperWrapper">
          <Notification
            isOpen={notificationDisplay}
            message={notificationMessage}
            severity={notificationSeverity as AlertColor}
            autoHideDuration={6000}
            closeNotification={value =>
              dispatch(
                setNotificationMessage({
                  display: value,
                  severity: notificationSeverity,
                  message: notificationMessage,
                }),
              )
            }
          />
          <Header>
            <Switch>
              <Route path={routes.root} element={<RewardsPage />} />
              <Route path={routes.user_profile} element={<AuthenticatedScreens Component={UserProfile} />} />
              <Route path={routes.returnOrder} element={<AuthenticatedScreens Component={ReturnOrder} />} />
              <Route path={routes.terms} element={<Terms />} />
              <Route path={`${routes.productDetails}/:id`} element={<ProductDetails />} />
              <Route path={routes.privacyPolicy} element={<Privacy />} />
              <Route path={routes.about} element={<About />} />
              <Route path={routes.contact} element={<Contact />} />
              <Route path={routes.cancellation} element={<Cancellation />} />
              <Route path="*" element={<NotFound />} />
            </Switch>
          </Header>
          <Box sx={{ zIndex: 12 }}><Cart /></Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Routes;
