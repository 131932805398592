export const identifiers: { [key: string]: string } = {
	access_token: "access_token",
	translation: "translation",
	languages: "languages",
	privilegeList: "privilege_list",
	refresh_token: "refresh_token",
	date_format: "DD/MM/YYYY",
	date_input: "YYYY-MM-DD",
	field_error: "This Field is Required",
	filters: "filters",
	selectedLanguage: "selectedLanguage",
	theme: "theme",
	initialData: "initialData",
	preview: "preview",
	userDetails: "userDetails",
	password_validation:
		"Password length should be between 8 and 15 characters and must contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character",
	REACT_APP_CAPTCHA: "6LcB6M0pAAAAAMp333JbxitMU4q8R2oPwAWfenpv",
};
