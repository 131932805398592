import { Grid, ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/pt';
import './App.css';
import Routes from './routes';

function App() {
  const selectedlangLocaleCode = 'en';
  const themes = {
    primary: '#1890FF',
    primaryTextColor: '#FFFFFF',
    secondaryMain: '#001529',
    secondaryTextColor: '#FFFFFF',
    secondaryDark: '#001529',
    secondaryLight: '#FFFFFF',
    hoverColor: '#002242',
  } as any;
  const theme = createTheme({
    // direction: 'rtl',
    palette: {
      mode: 'light',
      primary: {
        main: themes.primary,
        contrastText: themes.primaryTextColor,
      },
      divider: '#707070',
      action: {
        hover: 'lightgrey',
      },
      secondary: {
        main: themes.secondaryMain,
        dark: themes.secondaryDark,
        light: themes.secondaryLight,
        contrastText: themes.secondaryTextColor,
      },
    },
    typography: {
      fontFamily: ['Roboto'].join(','),
      fontSize: 12,
    },
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            boxShadow: '0px 4px 62px rgba(0, 0, 0, 0.08)',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          contained: {
            backgroundImage: themes?.buttonGradient,
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            ':last-of-type': {
              borderRadius: 0,
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: '0.75rem',
            marginLeft: 0,
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={responsiveFontSizes(theme)}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={selectedlangLocaleCode}>
        <Grid container className="main-body">
          <Routes />
        </Grid>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
