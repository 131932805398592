import { useEffect } from 'react';

export const Contact = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <div
      style={{ margin: '0px 30px' }}
      dangerouslySetInnerHTML={{
        __html:
          "<h3>Clover Bay Labs Pvt Ltd</h3><h5>38, Sarjapur - Marathahalli Rd, Jakkasandra, 1st Block Koramangala, Koramangala, Bengaluru, Karnataka 560034</h5><h5>9448000108</h5>",
      }}></div>
  );
};
