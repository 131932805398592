import { AlertColor, Button, Card, CardContent, CardMedia, Chip, CircularProgress, Divider, FormControl, Grid, MenuItem, Modal, Pagination, Popover, Select, Skeleton, Switch, Typography, useMediaQuery } from '@mui/material';
import { Box, Stack, useTheme } from '@mui/system';
import InputField from 'components/InputField';
import PageLoader from 'components/PageLoader';
import { FC, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { URLS } from 'utils/constants/urls';
import axiosInstance from 'utils/redux/axios';
import { setNotificationMessage } from 'utils/redux/reducer/Notification-details-slice';
import './rewardsPage.scss'
import strings from 'common/Translation/Translate';
import PrimaryButton from 'components/Button/PrimaryButton';
import CouponCard from './CouponCard';
import Form, { FormDataModel } from 'components/Form';
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { PlaceOrderForm } from './PlaceOrderForm';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { removeErrorFieldsFromValues } from 'utils/validators';
import Notification from 'components/Notification';
import DrawerMenu from 'components/DrawerMenu';
import { getBaseUrl, getDrawerWidth } from 'utils/validators/HelperFunctions';
import { ReedemCoupons } from 'utils/validators/HelperComponents';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ToolTip } from 'components/Tooltip/ToolTip';
import { redeemPercentage } from 'utils/redux/reducer/authentication-slice';
import { rewards } from 'utils/redux/reducer/rewards-slice';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Close } from '@mui/icons-material';
import useWindowSize from 'utils/hooks/useWindowSize';
import { CouponsFilter } from './CouponsFilter';

const couponBox = {
  //height: '200px',
  borderRadius: '20px 0px',
  padding: '15px',
  display: 'flex',
  gap: '10px',
  background: '#80808012',
  '&:hover': {
    boxShadow: 'inset 3px -3px 6px rgba(0, 0, 0, 0.1607843137)',
    background: '#af94942e',
  },
};

const descriptionFont = {
  fontFamily: 'initial',
  fontSize: 'x-large',
};

const otherFont = {
  fontSize: '12px'
};

const RewardsPage: FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const mobile = useWindowSize();
  let filterRef: RefObject<Form | null | undefined> = useRef();
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const screenSizeUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const [pageResponse, setPageResponse] = useState<any>({});
  const [couponData, setCouponData] = useState<any>([]);
  const [filter, setFilter] = useState<any>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [savedFilter, setSavedFilter] = useState<any>([]);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: any;
  }>({
    display: false,
    severity: null,
    message: "",
  });



  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };



  const getRewardsApiData = useCallback(async (body: any) => {
    try {
      // setLoader(true);
      const { status, data } = await axiosInstance.post(URLS.rewardPage, body);
      const { content, ...rest } = data;
      if (status === 200) {
        setLoader(false);
        setCouponData(data?.content);
        setPageResponse(rest);
      }
    } catch (err: any) {
      setLoader(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: 'error',
          message: err.message,
        }),
      );
    }
  }, [setCouponData]);

  useEffect(() => {
    (async () => {
      const body = {
        filter: [],
        sort: ["productCode:asc"],
        page: selectedPage,
        size: 100,
      };
      await getRewardsApiData(body);
    })();
  }, [getRewardsApiData, selectedPage]);

  const open = Boolean(anchorEl);
  const anchor_id = open ? 'simple-popover' : undefined;
  const handleFilter = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };
  const handlefilterData = (e: any) => {
    setAnchorEl(e.target);
  };

  const applyFilter = (e: any, reset: boolean) => {
    e.preventDefault();
    applyFilterHelper(reset)
  }
  const applyFilterHelper = useCallback(async (reset: boolean) => {
    const filters: any = [];
    if (reset) {
      setFilter(null);
    } else {
      const { getFormData } = filterRef.current as {
        getFormData: () => { formData: FormDataModel; isFormValid: boolean };
      };
      const { formData } = getFormData();
      const filter_data = removeErrorFieldsFromValues(formData);
      setFilter(filter_data);
      Object.keys(filter_data).forEach(key => {
        if (!!filter_data[key]) {
          if (filters.length > 0) {
            filters.push('and');
          }
          filters.push(filter_data[key] ? `${key}:cic:${filter_data[key]}` : null);
        }
      });
    }
    const newFilter = filters?.filter((item: any) => item !== null);
    setSavedFilter(newFilter);
    const body = {
      page: 1,
      size: 100,
      filter: newFilter || [],
      sort: ["productCode:asc"],
    };
    await getRewardsApiData(body);
    handleCloseFilter();
  }, [getRewardsApiData]);

  const popover = useMemo(() => {
    return (
      <Box>
        <Grid container sx={{ mx: mobile ? 0.5 : 2, ml: 0, alignItems: 'center' }}>
          <Grid item xs={5}>
            <Typography sx={{ fontSize: mobile ? 16 : 24, fontWeight: 500 }}>{strings.filter_text}</Typography>
          </Grid>
          <Grid item xs={5} textAlign={'right'}>
            <Button
              size="small"
              variant={'outlined'}
              onClick={(e: any) => applyFilter(e, true)}
              sx={{ p: mobile ? 0 : 1, ml: mobile ? 0 : 1 }}>
              <Typography fontSize="small" variant={'body2'} whiteSpace="nowrap">
                {screenSizeUpMd ? strings.clear_filter_text : strings.clearText}
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={2} textAlign={'right'} onClick={handleCloseFilter}>
            {/* <Button variant={'text'}> */}
            <Close />
            {/* </Button> */}
          </Grid>
        </Grid>
        <Divider sx={{ my: mobile ? 0.5 : 1, width: 'calc(100% + 16px)' }} />
        <Grid container>
          <form onSubmit={(e: any) => applyFilter(e, false)} style={{ width: '100%' }}>
            <Grid item xs={12} sx={{ p: 1 }}>
              <Grid container rowGap={2}>
                <Form ref={filterRef as RefObject<Form>} model={CouponsFilter()} values={filter} />
              </Grid>

              <Button
                type="submit"
                variant="contained"
                sx={{ my: 2, color: '#ffffff' }}
                fullWidth
                onClick={(e: any) => applyFilter(e, false)}>
                {'search'}
              </Button>
            </Grid>
          </form>
        </Grid>
      </Box>
    );
  }, [mobile, screenSizeUpMd, filter, applyFilter]);

  return (
    <>
      {/* {loader && <PageLoader />} */}
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      <Box marginX={4}>
        <ReedemCoupons justifyContent='end' loading={loader} setLoading={setLoader} />
      </Box>
      <Box textAlign={'right'} mt={2} marginRight={2}>
        <PrimaryButton sx={{ mr: 2 }} onClick={handleFilter} fullWidth={false}>
          <FilterAltOutlinedIcon sx={{ mr: screenSizeDownSm ? 0 : 1 }} />
          {screenSizeDownSm ? '' : 'FILTERS'}
        </PrimaryButton>
      </Box>
      {filter &&
        Object.entries(filter).map(
          ([key, value]: any) =>
            value !== '' && (
              <Chip
                size={mobile ? 'small' : 'medium'}
                onClick={handlefilterData}
                key={key}
                sx={{
                  textTransform: 'capitalize',
                  background: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  ml: 2,
                  my: mobile ? 0.5 : 1,
                  fontSize: mobile ? '10px' : '12px',
                  fontWeight: 700,
                }}
                color="error"
                label={`${key} : ${value}`}
              />
            ),
        )}
      {couponData && couponData.length > 0 ?
        <Grid container p={1} rowSpacing={3} columnSpacing={2} mb={1}>
          {couponData.map((cv: any) =>
            <Grid item xs={12} md={6} lg={4}>
              <CouponCard cv={cv} />
            </Grid>
          )}
        </Grid>
        :
        <Box textAlign={'center'} height='45vh'>
          <Typography variant='h6'>No Products Found</Typography>
        </Box>}
      <Popover
        onClose={() => {
          setAnchorEl(null);
        }}
        sx={{ m: 4, [`& .MuiPopover-paper`]: { borderRadius: 0, p: 1, width: mobile ? '300px' : '400px' } }}
        id={anchor_id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        {popover}
      </Popover>
      {pageResponse?.totalPages > 1 &&
        <Box display={'flex'} justifyContent={'center'}>
          <Pagination
            variant="outlined" shape="rounded" siblingCount={screenSizeDownSm ? 0 : 1}
            count={pageResponse?.totalPages}
            color="primary"
            sx={{ py: 3, px: 2 }}
            page={selectedPage}
            onChange={(e: any, page: number) => { setSelectedPage(page) }}
          />
        </Box>
      }
      {loader && <Stack spacing={1} >
        <Grid container p={1} rowSpacing={3} columnSpacing={2} my={1}>
          <Grid item xs={12} md={6} lg={4}>
            <Skeleton variant="rectangular" width={'100%'} height={300} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Skeleton variant="rectangular" width={'100%'} height={300} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Skeleton variant="rectangular" width={'100%'} height={300} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Skeleton variant="rectangular" width={'100%'} height={300} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Skeleton variant="rectangular" width={'100%'} height={300} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Skeleton variant="rectangular" width={'100%'} height={300} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Skeleton variant="rectangular" width={'100%'} height={300} />
          </Grid>
        </Grid>
      </Stack >
      }
    </>
  );
};

export default RewardsPage;
