import { Button } from "@mui/material";
import { fontSize } from "@mui/system";
import React, { FC } from "react";
import { ToolTip } from "../Tooltip/ToolTip";
import "./styles.css";

type PrimaryButtonProps = {
  onClick?: (e?: any) => void;
  disabled?: boolean;
  children: React.ReactNode | string;
  variant?: any;
  className?: string;
  fullWidth?: boolean;
  title?: string;
  sx?: any;
  type?: any;
  tooltipInfo?: string;
  spanStyle?:Object,
};

const PrimaryButton: FC<PrimaryButtonProps> = ({
  onClick,
  disabled,
  variant = "contained",
  fullWidth = true,
  className,
  children,
  title,
  spanStyle,
  type,
  sx,
  tooltipInfo,
  ...rest
}) => {
  return (
    <ToolTip title={tooltipInfo}>
      <span style={spanStyle}>
        <Button
          type={type}
          title={title}
          fullWidth={fullWidth}
          sx={{
            ...sx, 
            minHeight: '35px',
            '&:hover': {
              boxShadow: '0 8px 8px rgba(0, 0, 0, 0.1)'
            },
            "&.MuiButtonBase-root": {
                borderRadius: '0%',
                fontSize: '0.75rem',
                fontWeight: 500
            },
            textTransform: 'capitalize'
          }}
          // className={`${variant === "contained" ? "defaultButton" : "textButton"
          //   } ${className}`}
          disabled={disabled}
          onClick={onClick}
          variant={variant}
          {...rest}
        >
          {children}
        </Button>
      </span>
    </ToolTip>
  );
};
export default PrimaryButton;
