import { useEffect } from 'react';

export const Privacy = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <div
      style={{ margin: '0px 30px' }}
      dangerouslySetInnerHTML={{
        __html:
          "<p>Thank you for visiting our Sandbox CloverBay Labs website. Your privacy is important to us. This Privacy Policy outlines how we collect, use, and safeguard your personal information.</p><h4>Information Collection:</h4><p>We collect personal information such as name, email address, shipping address, and payment details when you place an order on our website.</p><h4>Information Use:</h4><p>We use your personal information to process orders, communicate with you, and improve our services. Your information is never shared with third parties without your consent, except as required by law.</p><h4>Data Security:</h4><p>We implement security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.</p><h4>Cookies:</h4><p>We may use cookies to enhance your browsing experience and gather analytics data. You can choose to disable cookies in your browser settings, but this may affect website functionality.</p><h4>Your Rights:</h4><p>You have the right to access, update, or delete your personal information. Please contact us if you wish to exercise these rights.</p><h4>Consent:</h4><p>By using our website, you consent to the collection and use of your personal information as outlined in this Privacy Policy.</p><h4>Updates:</h4><p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and your continued use of the website constitutes acceptance of those changes.</p>"
      }}></div>
  );
};
