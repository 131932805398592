import { FormModel } from "components/Form";
import { Validators } from "utils/validators";
import strings from "../../../common/Translation/Translate";
import { InputAdornment, Button } from "@mui/material";
const region = [
	{ label: "Arica y Parinacota", value: "Arica y Parinacota" },
	{ label: "Tarapacá", value: "Tarapacá" },
	{ label: "Antofagasta", value: "Antofagasta" },
	{ label: "Atacama", value: "Atacama" },
	{ label: "Coquimbo", value: "Coquimbo" },
	{ label: "Valparaíso", value: "Valparaíso" },
	{ label: "Metropolitana de Santiago", value: "Metropolitana de Santiago" },
	{ label: "Libertador General Bernardo O'Higgins", value: "Libertador General Bernardo O'Higgins" },
	{ label: "Maule", value: "Maule" },
	{ label: "Ñuble", value: "Ñuble" },
	{ label: "Biobío", value: "Biobío" },
	{ label: "La Araucanía", value: "La Araucanía" },
	{ label: "Los Ríos", value: "Los Ríos" },
	{ label: "Los Lagos", value: "Los Lagos" },
	{ label: "Aysén del General Carlos Ibáñez del Campo", value: "Aysén del General Carlos Ibáñez del Campo" },
	{ label: "Magallanes y de la Antártica Chilena", value: "Magallanes y de la Antártica Chilena" },
];

export const AddressForm1 = ({ InputProps, pincodeColor }: { InputProps: any; pincodeColor: string }): FormModel[] => {
	return [
		{
			label: `Address Line 1`,
			value: "",
			typeValue: "text",
			type: "text",
			placeholder: "",
			field: "addressLine1",
			variant: "outlined",
			validators: [{ check: Validators.required, message: strings.requiredField }],
			required: true,
			responsive: { xs: 12 },
			size: "small",
		},
		{
			label: `Address Line 2`,
			value: "",
			typeValue: "text",
			type: "text",
			placeholder: "",
			required: false,
			field: "addressLine2",
			variant: "outlined",
			validators: [],
			responsive: { xs: 12 },
			size: "small",
		},
		{
			label: `Location`,
			value: "",
			typeValue: "text",
			type: "text",
			placeholder: "",
			field: "location",
			variant: "outlined",
			validators: [{ check: Validators.required, message: strings.requiredField }],
			required: true,
			responsive: { xs: 12 },
			size: "small",
		},
		{
			label: `Postal Code`,
			value: "",
			typeValue: "text",
			type: "text",
			placeholder: "",
			color: pincodeColor,
			field: "pinCode",
			variant: "outlined",
			validators: [
				{ check: Validators.required, message: strings.requiredField },
				{
					check: Validators.maxCharLimit,
					message: "Enter a vaild pincode",
					num: 10,
				},
			],
			InputProps: InputProps,
			required: true,
			responsive: { xs: 12 },
			size: "small",
		},
		{
			label: `Country`,
			value: "",
			typeValue: "text",
			type: "drop-down",
			placeholder: "",
			options: [
				{
					label: "Chile",
					value: "Chile",
				},
				
			],
			field: "country",
			variant: "outlined",
			validators: [{ check: Validators.required, message: strings.requiredField }],
			required: true,
			responsive: { xs: 12 },
			size: "small",
		},
		{
			label: `Country code`,
			value: "",
			typeValue: "text",
			type: "text",
			disabled: true,

			placeholder: "",
			field: "countryCode",
			variant: "outlined",
			validators: [{ check: Validators.required, message: strings.requiredField }],
			required: true,
			responsive: { xs: 12 },
			size: "small",
		},
		{
			label: `Region`,
			value: "",
			typeValue: "text",
			type: "drop-down",
			options: region,
			placeholder: "",
			field: "region",
			variant: "outlined",
			validators: [{ check: Validators.required, message: strings.requiredField }],
			required: true,
			responsive: { xs: 12 },
			size: "small",
		},
		{
			label: `Region code`,
			value: "",
			typeValue: "text",
			type: "text",
			disabled: true,
			placeholder: "",

			field: "regionCode",
			variant: "outlined",
			validators: [{ check: Validators.required, message: strings.requiredField }],
			required: true,
			responsive: { xs: 12 },
			size: "small",
		},
	];
};

export const AddressForm2 = ({ disabled }: { disabled: boolean }): FormModel[] => {
	return [
		{
			label: `Address Line 1`,
			value: "",
			typeValue: "text",
			type: "text",
			placeholder: "",
			field: "addressLine1",
			variant: "outlined",
			validators: [{ check: Validators.required, message: strings.requiredField }],
			required: true,
			responsive: { xs: 12 },
			size: "small",
			disabled: disabled,
		},
		{
			label: `Address Line 2`,
			value: "",
			typeValue: "text",
			type: "text",
			placeholder: "",
			required: false,
			field: "addressLine2",
			variant: "outlined",
			validators: [],
			responsive: { xs: 12 },
			size: "small",
			disabled: disabled,
		},
		{
			label: `Location`,
			value: "",
			typeValue: "text",
			type: "text",
			placeholder: "",
			field: "location",
			variant: "outlined",
			validators: [{ check: Validators.required, message: strings.requiredField }],
			required: true,
			responsive: { xs: 12 },
			size: "small",
			disabled: disabled,
		},
		{
			label: `Postal Code`,
			value: "",
			typeValue: "text",
			type: "text",
			placeholder: "",
			field: "pinCode",
			variant: "outlined",
			validators: [
				{ check: Validators.required, message: strings.requiredField },
				{
					check: Validators.maxCharLimit,
					message: "Enter a vaild pincode",
					num: 10,
				},
			],

			required: true,
			responsive: { xs: 12 },
			size: "small",
			disabled: disabled,
		},
		{
			label: `Country`,
			value: "",
			typeValue: "text",
			type: "drop-down",
			placeholder: "",
			options: [
				{
					label: "Chile",
					value: "Chile",
				},
			],
			field: "country",
			variant: "outlined",
			validators: [{ check: Validators.required, message: strings.requiredField }],
			required: true,
			responsive: { xs: 12 },
			size: "small",
			disabled: disabled,
		},
		{
			label: `Country code`,
			value: "",
			typeValue: "text",
			type: "text",
			placeholder: "",
			disabled: true,

			field: "countryCode",
			variant: "outlined",
			validators: [{ check: Validators.required, message: strings.requiredField }],
			required: true,
			responsive: { xs: 12 },
			size: "small",
		},
		{
			label: `Region`,
			value: "",
			typeValue: "text",
			type: "drop-down",
			options: region,
			placeholder: "",
			field: "region",
			variant: "outlined",
			validators: [{ check: Validators.required, message: strings.requiredField }],
			required: true,
			responsive: { xs: 12 },
			size: "small",
			disabled: disabled,
		},
		{
			label: `Region code`,
			value: "",
			typeValue: "text",
			type: "text",
			placeholder: "",
			field: "regionCode",
			variant: "outlined",
			validators: [{ check: Validators.required, message: strings.requiredField }],
			required: true,
			responsive: { xs: 12 },
			size: "small",
			disabled: true,
		},
	];
};
