import { Validators } from "../../utils/validators";
import { FormModel } from "../../components/Form";

export const UserProfileForm = (strings: any): FormModel[] => {
	const arrayFields = [
		{
			label: "Customer Id",
			value: "",
			size: "small",
			autoFocus: false,
			type: "text",
			typeValue: "text",
			variant: "outlined",
			placeholder: "",
			field: "customerNumber",
			disabled: true,
			responsive: { xs: 12, sm: 6 },
			required: false,
			validators: [],
		},
		{
			label: strings.registartionEmailId,
			value: "",
			size: "small",
			autoFocus: false,
			type: "text",
			typeValue: "text",
			variant: "outlined",
			placeholder: "",
			field: "email",
			validators: [
				{
					check: Validators.required,
					message: strings.requiredField,
				},
				{ check: Validators.email, message: strings.emailField },
			],
			responsive: { xs: 12, sm: 6 },
			required: true,
			// changeValueFunc: changeValueFunc,
			disabled: true,
		},
		{
			label: strings.registartionFirstName,
			value: "",
			size: "small",
			autoFocus: false,
			type: "text",
			typeValue: "text",
			variant: "outlined",
			placeholder: "",
			field: "firstName",
			validators: [{ check: Validators.required, message: strings.requiredField }],
			responsive: { xs: 12, sm: 6 },
			required: true,
		},
		{
			label: strings.registartionLastName,
			value: "",
			size: "small",
			autoFocus: false,
			type: "text",
			typeValue: "text",
			variant: "outlined",
			placeholder: "",
			field: "lastName",
			validators: [
				{
					check: Validators.required,
					message: strings.requiredField,
				},
			],
			responsive: { xs: 12, sm: 6 },
			required: true,
		},

		{
			label: strings.registartionPhoneNo,
			value: "",
			size: "small",
			autoFocus: false,
			type: "text",
			typeValue: "text",
			variant: "outlined",
			placeholder: "",
			field: "phone",
			validators: [
				{
					check: Validators.required,
					message: strings.requiredField,
				},
				{ check: Validators.mobile, message: strings.errorPhoneNumber },
			],
			responsive: { xs: 12, sm: 6 },
			required: true,
		},
	];
	return arrayFields;
};
