const regionCodes = {
	"Arica y Parinacota": "AL",
	Tarapacá: "TA",
	Antofagasta: "AN",
	Atacama: "AT",
	Coquimbo: "CO",
	Valparaíso: "VA",
	"Metropolitana de Santiago": "MS",
	"Libertador General Bernardo O'Higgins": "LI",
	Maule: "MA",
	Ñuble: "NU",
	Biobío: "BI",
	"La Araucanía": "AR",
	"Los Ríos": "LR",
	"Los Lagos": "LL",
	"Aysén del General Carlos Ibáñez del Campo": "AI",
	"Magallanes y de la Antártica Chilena": "MG",
};
export const getCountryCode = (country: any) => {
	switch (country) {
		case "Chile":
			return "CL";
		case "United States":
			return "US";
		case "United Kingdom":
			return "UK";
		case "Mexico":
			return "MX";
		case "Canada":
			return "CA";
	}
};

export const getRegionCode = (region: keyof typeof regionCodes) => {
	return regionCodes[region];
};
